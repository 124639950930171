import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import axios from 'axios';
import { APIURL } from "../../Global";
import { UserManagementContext } from "../contexts/UserManagementContext";
import { Switch } from "antd";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { ConfirmationContext } from '../contexts/SubmitConfirmationContext';
function RenderSelected({ node, data }) {
    const [selected, setSelected] = useContext(UserManagementContext)
    const [error, setError] = useState('');
    const [access, setAccess] = useState('');
    const [accessData, setAccessData] = useState('')
    const [flag, setFlag] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [switchState, setSwitchState] = useState()
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
    const alert = useAlert()
    const handleRadioButton = (e) => {
        let opted = e.target.value;
        setAccess(opted);
        setError('');
    };
    useEffect(() => {
        setFlag(false)
    }, [node])

    useEffect(() => {
        if (node.length > 0) {
            const lastNode = node[node.length - 1];
            const tokenString = sessionStorage.getItem("usertoken");
            const token = tokenString.replace(/["]+/g, "");
            const user_id = selected === 'doctors' ? data.doctor_id
                : selected === "bdo" ? data.bdo_id
                    : selected === "pro" ? data.pro_id
                        : selected === "front-offices" ? data.user_id
                            : data.user;

            axios.get(`${APIURL}/api/v1/service-provider/user-access/?parent_node=${lastNode.node_access}&user_id=${user_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Token " + token
                },
            })
                .then((res) => {
                    if (res.data.status === 'success') {
                        if (res.data.access_list) {
                            const datas = res.data.access_list;
                            setAccessData(datas);
                            setAccess(datas);
                            setSwitchState(true);
                        } else {
                            setAccessData('');
                            setAccess('');
                            setSwitchState(false);
                        }
                    } else if (res.data.status === 'error' && res.data.message === 'Access Denied') {
                        setAccessData('');
                        setAccess('');
                        setSwitchState(false);
                    } else {
                        alert.error('Error occurred');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert.error('Error occurred');
                });
        }
    }, [node, refresh]);

    const onSubmit = (acc) => {
        if (acc !== '') {
            if (node.length > 0) {
                const lastNode = node[node.length - 1];
                const tokenString = sessionStorage.getItem("usertoken");
                const token = tokenString.replace(/["]+/g, "");
                const payload = {
                    user: selected === 'doctors' ? data.doctor_id
                        : selected === "bdo" ? data.bdo_id
                            : selected === "pro" ? data.pro_id
                                : selected === "front-offices" ? data.user_id
                                    : data.user,
                    access_list: {
                        [lastNode.id]: acc
                    }
                };
                axios.post(`${APIURL}/api/v1/service-provider/user-access/`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Token " + token
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            alert.success(res.data.message ? res.data.message : 'successfully updated')
                            setRefresh(!refresh)
                            setFlag(false)
                        } else {
                            alert.error("error");
                            setRefresh(!refresh)
                            setFlag(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        alert.error("error");
                    });
            }
        } else {
            setError('Select required access');
            alert.error('Select required access')
        }
    };
    const handleSwitchChange = async (checked) => {
        if (!checked) {
            try {
                const tokenString = sessionStorage.getItem("usertoken");

                if (!tokenString) {
                    alert.error("Authentication token is missing");
                    return;
                }

                const token = tokenString.replace(/["]+/g, "");
                const node_access = node[node.length - 1]?.node_access || "";
                const user_id = selected === 'doctors' ? data.doctor_id :
                    selected === 'bdo' ? data.bdo_id :
                        selected === 'pro' ? data.pro_id :
                            selected === 'front-offices' ? data.user_id :
                                data.user;

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                };

                const response = await axios.put(`${APIURL}/api/v1/service-provider/user-access/`, null,
                    { params: { user_id, node_access }, ...config, }
                );

                if (response.status === 200) {
                    const resData = response.data;
                    if (resData.status === 'success') {
                        alert.success(resData.message || 'Successfully updated');
                        setRefresh((prev) => !prev);
                        setFlag(false);
                    } else {
                        alert.error(resData.message || 'Error occurred');
                    }
                } else {
                    alert.error('Error');
                }
            } catch (err) {
                console.error(err);
                alert.error('Error');
            }
        } else {
            setShowConfirmation(true)
        }
    };
    const handleConfirm = () => {
        setAccess('viewer')
        onSubmit('viewer');
    };

    const handleCancel = () => {
        closeConfirmationPopup()
    };

    return (
        <div className="render-div">
            <div className="render-content1">
                <label className="access-selection-label" style={{ fontSize: '18px' }}>Preview</label>
                <label className="access-selection-label" style={{ textTransform: 'capitalize' }}>{data?.name}</label>
            </div>
            <label className="render-content2">
                {node.map((data, index) => (
                    <React.Fragment key={index}>
                        <div style={{ display: 'inline-block' }}>
                            {data.node_name}
                        </div>
                        {index < node.length - 1 && (
                            <span style={{ margin: '0 5px' }}> &gt;&gt; </span>
                        )}
                    </React.Fragment>
                ))}
            </label>
            <div style={{ display: 'flex', width: '90%', margin: 'auto', justifyContent: 'space-between' }}>
                <label className="render-content3" style={{ color: flag ? '' : accessData === '' ? '#ED4500' : '#007205' }}>{flag ? 'Modify Access' : accessData === '' ? 'No access defined' : 'Access granted'}</label>
                <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    checked={switchState}
                    onChange={handleSwitchChange} />
            </div>
            <div className="access-selection">
                <div style={{ display: 'flex' }}>
                    <div className="col-4" >
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'admin') ? '#007205' : '' }}
                            type="radio"
                            id="admin"
                            name="access"
                            value="admin"
                            disabled={!flag}
                            checked={access === "admin"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="admin" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'admin') ? '#007205' : '' }}>Admin</label>
                    </div>
                    <div className="col-4">
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'viewer') ? '#007205' : '' }}
                            type="radio"
                            id="viewer"
                            name="access"
                            value="viewer"
                            disabled={!flag}
                            checked={access === "viewer"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="viewer" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'viewer') ? '#007205' : '' }}>Viewer</label>
                    </div>
                    <div className="col-4">
                        <input
                            style={{ width: '14px', height: '14px', accentColor: (!flag && accessData !== '' && access === 'editor') ? '#007205' : '' }}
                            type="radio"
                            id="editor"
                            name="access"
                            value="editor"
                            disabled={!flag}
                            checked={access === "editor"}
                            onClick={handleRadioButton}
                        />&nbsp;&nbsp;
                        <label htmlFor="editor" style={{ margin: 0, color: (!flag && accessData !== '' && access === 'editor') ? 'green' : '' }}>Editor</label>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    {flag && <button onClick={()=>{onSubmit(access)}} className="sub-button">Submit</button>}
                    {!flag && <button disabled={!switchState} onClick={() => { setFlag(true) }} className="sub-button" style={{ backgroundColor: !switchState ? '#2b69a39c' : '' }}>Modify</button>}

                </div>
            </div>
            {showConfirmation && (
                <SubmitConfirmationPopup
                    message={`Are you sure you want to give access to ${data.name} for ${node[1].node_name}${node.length === 3 ? ` - ${node[2].node_name}` : ''}?`}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

RenderSelected.propTypes = {
    node: PropTypes.array.isRequired,
    data: PropTypes.object,
};

export default RenderSelected;
import React, { useState, useEffect, useRef } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import '../PRO/Dialysis/addMachine.css';
import '../PRO/Dialysis/machineSchedule.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import moment from 'moment';
import Select from 'react-select';
const AddPhysioShedulePopup = (props) => {

    const modalContentRef = useRef(null);
    const submitButtonRef = useRef(null);
    const alert = useAlert()
    const [error, setError] = useState({})
    const [physioOptions, setPhysioOptions] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [physio, setPhysio] = useState({
        value: '',
        name: ''
    });
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [scheduleSets, setScheduleSets] = useState([{
        start_time: '',
        end_time: '',
        token: ''
    }]);
    const [date, setDate] = useState({
        from_date: '',
        to_date: '',
    })

    const today = new Date().toISOString().split('T')[0];
    const generateTimeOptions = () => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            options.push(`${hour}:00:00`, `${hour}:30:00`);
        }
        return options;
    };

    const generateFilteredTimeOptions = (timeOptions, scheduleSets, currentIndex, type, selectedValue) => {
        const filteredOptions = [...timeOptions];

        scheduleSets.forEach((set, index) => {
            if (index !== currentIndex && set.start_time && set.end_time) {
                const startTimeIdx = timeOptions.indexOf(set.start_time);
                const endTimeIdx = timeOptions.indexOf(set.end_time);

                if (type === 'start') {
                    if (currentIndex > index) {
                        for (let i = startTimeIdx + 1; i < endTimeIdx; i++) {
                            filteredOptions[i] = null;
                        }
                    } else {
                        for (let i = 0; i <= endTimeIdx; i++) {
                            filteredOptions[i] = null;
                        }
                    }
                }
            }
        });

        // Add selected value as the first option if it exists and is not null/undefined
        if (selectedValue) {
            filteredOptions.unshift(selectedValue);
        }
        return filteredOptions.filter(option => option !== null);
    };


    const generateFilteredEndTimeOptions = (timeOptions, startTime, scheduleSets, currentIndex) => {
        const startTimeIdx = timeOptions.indexOf(startTime);
        let filteredOptions = timeOptions.filter((_, index) => index > startTimeIdx);

        // Collect all selected times from previous schedule sets
        const selectedTimes = [];
        for (let i = 0; i < currentIndex; i++) {
            const set = scheduleSets[i];
            if (set.start_time && set.end_time) {
                const startTimeIdx = timeOptions.indexOf(set.start_time);
                const endTimeIdx = timeOptions.indexOf(set.end_time);

                // Push all times between start_time and end_time to selectedTimes array
                for (let j = startTimeIdx + 1; j <= endTimeIdx; j++) {
                    selectedTimes.push(timeOptions[j]);
                }
            }
        }

        // Filter out selected times from filteredOptions
        filteredOptions = filteredOptions.filter(time => !selectedTimes.includes(time));

        return filteredOptions;

    };

    const timeOptions = generateTimeOptions();

    useEffect(() => {
        addedPhysios(5)
    }, []);


    const addedPhysios = async (val) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        let dep = `department[]=${val}`

        axios
            .get(`${APIURL}/api/v1/service-provider/access-department-users/?${dep}`, {

                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.details
                    const options = filteredData.map((physio) => ({
                        value: physio.id,
                        label: physio.name,
                    }));
                    setPhysioOptions(options);
                }
            })
            .catch((err) => { });
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const Errors = { ...error };

        setScheduleSets((prevSets) => {
            const updatedSets = [...prevSets];
            if (name === 'start_time') {
                const newEndTime = moment(value, 'HH:mm:ss').add(30, 'minutes').format('HH:mm:ss');

                updatedSets[index] = {
                    ...updatedSets[index],
                    start_time: value,
                    end_time: newEndTime,
                };
            }
            else {
                updatedSets[index] = {
                    ...updatedSets[index],
                    [name]: value,
                };
            }
            Errors[name] = ``
            Errors["set"] = ``
            if (index > 0 && updatedSets[index][name] === updatedSets[index - 1][name]) {
                if (name !== 'token') {
                    Errors[name] = `Time cannot be same for different slotes`;
                }
            } else {
                delete Errors[name];
            }
            setError(Errors);

            return updatedSets;
        });
    };

    const handleAddSet = () => {
        const lastSet = scheduleSets[scheduleSets.length - 1];
        if (lastSet.start_time && lastSet.end_time && lastSet.token) {
            setScheduleSets((prevSets) => [
                ...prevSets,
                { start_time: '', end_time: '', token: '' },
            ]);
        }
    };

    const handleRemoveSet = (index) => {
        setScheduleSets((prevSets) => {
            if (prevSets.length === 1) {
                return [{ start_time: '', end_time: '', token: '' }];
            }
            const updatedSets = prevSets.filter((_, i) => i !== index);
            return updatedSets;
        });
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setError(prevState => ({
            ...prevState,
            [name]: ''
        }));
        setDate(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getMaxToDate = () => {
        if (date.from_date) {
            return moment(date.from_date).add(1, 'month').format('YYYY-MM-DD');
        }
        return '';
    };

    const handleCheckboxChange = (day) => {
        setError(prevState => ({
            ...prevState,
            days: ''
        }));
        setSelectedDays(prevState => {
            if (prevState.includes(day)) {
                return prevState.filter(selectedDay => selectedDay !== day);
            } else {
                return [...prevState, day];
            }
        });
    };

    const physioChange = (selectedOption) => {
        setError(prevState => ({
            ...prevState,
            physio: ''
        }));
        if (selectedOption) {
            setPhysio({
                value: selectedOption.value,
                name: selectedOption.label
            });
        } else {
            setPhysio({
                value: '',
                name: ''
            });
        }
    };

    const validate = () => {
        let input1 = scheduleSets;
        let input2 = date;
        let errors = {};
        let isValid = true;

        if (input2["from_date"] === '' || input2["from_date"] === null) {
            isValid = false;
            errors.from_date = 'Select  schedule starting date'
        }
        if (input2["to_date"] === '' || input2["to_date"] === null) {
            isValid = false;
            errors.to_date = 'Select  schedule ending date'
        }

        if (selectedDays.length === 0) {
            isValid = false;
            errors.days = 'Select scheduling days'
        }
        if (physio.name === '' || physio.name === undefined || physio.name === null) {
            isValid = false;
            errors.physio = 'Select physios to schedule'
        }
        // for (let i = 0; i < scheduleSets.length; i++) {
        if (scheduleSets[scheduleSets.length - 1].token === '' || scheduleSets[scheduleSets.length - 1].token === '0' || scheduleSets[scheduleSets.length - 1].token === null || scheduleSets[scheduleSets.length - 1].token === undefined) {
            if (scheduleSets[scheduleSets.length - 1].start_time !== '') {
                isValid = false;
                errors.token = "Enter number of slots";
            }
        }
        // }

        const checkIntersection = (start1, end1, start2, end2) => {
            return start1 < end2 && start2 < end1;
        };

        for (let i = 0; i < scheduleSets.length; i++) {
            for (let j = i + 1; j < scheduleSets.length; j++) {
                const start1 = new Date(`1970-01-01T${scheduleSets[i].start_time}Z`);
                const end1 = new Date(`1970-01-01T${scheduleSets[i].end_time}Z`);
                const start2 = new Date(`1970-01-01T${scheduleSets[j].start_time}Z`);
                const end2 = new Date(`1970-01-01T${scheduleSets[j].end_time}Z`);

                if (checkIntersection(start1, end1, start2, end2)) {
                    isValid = false;
                    errors.set = "Time slots cannot overlap";
                    break;
                }
            }
        }

        scheduleSets.forEach((set, index) => {
            console.log(set.start_time)
            console.log(set.end_time)
            if (set.start_time === set.end_time) {
                isValid = false;
                errors.set = "Start time and end time cannot be the same";
            }
        });

        if (scheduleSets[0].start_time === '' && scheduleSets[0].end_time === '') {
            isValid = false;
            errors.set = "Select time slots"
        }

        setError(errors);

        if (isValid) {
            handleSchedulePhysio()
        }
        else {
            alert.error("Check all the fields")
        }
    }



    const handleSchedulePhysio = () => {
        const tokenString = sessionStorage.getItem('usertoken');
        const token = tokenString.replace(/["]+/g, '');

        const daysPayload = {}

        selectedDays.forEach(day => {
            daysPayload[day.toLowerCase()] = "True";
        });

        const slotsPayload = scheduleSets.map(set => ({
            from_time: set.start_time,
            to_time: set.end_time,
            token: set.token
        }));

        const payload = {
            allied_id: physio.value,
            days: daysPayload,
            from_date: date.from_date,
            to_date: date.to_date,
            slots: slotsPayload
        };

        axios.post(`${APIURL}/api/v1/service-provider/allied-schedule/`, payload, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Token ${token}`
            }
        })
            .then((res) => {

                if (res.status === 200) {
                    if (res.data.status === "success") {
                        alert.success(res.data.message);
                        props.reload()
                        props.close();
                    } else {
                        alert.error(res.data.message);
                    }
                } else {
                    alert.error(res.data.message);
                }
            })
            .catch((err) => {
            });
    };


    return (
        <div className='mschedule-modal-content' ref={modalContentRef}>
            <div className='add-physio-input'>
                <div className='mschedule-head'>
                    <label>Allied Schedule</label>
                </div>
                <div className=''>
                    {(error.to_date || error.from_date) ? <label className="error-label">{error.from_date ? error.from_date : error.to_date}</label> : <label>Working dates</label>}
                    <div className='mschedule-date-inputcontainer'>
                        <div className='msinput-container col-6'>
                            <label htmlFor="date">Start date</label>
                            <input
                                className={`msinput1 ${error.from_date ? 'error-border' : ''}`}
                                type="date"
                                step="1"
                                id="from_date"
                                name="from_date"
                                min={today}
                                value={date.from_date}
                                onChange={handleDateChange}
                            />
                        </div>
                        <div className='msinput-container col-6'>
                            <label htmlFor="date">End date</label>
                            <input className={`msinput1 ${error.to_date ? 'error-border' : ''}`}
                                type="date"
                                id="to_date"
                                name="to_date"
                                disabled={date.from_date === ''}
                                min={date.from_date}
                                max={getMaxToDate()}
                                value={date.to_date}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    {(error.set || error.start_time || error.end_time) ? <label className="error-label">{error.set ? error.set : error.start_time ? error.start_time : error.end_time}</label> : <label>Working Slots</label>}
                    <div className="mschedule-inputcontainer">
                        <button
                            className="schedule-button"
                            onClick={handleAddSet}
                            disabled={
                                !scheduleSets[scheduleSets.length - 1].start_time ||
                                !scheduleSets[scheduleSets.length - 1].end_time ||
                                scheduleSets[scheduleSets.length - 1].token === '' ||
                                scheduleSets[scheduleSets.length - 1].token === '0' ||
                                scheduleSets[scheduleSets.length - 1].token === undefined
                            }
                        >
                            <span>+</span>
                        </button>
                        {scheduleSets.map((set, index) => (
                            <div className="schedule-set" style={{ alignItems: 'center' }} key={index}>
                                <div className='msinput-container col-4'>
                                    <label htmlFor="start_time">From</label>
                                    <select
                                        className={`msinput1 ${(error.start_time || error.set) ? 'error-border' : ''} ${(date.from_date === '' || date.to_date === '' || index !== scheduleSets.length - 1) ? 'disabled-cursor' : 'pointer'}`}
                                        id="start_time"
                                        name="start_time"
                                        disabled={date.from_date === '' || date.to_date === '' || index !== scheduleSets.length - 1}
                                        value={set.start_time}
                                        onChange={(e) => handleChange(e, index)}
                                    >
                                        {generateFilteredTimeOptions(timeOptions, scheduleSets, index, 'start', set.start_time).map((time, idx) => (
                                            <option key={idx} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='msinput-container col-4'>
                                    <label htmlFor="end_time">To</label>
                                    <select
                                        className={`msinput1 ${(error.end_time || error.set) ? 'error-border' : ''} ${(set.start_time === '' || index !== scheduleSets.length - 1) ? 'disabled-cursor' : 'pointer'}`}
                                        id="end_time"
                                        name="end_time"
                                        disabled={set.start_time === '' || index !== scheduleSets.length - 1}
                                        value={set.end_time}
                                        onChange={(e) => handleChange(e, index)}
                                    >
                                        {generateFilteredEndTimeOptions(timeOptions, set.start_time, scheduleSets, index).map((time, idx) => (
                                            <option key={idx} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className='msinput-container col-3'>
                                    {((error.token) && (index === scheduleSets.length - 1)) ? <label className="error-label">{error.token}</label> : <label>Token</label>}
                                    <input
                                        className={`msinput1 ${(((error.token) && (index === scheduleSets.length - 1)) || (error.set)) ? 'error-border' : ''} ${(set.start_time === '' || index !== scheduleSets.length - 1) ? 'disabled-cursor' : ''}`}
                                        id="token"
                                        name="token"
                                        disabled={set.start_time === '' || index !== scheduleSets.length - 1}
                                        value={set.token}
                                        type='number'
                                        min={0}
                                        max={100}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </div>
                                <div className='col-1' style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                                    <IoIosCloseCircleOutline onClick={() => handleRemoveSet(index)} style={{ margin: 0 }} className="file-remove-button" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className=''>
                    {error.days ? <label className="error-label">{error.days}</label> : <label>Working days</label>}
                    <div className='mschedule-daycontainer'>
                        {daysOfWeek.map((day) => (
                            <div key={day} className='day-checkbox'>
                                <input
                                    type="checkbox"
                                    id={day}
                                    name={day}
                                    value={day}
                                    checked={selectedDays.includes(day)}
                                    onChange={() => handleCheckboxChange(day)}
                                />
                                <label htmlFor={day}>{day}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className=''>
                    {error.physio ? <label className="error-label">{error.physio}</label> : <label>Select Physio</label>}
                    <div className='mschedule-date-inputcontainer'>
                        <div className='msinput-container col-10'>
                            <label>Select Allied Professional</label>
                            <Select
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: '44px',
                                        border: '1px solid #E3E3E3',
                                    })
                                }}
                                value={physio.value ? { value: physio.value, label: physio.name } : null}
                                onChange={physioChange}
                                options={physioOptions}
                                name="physio"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mschedule-button-container'>
                {!props.primary && (
                    <button ref={submitButtonRef} onClick={() => { props.close() }} className='done-button'>Cancel</button>
                )}
                <button ref={submitButtonRef} onClick={() => { validate() }} className='done-button'>Done</button>
            </div>
        </div >
    )
};

export default AddPhysioShedulePopup;
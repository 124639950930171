import React, { useState, useEffect, } from 'react'
import './csc.css'
import './bookings.css'
import axios from "axios";
import { APIURL } from "../../../Global";
import ConfirmationPopup from "./ConfirmationPopup";
import BookingSelection from './BookingsSelection'
import moment from 'moment';
import { useAlert } from 'react-alert';
import OpBookedPopup from './OpBookedPopup';
import OpBookingConfirmPopup from './OpBookingConfirmPopup';
import { BiEdit } from "react-icons/bi";
import RemarkPopup from './RemarkPopup';
const Bookings = () => {
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const role = userData.role
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [selected, setSelected] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [itemList, setItemList] = useState([])
    const [reload, setReload] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const [cancelId, setCancelId] = useState('')
    const [booked, setBooked] = useState(false);
    const [display, setDisplay] = useState(false)
    const [confirmationData, setConfirmationData] = useState({})
    const [patientId, setPatientId] = useState('')
    const [searchName, setSearchName] = useState('');
    const [remark, setRemark] = useState('')
    const [showRemark, setShowRemark] = useState(false);
    const alert = useAlert()
    const today = moment(new Date()).format('YYYY-MM-DD')
    const [access, setAccess] = useState(JSON.parse(sessionStorage.getItem("access")))

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (role !== 'alliedprofessional' && access.some(item => ["0.1.8.3"].includes(item.node_access))) {
            setSelected("GeneralOp")
        } else if (role !== 'alliedprofessional' && access.some(item => ["0.1.1.4"].includes(item.node_access))) {
            setSelected("DialysisOp")
        } else if ((role !== 'alliedprofessional' && access.some(item => ["0.1.2.4"].includes(item.node_access)))) {
            setSelected("PalliativeOp")
        } else if (role !== 'nurse' && access.some(item => ["0.1.3.4"].includes(item.node_access))) {
            setSelected("PhysioOp")
        }
    }, [access, role])

    const handlePopState = (e) => {
        e.preventDefault();
        console.log('called-->2');
    };

    useEffect(() => {
        if (showConfirmation) {
            window.history.pushState(null, "", window.location.href);
            window.addEventListener('popstate', handlePopState);

            return () => {
                window.removeEventListener('popstate', handlePopState);
            };
        }
    }, [showConfirmation]);

    useEffect(() => {
        console.log(remark)
        console.log(showRemark);
    }, [remark, showRemark]);

    useEffect(() => {
        setItemList([])
        if (selectedItem === '') {
            if (selected === "GeneralOp" || selected === 'PalliativeOp') {
                const tokenString = sessionStorage.getItem("usertoken");
                let str = tokenString.replace(/["]+/g, "");
                const endpoint = (selected === "GeneralOp") ? `booking/?date=${selectedDate}`
                    : `booking/?is_palliative=true&date=${selectedDate}`
                axios
                    .get(`${APIURL}/api/v1/patient/appointments/${endpoint}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.appointments ? res.data.appointments.map(item => ({ ...item, status: 'success' })) : [];
                            if (role !== "doctor" && role !== "alliedprofessional") {
                                let cancelledList = res.data.cancelled_appointments ? res.data.cancelled_appointments.map(item => ({ ...item, status: 'cancelled' })) : [];
                                let mergedList = [...messageList, ...cancelledList];
                                setItemList(mergedList);
                            } else {
                                setItemList(messageList)
                            }
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            } else if (selected === "DialysisOp") {
                const tokenString = sessionStorage.getItem("usertoken");

                let str = tokenString.replace(/["]+/g, "");

                axios
                    .get(`${APIURL}/api/v1/service-provider/dialysis-bookings/?date=${selectedDate}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.message ? res.data.message.map(item => ({ ...item, status: 'success' })) : [];
                            let cancelledList = res.data.cancelled_appointment ? res.data.cancelled_appointment.map(item => ({ ...item, status: 'cancelled' })) : [];
                            let mergedList = [...messageList, ...cancelledList];
                            setItemList(mergedList);
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            }
            else if (selected === 'PhysioOp') {
                const tokenString = sessionStorage.getItem("usertoken");
                let str = tokenString.replace(/["]+/g, "");
                axios
                    .get(`${APIURL}/api/v1/service-provider/allied-bookings/?date=${selectedDate}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.message || [];
                            let cancelledList = res.data.cancelled_appointmnet || [];
                            let mergedList = [...messageList, ...cancelledList];
                            setItemList(mergedList);
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, selected, reload, canceled])

    useEffect(() => {
        setItemList([])
        if (selectedItem !== '') {
            if (selected === "GeneralOp" || selected === 'PalliativeOp') {

                const tokenString = sessionStorage.getItem("usertoken");
                let str = tokenString.replace(/["]+/g, "");
                const doctorId = selectedItem.id
                const endpoint = selected === "GeneralOp" ? `booking/?doctor_id=${doctorId}&date=${selectedDate}` : `booking/?is_palliative=true&doctor_id=${doctorId}&date=${selectedDate}`
                axios
                    .get(`${APIURL}/api/v1/patient/appointments/${endpoint}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.appointments ? res.data.appointments.map(item => ({ ...item, status: 'success' })) : [];
                            let cancelledList = res.data.cancelled_appointments ? res.data.cancelled_appointments.map(item => ({ ...item, status: 'cancelled' })) : [];
                            let mergedList = [...messageList, ...cancelledList];
                            setItemList(mergedList);
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            } else if (selected === 'DialysisOp') {

                const tokenString = sessionStorage.getItem("usertoken");
                let str = tokenString.replace(/["]+/g, "");
                const machineId = selectedItem.id
                axios
                    .get(`${APIURL}/api/v1/service-provider/dialysis-bookings/?date=${selectedDate}&dialysis_unit=${machineId}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.message ? res.data.message.map(item => ({ ...item, status: 'success' })) : [];
                            let cancelledList = res.data.cancelled_appointment ? res.data.cancelled_appointment.map(item => ({ ...item, status: 'cancelled' })) : [];
                            let mergedList = [...messageList, ...cancelledList];
                            setItemList(mergedList);
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            }
            else {

                const tokenString = sessionStorage.getItem("usertoken");
                let str = tokenString.replace(/["]+/g, "");
                const alliedId = selectedItem.id
                axios
                    .get(`${APIURL}/api/v1/service-provider/allied-bookings/?date=${selectedDate}&allied_id=${alliedId}`, {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            let messageList = res.data.message || [];
                            let cancelledList = res.data.cancelled_appointmnet || [];
                            let mergedList = [...messageList, ...cancelledList];
                            setItemList(mergedList);
                        }
                        else {
                        }
                    })
                    .catch((err) => {
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem, selectedDate, canceled])

    const handleContinue = (item) => {
        const appointment_id = item.id
        const arriving_start = moment(item.from_time, 'HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss')
        const arriving_end = moment(item.from_time, 'HH:mm:ss').subtract(30, 'minutes').format('HH:mm:ss')
        const patient_name = item.patient
        const date = item.date;
        const physio_name = item.allied_prof;
        const time = `${item.from_time} - ${item.to_time}`;
        const arriving_time = `${arriving_start} - ${arriving_end}`;

        setConfirmationData({
            appointment_id,
            patient_name,
            physio_name,
            date,
            time,
            arriving_time
        });

        setDisplay(true);
    }

    const handleSuccess = () => {
        setCanceled(!canceled)
        setBooked(false);
    };

    const handleConfirmation = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        const payload = {
            appt_id: confirmationData.appointment_id,
            status: 'success'
        }

        axios
            .put(`${APIURL}/api/v1/patient/allied-appointment-detail/`, payload, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success("Booking completed successfully")
                    setDisplay(false)
                    setBooked(true)
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
                alert.error('error occured')
            });
    }

    const handleCanclation = (item) => {
        setCancelId(item.id)
        setPatientId(item.patient_id && item.patient_id)
        setShowConfirmation(true)
    }

    const handleConfirm = (comment) => {
        console.log('confirmed with comment:', comment);
        if (selected === "GeneralOp" || selected === "PalliativeOp") {
            handleCancelGeneral(comment);
        } else if (selected === "DialysisOp") {
            handleCancelDialysis(comment);
        } else {
            handleCancelPhysio(comment);
        }
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    }

    const handleCancelDialysis = (comment) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const payload = {
            booking_id: cancelId,
            remarks: comment
        }
        axios
            .put(`${APIURL}/api/v1/service-provider/dialysis-booking-detail/?cancel=True`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success(res.data.message)
                    setCanceled(!canceled)
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
            });
    }

    const handleCancelGeneral = (comment) => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        const data = {
            patient_id: patientId,
            booking_id: cancelId,
            remarks: comment
        }

        axios
            .put(`${APIURL}/api/v1/patient/appointments/cancel/`, data, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success(res.data.message)
                    setCanceled(!canceled)
                }
                else {
                    alert.error(res.data.messsage)
                }
            })
            .catch((err) => {
                alert.error('Error occured')
            });

    }

    const handleCancelPhysio = (comment) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const payload = {
            booking_id: cancelId,
            remarks: comment
        }
        axios
            .put(`${APIURL}/api/v1/service-provider/allied-booking-detail/?cancel=True`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success(res.data.message)
                    setCanceled(!canceled)
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
            });
    }

    const getFirstTimeFromSlot = (slot) => {
        const to_time = slot && slot.split(' - ')[1];
        return to_time;
    };

    const isSlotCancellable = (time) => {
        const currentTime = moment();
        const slotDateTime = moment(`${selectedDate} ${time}`, 'YYYY-MM-DD HH:mm:ss');
        if (currentTime.isBefore(slotDateTime)) {
            return true;
        } else {
            return false;
        }
    };

    const filteredList = searchName === '' ? itemList : itemList.filter(item => {
        const nameToMatch = selected === 'GeneralOp' ? item.patient_name : item.patient;
        return nameToMatch?.toLowerCase().includes(searchName.toLowerCase());
    });

    const handleView = (remark) => {
        setRemark(remark)
        setShowRemark(true)
    }
    const handleConsultation = (id, patid) => {
        const tokenString = sessionStorage.getItem("usertoken");
        const str = tokenString.replace(/["]+/g, "");

        axios
            .put(
                `${APIURL}/api/v1/doctor/consultation-status/${id}/?consultant_id=${userData.user_id}&patient_id=${patid}&type=dialysis`,
                {},
                {
                    headers: {
                        "Authorization": 'Token ' + str
                    }
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success(res.data.message);
                    setCanceled(!canceled)
                } else if (res.data.status === "error") {
                    alert.error(res.data.message ? res.data.message : "Error in submission!");
                } else {
                    alert.error("Error in submission!");
                }
            })
            .catch((err) => {
                alert.error("Error in submission!");
            });
    };
    return (
        <div className='csc-primary-container'>
            <BookingSelection
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                selected={selected}
                setReload={setReload}
                reload={reload}
                role={role}
                from={'csc'}
            />
            <div className="col-lg-9 col-md-9 container">
                <div className='bookings'>
                    <div className='bookings-buttoncontainer'>
                        {role !== "alliedprofessional" && <>
                            {access.some(item => ["0.1.8.3"].includes(item.node_access)) && <button className={(selected === "GeneralOp") ? 'color-change' : ''} onClick={() => { setSelected("GeneralOp"); setSelectedItem('') }}>General Op</button>}
                            {((role !== 'doctor') && access.some(item => ["0.1.1.4"].includes(item.node_access))) && <button className={(selected === "DialysisOp") ? 'color-change' : ''} onClick={() => { setSelected("DialysisOp"); setSelectedItem('') }}>Dialysis Op</button>}
                            {access.some(item => ["0.1.2.4"].includes(item.node_access)) && <button className={(selected === "PalliativeOp") ? 'color-change' : ''} onClick={() => { setSelected("PalliativeOp"); setSelectedItem('') }}>Palliative Op</button>}
                        </>}
                        {((role === 'bdo' || role === 'alliedprofessional' || role === 'front_office') && access.some(item => ["0.1.3.4"].includes(item.node_access))) && <button className={(selected === "PhysioOp") ? 'color-change' : ''} onClick={() => { setSelected("PhysioOp"); setSelectedItem('') }}>Physio Op</button>}
                        <div style={{ flex: 3 }}></div>
                    </div>
                    <div style={{ display: 'flex', margin: " 5px 0px" }}>
                        <div className='input-container-booking col-6'>
                            <label>Name</label>
                            <div className='input1' style={{ display: 'flex', backgroundColor: '#fff' }}>
                                <svg style={{}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.3851 15.4461C13.0371 16.5232 11.3278 17.0432 9.60827 16.8994C7.88877 16.7556 6.28961 15.9588 5.13922 14.6728C3.98883 13.3867 3.37455 11.709 3.42254 9.98415C3.47052 8.25932 4.17713 6.61834 5.39725 5.39822C6.61736 4.17811 8.25835 3.4715 9.98318 3.42351C11.708 3.37553 13.3857 3.98981 14.6718 5.1402C15.9579 6.29058 16.7546 7.88975 16.8984 9.60924C17.0422 11.3287 16.5222 13.038 15.4451 14.3861L20.6011 19.5411C20.6748 19.6097 20.7339 19.6925 20.7749 19.7845C20.8159 19.8765 20.8379 19.9758 20.8397 20.0765C20.8415 20.1772 20.8229 20.2773 20.7852 20.3707C20.7475 20.4641 20.6913 20.5489 20.6201 20.6201C20.5489 20.6913 20.4641 20.7475 20.3707 20.7852C20.2773 20.8229 20.1773 20.8414 20.0766 20.8397C19.9759 20.8379 19.8766 20.8158 19.7846 20.7748C19.6926 20.7339 19.6098 20.6748 19.5411 20.6011L14.3851 15.4461ZM6.46009 13.8841C5.72613 13.15 5.22624 12.2149 5.0236 11.1968C4.82096 10.1788 4.92466 9.1235 5.3216 8.16437C5.71854 7.20523 6.3909 6.38529 7.2537 5.80817C8.11651 5.23105 9.13103 4.92265 10.1691 4.92195C11.2071 4.92125 12.222 5.22828 13.0856 5.80424C13.9492 6.3802 14.6226 7.19924 15.0209 8.15784C15.4191 9.11644 15.5242 10.1716 15.323 11.1899C15.1217 12.2082 14.6231 13.144 13.8901 13.8791L13.8851 13.8841L13.8801 13.8881C12.8952 14.8707 11.5605 15.4222 10.1693 15.4214C8.77801 15.4207 7.44394 14.8678 6.46009 13.8841Z" fill="#777777" />
                                </svg>
                                <input className=''
                                    style={{ border: 'none', width: '100%' }}
                                    placeholder='Search Patient...'
                                    value={searchName}
                                    onChange={(e) => setSearchName(e.target.value)} />

                            </div>
                        </div>
                    </div>
                    <div className='booklist-container' >
                        {((selected === 'GeneralOp' || selected === 'PalliativeOp') && filteredList.length > 0) ? (
                            <div>
                                <div style={{ display: 'flex', padding: '0px 15px' }}>
                                    <label className='col-3'>Patient</label>
                                    <label className='col-3'>Doctor</label>
                                    <label className='col-3'>Time slot</label>
                                    <label className='col-1' style={{ padding: 0 }}>Token</label>
                                    <label className='col-2'></label>
                                </div>
                                {filteredList.map((item, index) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className='bookingslist'
                                        >
                                            <div className='col-3'>
                                                <label className="head-style" style={{ textTransform: 'capitalize' }}> {item.patient_name}</label>
                                            </div>
                                            <div className='col-3'>
                                                <label className="head-style" style={{ textTransform: 'capitalize' }}> {item.doctor_name}</label>
                                            </div>
                                            <div className='col-3'>
                                                <label className="head-style"> {item.slot}</label>
                                            </div>
                                            <div className='col-1' style={{ padding: 0 }}>
                                                <label className="head-style">Token &nbsp;{item.token}</label>
                                            </div>

                                            <div className='col-2' style={{}}>
                                                {item.status === 'cancelled' ? (
                                                    <label style={{ color: '#000' }}>
                                                        <BiEdit style={{ marginLeft: 'auto', color: 'rgb(75, 156, 223)' }} onClick={() => handleView(item.remarks)} className='icon-item' />
                                                        <b>Cancelled</b>
                                                    </label>
                                                ) : item.service_status === 'completed' ?
                                                    <label style={{ color: '#000' }}><b>Completed</b></label>
                                                    : (
                                                        isSlotCancellable(getFirstTimeFromSlot(item.slot)) && (
                                                            <button className='cancel-butn' onClick={() => { handleCanclation(item) }}>Cancel</button>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (selected === 'DialysisOp' && filteredList.length > 0) ? (
                            <div>
                                <div style={{ display: 'flex', padding: '0px 15px' }}>
                                    <label className='col-4'>Patient</label>
                                    <label className='col-3'>Machine</label>
                                    <label className='col-3'>Time slot</label>
                                    <label className='col-2'></label>
                                </div>
                                {filteredList.map((item, index) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className='bookingslist'
                                        >
                                            <div className='col-4'>
                                                <label className="head-style" style={{ textTransform: 'capitalize' }}> {item.patient}</label>
                                            </div>
                                            <div className='col-3'>
                                                <label className="head-style">{item.dialysis_unit}</label>
                                            </div>
                                            <div className='col-3'>
                                                <label className="head-style"> {item.from_time}&nbsp;-&nbsp;{item.to_time}</label>
                                            </div>
                                            <div className='col-2'>
                                                {item.status === 'cancelled' ? (
                                                    <label style={{ color: '#000' }}>
                                                        <BiEdit style={{ marginLeft: 'auto', color: 'rgb(75, 156, 223)' }} onClick={() => handleView(item.remarks)} className='icon-item' />
                                                        <b>Cancelled</b>
                                                    </label>
                                                ) : item.service_status === "completed" ?
                                                    <button style={{ backgroundColor: "lightgreen", color: "green", width: "100%" }} className='cancel-butn'>Assessed</button>
                                                    : isSlotCancellable(item.from_time) ?
                                                        <button style={{ width: "100%" }} className='cancel-butn' onClick={() => { handleCanclation(item) }}>Cancel</button>
                                                        : (selectedDate === today) && <button onClick={() => { handleConsultation(item.id, item.patient_id); }} style={{ backgroundColor: "lightblue", color: "blue", width: "100%" }} className='cancel-butn'>Start</button>

                                                }

                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : ((selected === 'PhysioOp') && filteredList.length > 0) ? (
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <label className='col-3'>Patient</label>
                                    <label className='col-3'>Allied Professionals</label>
                                    <label className='col-2'>Time Slot</label>
                                    <label className='col-1' style={{ padding: 0 }}>Token</label>
                                    <label className='col-1'></label>
                                    <label className='col-2'></label>
                                </div>
                                {filteredList.map((item, index) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className='bookingslist'
                                        >
                                            <div className='col-3'>
                                                <label className="head-style" style={{ textTransform: 'capitalize' }}> {item.patient}</label>
                                            </div>
                                            <div className='col-3'>
                                                <label className="head-style" style={{ textTransform: 'capitalize' }}> {item.allied_prof}</label>
                                            </div>
                                            <div className='col-2' style={{ padding: 0 }}>
                                                <label className="head-style"> {item.from_time}&nbsp;-&nbsp;{item.to_time}</label>
                                            </div>
                                            <div className='col-1' style={{ padding: 0 }}>
                                                <label className="head-style">Token &nbsp;{item.token}</label>
                                            </div>
                                            <div className='col-1' style={{ padding: 0, display: 'flex' }}>
                                                {(item.status && item.status === 'reserved' && isSlotCancellable(item.from_time)) &&
                                                    <button className='confirm-butn' disabled={selectedDate < today} onClick={() => { handleContinue(item) }}>Confirm</button>
                                                }
                                            </div>
                                            <div className='col-2'>
                                                {item.status === 'cancelled' ? (
                                                    <label style={{ color: '#000' }}>
                                                        <BiEdit style={{ marginLeft: 'auto', color: 'rgb(75, 156, 223)' }} onClick={() => handleView(item.remarks)} className='icon-item' />
                                                        <b>Cancelled</b>
                                                    </label>
                                                ) : item.service_status === 'completed' ?
                                                    <label style={{ color: '#000' }}><b>Completed</b></label>
                                                    : (
                                                        isSlotCancellable(item.to_time) && (
                                                            <button className='cancel-butn' onClick={() => { handleCanclation(item) }}>Cancel</button>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : <div className="" style={{ textAlign: 'center', margin: "10%", color: "red" }}>No Appointments!!</div>
                        }
                    </div>
                </div>
            </div>
            {
                showConfirmation && (
                    <ConfirmationPopup
                        message="Are you sure you want to cancel the booking?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )
            }
            {display && <OpBookingConfirmPopup content={confirmationData} close={() => setDisplay(false)} confirm={handleConfirmation} />}
            {booked && <OpBookedPopup content={confirmationData} close={handleSuccess} />}
            {showRemark && <RemarkPopup content={remark} close={() => { setShowRemark(false); setRemark('') }} />}
        </div >
    )
}
export default Bookings
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from 'react-alert';
import moment from 'moment';
import peace from "../../../assets/images/icons/peacevillage.png";
import printJS from 'print-js'
import '../../HospSearchPatient/discharge.css';
const DischargeForm = (props) => {
    console.log(props.patient)
    const html2pdf = require('html2pdf.js');

    const contentRef = useRef();
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );

    const [data, setData] = useState({});

    const alert = useAlert();

    useEffect(() => {
        reload()
    }, []);

    const generatePDF = () => {
        const element = contentRef.current;
        const fileName = `${props.patient.patient_name}_${props.patient.admission_number}.pdf`;
        html2pdf()
            .from(element)
            .set({
                filename: fileName,
                jsPDF: { format: 'a4' },
            })
            .outputPdf('blob')
            .then((pdfBlob) => {
                // Manually download the file with the specified filename
                const url = window.URL.createObjectURL(pdfBlob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);

                // Convert blob to File and submit
                const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
                submit(pdfFile);
            });
    };

    const handlePrint = () => {
        printJS({
            printable: 'print-invoice',  // ID of the element to print
            type: 'html',
            targetStyles: ['*'],
        });
        props.onSuccess();
    };

    const submit = (file) => {
        const tokenString = sessionStorage.getItem('usertoken');
        const formData = new FormData();
        formData.append('discharge_summary', file);
        const token = tokenString.replace(/["]+/g, '');

        axios.put(`${APIURL}/api/v1/service-provider/complete-discharge/?admission_number=${props.patient.admission_number}&department=${props.department}`, formData, {
            headers: {
                "Authorization": 'Token ' + token
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success(res.data.message);
                    handlePrint();
                } else {
                    alert.error(res.data.message);
                }
            });
    };


    const reload = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/doctor/discharge-patient/?post_discharge=true&admission_id=${props.patient.admission_number}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setData(res.data.message);
                } else {
                    alert.error(res.data.message);
                }
            });
    }

    const formatDate = (admissionDate) => {
        const cleanDate = admissionDate && admissionDate.replace('IST', '').trim();
        const dateOnly = moment(cleanDate, "YYYY-MM-DD HH:mm:ss Z").format("YYYY-MM-DD");
        console.log(dateOnly)
        return dateOnly;
    };

    const InfoRow = ({ label }) => {
        return (
            <div className="col-12 flex-dis">
                <div className="col-2 zero-padding" style={{ textAlign: 'left' }}>
                    <label><b>{label}</b></label>
                </div>
                <div className="col-10 zero-padding">
                    <hr className="lines" />
                </div>
            </div>
        );
    };

    return (
        <>
            {data.id ? <><div ref={contentRef} id='print-invoice' style={{ padding: "20px" }}>
                <div className='row'>
                    <div className='col-8'>
                        <div style={{ display: "flex", }}><img src={peace} width="20%" height="20%" alt="image" />
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_state},{login_datas.hospital_location},{login_datas.hospital_street}</small><br />
                            phone: <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_mobile}</small><br />
                            <small className="invoice-heading invoice-heading-normal">{login_datas.hospital_email}</small>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ textAlign: "left" }}>
                            <h6 style={{ color: "#2B69A3" }}>Issued to:</h6>
                            <small className="invoice-heading invoice-heading-normal" style={{ textTransform: 'capitalize' }}>Patient: {props.patient.patient_name}</small><br />
                            <small className="invoice-heading invoice-heading-normal">Patient ID: {props.patient.patient_id}</small><br />
                            <small className="invoice-heading invoice-heading-normal">Admission Number: {props.patient.admission_number}</small><br />
                            <small className="invoice-heading invoice-heading-normal">Admission Date: {data?.admission_date && formatDate(data.admission_date)}</small>
                        </div>
                    </div>
                </div><br />
                <h6 className='discharge-head2 col-12'>Discharge summary</h6><br />
                <div className='row' >
                    <div className='col-4'>
                        <small style={{ fontWeight: "bolder", textTransform: 'capitalize' }} className="invoice-heading invoice-heading-normal"> {data.discharging_doctor_name}</small><br />
                    </div>
                    <div className='col-4'>
                        <small style={{ fontWeight: "bolder", textTransform: 'capitalize' }} className="invoice-heading invoice-heading-normal">Department: {data.department_name}</small>
                    </div>
                    <div className='col-4'>
                        <small style={{ fontWeight: "bolder" }} className="invoice-heading invoice-heading-normal">Date: {(new Date() + "").slice(4, 16)}</small>
                    </div>
                </div><br />

                {data?.diagnosis && data.diagnosis.length > 0 && (
                    <>
                        <label className='col-12 side-headings'>Diagnosis:</label>
                        {data.diagnosis.map((item, index) => (
                            <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" key={index}>
                                <small style={{ textAlign: "left" }}>{item.created_at}:</small>&nbsp;
                                <small >{item.diagnosis.charAt(0).toUpperCase() + item.diagnosis.slice(1)}</small>
                            </div>
                        ))}
                    </>
                )}
                {data?.symptoms && data.symptoms.length > 0 && (
                    <><br />
                        <label className='col-12 side-headings'>Symptoms:</label>
                        {data.symptoms.map((item, index) => (
                            <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" key={index}>
                                <small style={{ textAlign: "left" }}>{item.created_at}:</small>&nbsp;
                                <small >{item.diagnosis.charAt(0).toUpperCase() + item.diagnosis.slice(1)}</small>
                            </div>
                        ))}
                    </>
                )}

                {data?.observations && data.observations.length > 0 && (
                    <><br />
                        <label className='col-12 side-headings'>Observations:</label>
                        {data.observations.map((item, index) => (
                            <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" key={index}>
                                <small style={{ textAlign: "left" }}>{item.created_at}:</small>&nbsp;
                                <small >{item.observation.charAt(0).toUpperCase() + item.observation.slice(1)}</small>
                            </div>
                        ))}
                    </>
                )}
                {data?.family_history && (
                    <><br />
                        <label className='col-12 side-headings'>Family history:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.family_history.charAt(0).toUpperCase() + data.family_history.slice(1)}</small>
                        </div>
                    </>
                )}
                {data?.medical_history && (
                    <><br />
                        <label className='col-12 side-headings'>Medical history:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.medical_history.charAt(0).toUpperCase() + data.medical_history.slice(1)}</small>
                        </div>
                    </>
                )}
                {data?.admission_remarks && (
                    <><br />
                        <label className='col-12 side-headings'>Admission remarks:</label>
                        <div style={{ marginBottom: "2%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.admission_remarks.charAt(0).toUpperCase() + data.admission_remarks.slice(1)}</small>
                        </div>
                    </>
                )}
                {data?.vitals?.length > 0 && (
                    <><br />
                        <label className='col-12 side-headings'>Vitals:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.vitals}</small>
                        </div>

                    </>
                )}
                {((data.fulfilled_prescriptions && data.fulfilled_prescriptions.medicines && data.fulfilled_prescriptions.medicines.length > 0) ||
                    (data.fulfilled_careplans && data.fulfilled_careplans.scan && data.fulfilled_careplans.scan.length > 0) ||
                    (data.fulfilled_careplans && data.fulfilled_careplans.procedure && data.fulfilled_careplans.procedure.length > 0) ||
                    (data.fulfilled_careplans && data.fulfilled_careplans.labtest && data.fulfilled_careplans.labtest.length > 0)
                ) && <><br /> <InfoRow label="Treatment given" /></>
                    // <label style={{ textAlign: 'left' }} className='col-12'><b>Treatment given</b></label>
                }

                {data?.fulfilled_prescriptions?.length > 0 && (
                    <> <br /><label className='col-12 side-headings'>Prescription:</label>
                        <div className='row' style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ textAlign: "left", display: 'flex' }} className="data-dis-summary">
                                <small className='col-5' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Drug</small>
                                <small className='col-2' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Intake unit</small>
                                <small className='col-2' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Frequency</small>
                                <small className='col-3' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Duration</small>
                            </div>
                            {data.fulfilled_prescriptions.map((item, index) => (
                                <div style={{ textAlign: "left", display: 'flex' }} className="data-dis-summary" key={index}>
                                    <small className='col-5' style={{ textAlign: "left" }}>{item.drug}</small>
                                    <small className='col-2' style={{ textAlign: "left" }}>{item.intake_count}{item.intake_unit}</small>
                                    <small className='col-2' style={{ textAlign: "left" }}>{item.frequency || '-'}</small>
                                    <small className='col-3' style={{ textAlign: "left" }}>{item.intake}{(item.intake && item.duration) && '-'}{item.duration}{item.duration_unit}</small>
                                </div>
                            ))}
                        </div></>
                )}
                {data?.fulfilled_careplans ? (data.fulfilled_careplans.scan?.length > 0 || data.fulfilled_careplans.procedure?.length > 0 || data.fulfilled_careplans.test?.length > 0) &&
                    <><br /><label className='col-12 side-headings'>Careplans</label></> : null}
                <div className='row' style={{ display: 'flex', flexDirection: 'column' }}>
                    {data ? data.fulfilled_careplans && data.fulfilled_careplans.scan && data.fulfilled_careplans.scan.length > 0 && (
                        <div>
                            {data.fulfilled_careplans.scan.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary " key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}> {item.scan_name}</small>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.created_at}</small>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                    {data ? data.fulfilled_careplans && data.fulfilled_careplans.labtest && data.fulfilled_careplans.labtest.length > 0 && (
                        <div >
                            {data.fulfilled_careplans.labtest.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary " key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.test_name}</small><br />
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.created_at}</small>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                    {data ? data.fulfilled_careplans && data.fulfilled_careplans.procedure && data.fulfilled_careplans.procedure.length > 0 && (
                        <div >
                            {data.fulfilled_careplans.procedure.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary" key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.procedure_name}</small>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.created_at}</small>
                                    {/* {item.procedure && <small className='col-4' style={{ textAlign: "left" }}>{item.precedure_details.charAt(0).toUpperCase() + item.precedure_details.slice(1)}</small>} */}
                                </div>
                            ))}
                        </div>
                    ) : ""}
                </div>
                {data?.discharge_remarks && (
                    <><br />
                        <label className='col-12 side-headings'>Discharge remarks:</label>
                        <div style={{ marginBottom: "2%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.discharge_remarks.charAt(0).toUpperCase() + data.discharge_remarks.slice(1)}</small>
                        </div>
                    </>
                )}
                {(data?.instructions || data?.post_discharge_careplans.scans.length > 0 || data?.post_discharge_careplans.tests.length > 0 || data?.post_discharge_careplans.procedures.length > 0 || data?.post_discharge_prescriptions.medicines.length > 0 || data?.diet || data?.next_visit) && <><br /><InfoRow label="Discharge plan" /></>}
                {data?.instructions && (
                    <><br />
                        <label className='col-12 side-headings'>Instructions:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.instructions.charAt(0).toUpperCase() + data.instructions.slice(1)}</small>
                        </div>
                    </>
                )}
                {data?.diet && (
                    <><br />
                        <label className='col-12 side-headings'>Diet:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.diet.charAt(0).toUpperCase() + data.diet.slice(1)}</small>
                        </div>
                    </>
                )}
                {data?.next_visit && (
                    <><br />
                        <label className='col-12 side-headings'>Follow up:</label>
                        <div style={{ marginBottom: "1%", marginLeft: "2%", textAlign: "left" }} className="data-dis-summary" >
                            <small style={{ textAlign: "left" }}>{data.next_visit}</small>
                        </div>
                    </>
                )}


                {data ? data.post_discharge_prescriptions && data.post_discharge_prescriptions.medicines && data.post_discharge_prescriptions.medicines.length > 0 && (
                    <><br />
                        <label className='col-12 side-headings'>Prescription:</label>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ textAlign: "left", display: 'flex' }} className="data-dis-summary">
                                <small className='col-5' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Drug</small>
                                <small className='col-2' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Intake unit</small>
                                <small className='col-2' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Frequency</small>
                                <small className='col-3' style={{ textAlign: "left", fontWeight: 600, color: '#adabab' }}>Duration</small>
                            </div>
                            {data.post_discharge_prescriptions.medicines.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary" key={index}>
                                    <small className='col-5' style={{ textAlign: "left" }}>{item.drug}</small>
                                    <small className='col-2' style={{ textAlign: "left" }}>{item.intake_count}{item.intake_unit}</small>
                                    <small className='col-2' style={{ textAlign: "left" }}>{item.frequency || '-'}</small>
                                    <small className='col-3' style={{ textAlign: "left" }}>{item.intake}{(item.intake && item.duration) && '-'}{item.duration}{item.duration_unit}</small>
                                </div>
                            ))}
                        </div>
                    </>
                ) : ""}
                {(data?.post_discharge_careplans?.scans?.length > 0 || data?.post_discharge_careplans?.tests?.length > 0 || data?.post_discharge_careplans?.procedures?.length > 0) && <><br /><label className='col-12 side-headings'>Careplans</label></>}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {data ? data.post_discharge_careplans && data.post_discharge_careplans.scans && data.post_discharge_careplans.scans.length > 0 && (
                        <div>
                            {data.post_discharge_careplans.scans.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary " key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}>  {item.scan_name}</small>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.date}</small>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                    {data ? data.post_discharge_careplans && data.post_discharge_careplans.tests && data.post_discharge_careplans.tests.length > 0 && (
                        <div>
                            {data.post_discharge_careplans.tests.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary " key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.test_name}</small>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.date}</small>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                    {data ? data.post_discharge_careplans && data.post_discharge_careplans.procedures && data.post_discharge_careplans.procedures.length > 0 && (
                        <><div>
                            {data.post_discharge_careplans.procedures.map((item, index) => (
                                <div style={{ marginBottom: "1%", textAlign: "left", display: 'flex' }} className="data-dis-summary" key={index}>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.procedure_name}</small>
                                    <small className='col-4' style={{ textAlign: "left" }}>{item.date}</small>
                                </div>
                            ))}
                        </div>
                        </>
                    ) : ""}
                </div>
                {data?.report_prepared_by && (
                    <>
                        <div style={{ textAlign: "right", marginRight: '20px' }} className="data-dis-summary" >
                            <br />
                            <h6><b>Prepared by:</b></h6>
                            <small style={{ textTransform: "capitalize" }}>{data.report_prepared_by}</small>
                        </div>
                    </>
                )}
                <br />
            </div >
                <div className='col-12'>
                    <button className="btn btn-primary btn-col btn-square" id="save-btn" onClick={() => { (props.selected !== "Shelter Home" && props.patient.patient_type === "shelter") ? generatePDF() : !props.patient.payment_status ? generatePDF() : alert.error("Kindly ensure the payment is cleared.") }}> Save and Print </button>
                </div></> :
                <div>
                    <div className="" style={{ color: "#ffa700" }}><h5>Server error occurred. Please try again later.</h5></div>
                </div>
            }
        </>
    );
};

export default DischargeForm;

import React, { useImperativeHandle, useState, useEffect, useRef, forwardRef } from 'react';
import axios from "axios";
import { APIURL } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { useAlert } from "react-alert";
import moment from "moment";

const AddScan = forwardRef((props, ref) => {
    const [n, setN] = useState(1);
    const [currentIndex, setCurrentIndex] = useState("");
    const [scanSuggest, setScanSuggest] = useState([]);
    const [searchScan, setSearchScan] = useState("")
    const [prevScan, setPrevScan] = useState([])
    const [scanList, setScanList] = useState([
        {
            id: "",
            scan_name: "",
            date: "",
            scan_details: "",
            created_by: "",
            hospital_name: ""
        },
    ]);
    const alert = useAlert();
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas")) || null;
    const delConfirmRef = useRef(null);

    useEffect(() => {
        const handleKey = (e) => {
            if (e.altKey && e.key === 's') {
                e.preventDefault();
                delConfirmRef.current.click();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                delConfirmRef.current.click();
            } else if (e.altKey && e.key === 'c') {
                e.preventDefault();
                props.setSelected('current')
            }
        };
        document.addEventListener("keydown", handleKey);
        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);

    useEffect(() => {
        const processPrevScanData = (scanData) => {
            return scanData.map(item => ({
                id: item.scan,
                date: item.date,
            }));
        };

        const ScanData = async () => {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            await axios
                .get(`${APIURL}/api/v1/doctor/scans-list/?patient_id=${props.id}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success" && res.data.message.length > 0) {
                        const prevScans = processPrevScanData(res.data.message);
                        setPrevScan(prevScans);
                    }
                });
        };

        ScanData();
    }, []);

    const handleSubmitScan = () => {
        const updatedScans = scanList.filter(row => row.scan_name);
        const isValid = updatedScans.every(item => item.scan_name && item.date);

        if (!isValid) {
            alert.error("Please add date for scan");
        }
        else {
            props.onScanUpdate(updatedScans);
        }
        return isValid
    };

    useEffect(() => {
        if (searchScan !== '') {
            const tokenString = sessionStorage.getItem("usertoken")?.replace(/["]+/g, "");

            if (tokenString && (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof")) {
                axios.get(`${APIURL}/api/v1/doctor/scans/?patient_id=${props.id}&search=${searchScan}`, {
                    headers: { Authorization: "Token " + tokenString }
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setScanSuggest(res.data.message);
                        }
                    });
            }
        }
    }, [searchScan]);

    const deleteRowScan = (index) => {
        if (index === 0 && scanList.length === 1) {
            setScanList([{
                id: "",
                scan_name: "",
                date: "",
                scan_details: "",
                created_by: "",
                hospital_name: ""
            },]);
        } else {
            const newList = scanList.filter((_, i) => i !== index);
            setScanList(newList);
        }
    };

    const renderEditableScan = (cellInfo) => (
        <div>
            <input
                style={{ backgroundColor: "#fafafa" }}
                value={scanList[cellInfo.index][cellInfo.column.id]}
                className="form-control"
                onChange={(e) => {
                    const updatedList = [...scanList];
                    updatedList[cellInfo.index].scan_name = e.target.value;
                    setScanList(updatedList);
                    setSearchScan(e.target.value);
                    setCurrentIndex(cellInfo.index);
                    if (e.target.value === "") setScanSuggest([]);
                }}
                autoComplete="off"
            />
            {currentIndex === cellInfo.index && scanSuggest.length > 0 && (
                <div className="suggestions-box">
                    {scanSuggest.map((item, i) => (
                        <div key={i} className="scan-item" onClick={() => {
                            const updatedList = [...scanList];
                            updatedList[cellInfo.index].scan_name = item.scan_name;
                            updatedList[cellInfo.index].id = item.id;
                            setScanList(updatedList);
                            setScanSuggest([]);
                        }}>
                            <p className="search-item">{item.scan_name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const renderEditableScanDate = (cellInfo) => {
        const today = moment().format("YYYY-MM-DD");

        const checkForDuplicateScan = (scanId, scanDate) => {
            const duplicateInPrevScans = prevScan.some(
                (scan) => scan.id === scanId && scan.date === scanDate
            );

            const duplicateInCurrentScans = scanList.some(
                (scan, index) =>
                    scan.id === scanId &&
                    scan.date === scanDate &&
                    index !== cellInfo.index
            );

            return duplicateInPrevScans || duplicateInCurrentScans;
        };

        return (
            <input
                style={{ fontSize: '14px', width: '100%', height: '44px', border: '1px solid #cccccc87', borderRadius: '4px' }}
                type="date"
                min={today}
                disabled={scanList[cellInfo.index].id === ''}
                value={scanList[cellInfo.index].date || ""}
                onChange={(e) => {
                    const updatedDate = e.target.value;
                    const updatedList = [...scanList];
                    const scanId = updatedList[cellInfo.index].id;

                    if (checkForDuplicateScan(scanId, updatedDate)) {
                        updatedList[cellInfo.index].date = ''
                        setScanList(updatedList);
                        alert.error("A scan with the same ID and date already exists.");
                        return;
                    }
                    else {
                        updatedList[cellInfo.index].date = updatedDate;
                        setScanList(updatedList);
                    }
                }}
            />
        );
    };


    const renderDetails = (cellInfo) => (
        <textarea
            className="text-area"
            placeholder="Add Scan Details"
            value={scanList[cellInfo.index].scan_details || ""}
            onChange={(e) => {
                const updatedList = [...scanList];
                updatedList[cellInfo.index].scan_details = e.target.value;
                setScanList(updatedList);
            }}
            cols="100"
            rows="2"
        />
    );

    const AddRowScan = () => {
        setN(n + 1)
        const newList = [...scanList, { id: "", scan_name: "", date: "", scan_details: "" }];
        setScanList(newList);
    };

    const columnsScans = [
        {
            Header: <i className="fa fa-trash"></i>,
            Cell: (props) => (
                <i
                    style={{ marginLeft: "30%" }}
                    className="fa fa-trash trash-btn"
                    onClick={() => deleteRowScan(props.index)}
                />
            ),
            width: 50,
            sortable: false,
            filterable: false,
        },
        {
            Header: "Scan Name",
            accessor: "scan_name",
            Cell: renderEditableScan,
            width: 250,
            sortable: false,
        },
        {
            Header: "Date (YYYY-MM-DD)",
            accessor: "date",
            Cell: renderEditableScanDate,
            sortable: false,
            width: 150,
        },
        {
            Header: "Scan Details",
            accessor: "scan_details",
            Cell: renderDetails,
            sortable: false,
        },
        {
            Header: "Add New",
            Cell: (props) => (
                props.index === scanList.length - 1 && scanList[props.index].id && (
                    <button className="btn btn-primary" onClick={AddRowScan}> + </button>
                )
            ),
            sortable: false,
            filterable: false,
        },
    ];

    useImperativeHandle(ref, () => ({
        handleSubmitScan
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ReactTable
                columns={columnsScans}
                data={scanList}
                pageSize={n}
                showPagination={false}
                className="observation-table"
            />
            <br />
        </div>
    );
});

export default AddScan;

import React, { useState } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import './hospprofile.css'
import { Button, Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function DescriptionUpdate(props) {

  const [desc, setDesc] = useState(props.des)
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const alert = useAlert()
  const [error, setError] = useState({
    description: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    if (key === 'description') {
      setDesc(val)
    }
  }

  const validate = () => {
    //let input = email;
    let errors = {};
    let isValid = true;

    if (desc === "") {
      isValid = false;
      errors["description"] = "Enter a description";
    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }

  const handleContinue = async (e) => {
    e.preventDefault();

    if (validate()) {
      submitDesc()
    }
  }

  const submitDesc = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      description: desc
    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {

          alert.success('Updated Successfully')
          props.handle()
        } else {
          setErrorMsg(res.data.message);
          setErrorSubmit(true);


        }

      })
      .catch(err => {
        setErrorMsg("error");

        setErrorSubmit(true);

      })

  }

  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        //  backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 class="updated-message" style={{ color: "red" }}>Error in Data Submission. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }



  return (
    <div>
      <div className="item"><textarea className="text-area" cols="100" rows="4" maxLength="350" style={{ height: "100px" }} name="description" value={desc} onChange={handleChange} />

        {error.description ? <div className="error-validation-msg"> {error.description}</div> : null}

      </div>
      <div className="form-button">
        <button onClick={handleContinue} className="btn btn-primary btn-col" >Update Description</button>
      </div>

      {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
          }}

        /> : ''
      }


    </div>
  )
}

export default DescriptionUpdate

import React, { useState } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import './hospprofile.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Modal } from 'react-bootstrap';
import "./popup.css";
import { useAlert } from 'react-alert';

function MobileUpdate(props) {
  let number = props.ph;
  if (number && !number.startsWith('91')) {
    number = '91' + number;
  }
  const [phone, setPhone] = useState({
    dialcode: "",
    rawphone: "",
    mobile_number: number,
  });
  const [rawPhone, setRawPhone] = useState("");
  const [error, setError] = useState({ phone: "" });
  const alert = useAlert()

  const handlePhoneInput = (value, data) => {

    const rawPhone = value.slice(data.dialCode.length);
    setRawPhone(rawPhone);
    const dialcode = value.slice(0, data.dialCode.length);
    let number = value;
    if (number && !number.startsWith('91')) {
      number = '91' + number;
    }
    setPhone(currentstate => ({
      ...currentstate,
      dialcode: dialcode,
      rawphone: rawPhone,
      mobile_number: number

    }));
  }


  let flag = true

  const handleCheckExistingPhone = async () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const rawPhone = phone.rawphone;

    const dialcode = phone.dialcode;
    setError(currentstate => ({
      ...currentstate,
      phone: ''
    }));


    const data = {
      code: dialcode,
      mobile_number: rawPhone
    }

    await axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
      headers: {
        "Authorization": 'Token ' + str,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          if (res.data.is_existing_user === true) {
            flag = false

            setError(currentstate => ({
              ...currentstate,
              phone: "This phone number is already registered!"
            }));

          }
          else {
            flag = true

          }
        }
        else {
          flag = true

        }

      })
      .catch(err => {


      })

  }

  const validate = async () => {
    //let input = email;
    let errors = {};
    let isValid = true;
    await handleCheckExistingPhone()

    if (flag === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (!phone.dialcode || phone.dialcode === "" || !phone.rawphone || phone.rawphone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }
    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Enter valid phone number  ";
    }
    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }


  const handleContinue = async (e) => {
    e.preventDefault();

    if (await validate()) {

      submitPhone()
      //handleSendOTP();
    }
  }


  const submitPhone = () => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      code: phone.dialcode,
      mobile_number: phone.dialcode + phone.rawphone
    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          alert.success('Updated successfully')
          props.handle()
        } else {
         alert.error(res.data.message || 'Error occured')
        }

      })
      .catch(err => {
        alert.error('Error occured, Try again!')
      })

  }


  return (<>
    <div className='phone-edit flex-phone'>
      <div className="form-control phone-input item item-left-row">
        <PhoneInput
          inputProps={{

          }}
          country={"in"}
          onlyCountries={['in']}
          countryCodeEditable={false}
          value={phone.mobile_number}
          onChange={handlePhoneInput}
          onBlur={handleCheckExistingPhone}

        />
        {error.phone ? <div className="error-validation-msg"> {error.phone}</div> : null}

      </div>
      <div className="form-button">
        <button style={{ marginLeft: '10px' }} onClick={handleContinue} className="btn btn-primary btn-col" >Update Phone</button>
      </div>
    </div>
  </>
  )
}

export default MobileUpdate

import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import "./login.css";
import "./log.css";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import { LoginContext } from "../contexts/LoginContext";
import { APIURL } from "../../Global";
import axios from "axios";
import background from "../../assets/images/bg.jpg";
import { HospLoginContext } from "../contexts/HospitalLoginContext";
import AuthUtil from "./AuthUtil";
import home from "../../assets/images/newww.png";
import log0 from "../../assets/images/Peace-Village-Logo.png";
import LoadingOverlay from "react-loading-overlay";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import { useAlert } from "react-alert";

function LoginPage() {
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [, setHospData] = useContext(HospLoginContext);
  const location = useLocation();
  const [, setLoginDatas] = useContext(LoginContext);
  const [, setIsLoggedIn] = useContext(LoginStatusContext);
  const [error, setError] = useState(false);
  const [serveCount, setServeCount] = useState("");
  const [aggregator, setAggregator] = useState('');
  const [orgImg, setOrgImg] = useState("");
  const [orgFlag, setOrgFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regcount, setRegCount] = useState('');
  const [isUser, setIsUser] = useState(false);
  const inputRefs = useRef([]);
  const loginButtonRef = useRef(null);
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  const alert = useAlert()
  const navigate = useNavigate();

  useEffect(() => {
    if (showConfirmation) {
      closeConfirmationPopup();
    }
  }, [showConfirmation, closeConfirmationPopup]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (index === 1) {
        loginButtonRef.current.click();
      } else if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!inputRefs.current.includes(document.activeElement) && document.activeElement !== loginButtonRef.current) {
        loginButtonRef.current.click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    setLoginDatas(null);
    setIsLoggedIn(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get(`${APIURL}/api/v1/account/customer-info/`)
      .then(res => {
        if (res.data.status === "success") {
          const { 'service provider count': spCount, project_type, aggregation_type, organization_logo, registered_sp_count, features } = res.data;
          setServeCount(Number(spCount));
          sessionStorage.setItem("project_type", project_type);
          setLoading(false);
          setAggregator(aggregation_type);
          setOrgImg(organization_logo);
          if (organization_logo !== `${APIURL}/media/null` && organization_logo) {
            setOrgFlag(true);
          }
          setRegCount(registered_sp_count);
          if (sessionStorage.getItem("project_type")) {
            sessionStorage.setItem("features", JSON.stringify(features.map(f => f.feature_name)));
          }
        } else {
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await apiCall();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "loginUsername") setLoginUsername(value);
    if (name === "loginPassword") setLoginPassword(value);
  };

  const togglePasswordVisiblity = () => {
    setPasswordType(prevType => (prevType === "password" ? "text" : "password"));
  };

  const apiCall = () => {
    axios.post(`${APIURL}/api/v1/account/login/`, {
      username: loginUsername,
      password: loginPassword,
    }, {
      headers: { "Content-Type": "application/json" },
    })
      .then(res => {
        if (res.data.status === "success") {
          const { user_type, user_id, verification_status, hospital_id, role, token } = res.data;
          if (verification_status === "pending") {
            sessionStorage.setItem("hosp_data", JSON.stringify(res.data));
            sessionStorage.setItem("hospital_pending", "true");
            setHospData(res.data);
          }
          if (user_type === "config_admin") {
            handleSuccessfulLogin(res.data, user_id);
          } else {
            checkWebUserLogin(user_type, res.data);
          }
        } else {
          alert.error(res.data.message);
          resetLoginForm();
        }
      })
      .catch(() => {
        setError("There was some error in login");
        resetLoginForm();
      });
  };

  const handleSuccessfulLogin = (data) => {
    setIsUser(true);
    sessionStorage.setItem("logindatas", JSON.stringify(data));
    sessionStorage.setItem("userid", data.user_id);
    sessionStorage.setItem("hospId", data.hospital_id);
    sessionStorage.setItem("role", data.role);
    sessionStorage.setItem("aggr_type", aggregator);
    sessionStorage.setItem("usertoken", JSON.stringify(data.token));
    sessionStorage.setItem("usertype", data.user_type);
    setLoginDatas(data);
    if (data.role === 'bo_superadmin') {
      navigateToDashboard()
    } else {
      userAccess(data.user_id);
    }
  };


  const userAccess = (user_id) => {
    const tokenString = sessionStorage.getItem("usertoken");
    const token = tokenString.replace(/["]+/g, "");

    axios.get(`${APIURL}/api/v1/service-provider/user-access/?existing_access=true&user_id=${user_id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + token
      },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          const datas = res.data.existing_access_list;
          sessionStorage.setItem("access", JSON.stringify(datas));
          navigateToDashboard(); // Navigate only after access is set
        } else if (res.data.status === 'error' && res.data.message === 'Access Denied') {
          sessionStorage.setItem("access", JSON.stringify([]));
          navigateToDashboard()
        } else {
          setError('Error occurred');
        }
      })
      .catch((err) => {
        console.log(err);
        setError('Error occurred');
      });
  };

  const navigateToDashboard = () => {
    const authUrl = sessionStorage.getItem("usertoken") && AuthUtil.validateAuth();
    if (authUrl) {
      navigate(authUrl, { state: { from: location } });
    }
  };

  const checkWebUserLogin = (user, data) => {
    axios.get(`${APIURL}/api/v1/account/customer-info/`)
      .then(respond => {
        if (respond.data.status === "success") {
          sessionStorage.setItem("project_type", respond.data.project_type);
          const filtered = respond.data.features.filter((item) => {
            return item.feature_name === "web_user_login";
          });
          sessionStorage.setItem("web_users", JSON.stringify(filtered[0].description));
          sessionStorage.setItem("app_users", JSON.stringify(filtered[0].description));
          if (filtered[0].description[user] === "True") {
            handleSuccessfulLogin(data, data.user_id);
          } else {
            setError("Invalid User");
            resetLoginForm();
          }
        }
      });
  };

  const resetLoginForm = () => {
    setLoginUsername("");
    setLoginPassword("");
    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay active={loading}>
        <div className="App">
          <div className="appAside" style={{padding:"0px 20px"}}>
            <article className="card1">
              <img src={orgFlag ? orgImg : home} className="card__img" />
              <div className="card__data">
                <div style={{ height: "86%", paddingTop: "2%" }}></div>
                <button>
                  <a href="https://peacevillage.in/" className="card__btn">Learn more</a>
                </button>
              </div>
            </article>
          </div>
          <div className="appForm">
            <div className="formData" style={{padding:"0px 20px"}}>
              <div className="formdata1">
                <h2 className="name">Login</h2>
              </div>
              <div className="formFields">
                <div className="formField">
                  <input
                    ref={el => inputRefs.current[0] = el}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                    type="text"
                    className="formFieldInput"
                    name="loginUsername"
                    placeholder="Username/E-mail Address"
                    value={loginUsername}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="formField">
                  <div style={{ backgroundColor: "#e8f0fe", position: 'relative', borderRadius: 20 }}>
                    <input
                      ref={el => inputRefs.current[1] = el}
                      onKeyDown={(e) => handleKeyDown(e, 1)}
                      style={{ backgroundColor: "#e8f0fe" }}
                      type={passwordType}
                      className="formFieldInput"
                      value={loginPassword}
                      name="loginPassword"
                      placeholder="Password"
                      onChange={handleChange}
                      required
                    />
                    <span style={{ float: "right", top: 10, right: 10, position: 'absolute', color: 'black' }}>
                      <i className={`fa ${passwordType === "password" ? "fa-eye-slash" : "fa-eye"}`} onClick={togglePasswordVisiblity}></i>
                    </span>
                  </div>
                </div>
                <div className="forgot-password">
                  <Link style={{color:"white"}}to="/forgot-password">Forgot password?</Link>
                </div>
                <div className="formField ">
                  <button ref={loginButtonRef} className="formFieldButton" onClick={handleLogin}>
                    Login
                  </button>
                  { regcount<serveCount &&<div className="formFieldButton">
                    <Link style={{color:"white"}} to="/signup">Create an account</Link>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
      {/* <div className="footerSection">
        <div className="footerText">
          <p style={{ textAlign: "center", color: "white" }}>Supported browsers: Chrome, Firefox, Safari, Edge</p>
        </div>
      </div> */}
    </>
  );
}

export default LoginPage;

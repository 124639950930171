import React, { useState, useEffect, useRef } from 'react'
//import {useHistory} from 'react-router-dom';
import { RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import axios from "axios";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../../Global";
import { useAlert } from 'react-alert'
import VerifyOTPPatient from "../../BDO/VerifyOTPPatient";
import '../../BDO/hospregisterpatient.css'
import 'react-table-v6/react-table.css'

function NewRegistartion({ close, from }) {
    let navigate = useNavigate();
    const [userDatas, setUserDatas] = useState({
        firstname: "",
        lastname: '',
        email: "",
        consent: "",
        gender: "",
        dob: "",
        country: "India",
        state: "",
        phone: {
            dialCode: "91",
            rawphone: "",
            mobile_number: "",
        },
        identitycard: { value: '', label: '' },
        pincode: "",
        identitynumber: "",
        province: "",
        city: "",
        location: ""
    });
    const [isValidIdentity, setIsValidIdentity] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const alert = useAlert()
    const [errorMsg, setErrorMsg] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [verifyOTP, setVerifyOTP] = useState(false)
    const [otpval, setotpval] = useState("")
    const [signmethod, setSignMethod] = useState("")
    const [modalShow, setModalShow] = useState(false)
    const [loginEnable, setLoginEnabled] = useState(false)
    const [consentlog, setConsent] = useState(false)
    const [dob, setDob] = useState();
    const [, setFormattedDob] = useState("");
    const inputRefs = useRef([])
    const submitButtonRef = useRef(null)
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas"))
        : null

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const identityoptions = [
        { value: '', label: 'Select type' },
        { value: 'pan', label: 'PAN' },
        { value: 'passport', label: 'PASSPORT' },
        { value: 'aadhar', label: 'AADHAR' },
        { value: 'driving_license', label: 'DRIVING LICENCE' },
        { value: 'ration_card', label: 'RATION CARD' },
        { value: 'voter_id', label: 'VOTER ID' },]

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault()
            if (index === 23) {
                inputRefs.current[24].click();
            } else if (index === 38) {
                submitButtonRef.current.click();
            } else {
                e.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
            submitButtonRef.current.click();
        } else if (e.altKey && e.key === 'c') {
            navigate("../");
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    });

    const [error, setError] = useState({
        username: "",

        phone: "",

    });

    const handleChange = e => {

        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: val
        }));
        setError((currentstate) => ({
            ...currentstate,
            [key]: "",
        }));



    }

    useEffect(() => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */


        axios
            .get(`${APIURL}/api/v1/service-provider/hospital-services/?type=misc`, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.status === 200) {


                    let dataList = (res.data.filter(item => {


                        return item.service_name === 'Registration' || item.service_name === 'registration'

                    }))


                }
                else {

                }
            }).catch((error) => {

            })

    }, [])

    const onSuccess = (data) => {

        setotpval(data.otp)
        setSignMethod(data)

        setVerifyOTP(false);
        setModalShow(true);
        setLoginEnabled(true);
        setConsent(true);
    }
    const onCancel = () => {

        setVerifyOTP(false);
        setUserDatas(currentstate => ({
            ...currentstate,
            consent: ""

        }))

    };
    const SubmitPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Verified Successfully!!</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary btn-col" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const handleChangeIdnum = (e) => {
        setIsValidIdentity(true)
        setError((currentstate) => ({
            ...currentstate,
            identitynumber: "",
        }));

        const x = e.target.value
        const val = x.trim()
        const key = e.target.name;

        setUserDatas((currentstate) => ({
            ...currentstate,
            [key]: val,
        }));

        if (val === '') {
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: "Enter Identity number",
            }));
        }

        if (userDatas.identitycard !== null && userDatas.identitynumber !== "" && userDatas.identitycard === userDatas.identitycard.value && userDatas.identitynumber === val) {
            setIsValidIdentity(true)
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: "",
            }));

        } else {
            const data = {
                idcard_no: val,
                idcard_type: userDatas.identitycard.value,
            };
            axios
                .post(`${APIURL}/api/v1/account/check-id/ `, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === "error") {
                            setError((currentstate) => ({
                                ...currentstate,
                                identitynumber: `This is already registered!`,
                            }));
                            setIsValidIdentity(false);
                        } else {
                            setIsValidIdentity(true);
                        }
                    } else {
                        setIsValidIdentity(true);
                    }
                })
                .catch((err) => { });
        }
    }

    const handleDOB = (date) => {

        setDob(date);

        let dateUpdated = date;
        if (date !== null) {
            const offset = date.getTimezoneOffset();

            dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))

            let DateExtracted = dateUpdated.toISOString().split('T')[0];

            /*let dateString= DateExtracted.toString(); */
            setFormattedDob(DateExtracted);

            setUserDatas(currentstate => ({
                ...currentstate,
                dob: DateExtracted
            }));
        }
        setError((currentstate) => ({
            ...currentstate,
            dob: "",
        }));
    }

    const selectRegion = (val) => {
        // let value1=e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
            state: val
        }));
        setError((currentstate) => ({
            ...currentstate,
            state: "",
        }));
    }

    const handleGenderRadioButton = (e) => {
        let genderOpted = e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
            gender: genderOpted

        }))
        setError((currentstate) => ({
            ...currentstate,
            ["gender"]: "",
        }));
    }

    function OtpPopUp(props) {
        return (

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    <VerifyOTPPatient onSuccess={onSuccess} onCancel={onCancel} email={userDatas.email} phone={userDatas.phone} />
                </Modal.Body>

            </Modal>
        );
    }

    const handlePhoneInput = (value, data, event, formattedValue) => {


        const rawPhone = value.slice(data.dialCode.length);

        const dialcode = value.slice(0, data.dialCode.length);
        setUserDatas(currentstate => ({
            ...currentstate,
            phone: {
                dialcode: "91",
                rawphone: rawPhone,
                mobile_number: value
            }

        }));

        setError((currentstate) => ({
            ...currentstate,
            "phone": "",
        }));


    }

    const handleCheckExistingPhone = (e) => {
        setIsValidPhone(true);
        if (e.target.value !== "") {

            const rawPhone = userDatas.phone.rawphone;

            const dialcode = userDatas.phone.dialcode;
            setError(currentstate => ({
                ...currentstate,
                phone: ''
            }));



            const data = {
                code: dialcode,
                mobile_number: rawPhone
            }

            axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {

                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {

                            setError(currentstate => ({
                                ...currentstate,
                                phone: "Phone Number already registered!"
                            }));
                            setIsValidPhone(false);
                        }
                        else {
                            setIsValidPhone(true);

                        }


                    }
                    else {

                        setIsValidPhone(true);

                    }

                })
                .catch(err => {


                })

        }


    }




    const handleCheckExisting = e => {

        setIsValidEmail(true);
        if (e.target.value !== "") {
            setError(currentstate => ({
                ...currentstate,
                email: ''
            }));
            e.preventDefault();
            let key = e.target.name;


            const data = {
                email: e.target.value
            }

            axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {

                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {

                            setError(currentstate => ({
                                ...currentstate,
                                email: `This ${key} is already registered!`
                            }));
                            setIsValidEmail(false);
                        }
                        else {
                            setIsValidEmail(true);
                        }


                    }
                    else {

                        setIsValidEmail(true);

                    }

                })
                .catch(err => {


                })
        }

    }

    const IdentityChange = (data) => {

        setUserDatas(current => ({
            ...current, identitycard: data,
            identitynumber: ''
        }))

        setError((currentstate) => ({
            ...currentstate,
            identitynumber: '',
        }))

        if (data.value !== '') {
            if (!userDatas.identitynumber || userDatas.identitynumber === '' || userDatas.identitynumber === null) {
                setError((currentstate) => ({
                    ...currentstate,
                    identitynumber: 'Enter Identity Number',
                }))
            }
        } else if (data.value === '') {
            setUserDatas(current => ({
                ...current, identitynumber: ''
            }))
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: '',
            }))
        }
    }






    const validate = () => {
        let input = userDatas;
        let errors = {};
        let isValid = true;
        setError({});


        if (!input["firstname"] || input["firstname"] === "") {
            isValid = false;
            errors["firstname"] = "Enter first name";
        }
        if (!input["pincode"] || input["pincode"] === "") {
            isValid = false;
            errors["pincode"] = "Enter pincode";
        }
        if (!input["lastname"] || input["lastname"] === "") {
            isValid = false;
            errors["lastname"] = "Enter last name";
        }
        if (!input["state"] || input["state"] === "") {
            isValid = false;
            errors["state"] = "Select a region";
        }
        if (isValidPhone === false) {
            isValid = false;
            errors["phone"] = "Phone number already registered!";
        }
        if (input["email"] !== "" && isValidEmail === false) {
            isValid = false;
            errors["email"] = "Email already registered!";
        }
        if (input["email"] !== "") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Enter a valid email address.";
            }
        }
        if (typeof input["firstname"] !== "undefined") {

            var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
            if (pattern2.test(input["firstname"])) {
                isValid = false;
                errors["firstname"] = "Special Characters are not allowed";
            }
        }
        if (typeof input["lastname"] !== "undefined") {

            var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
            if (pattern3.test(input["lastname"])) {
                isValid = false;
                errors["lastname"] = "Special Characters are not allowed";
            }
        }
        if (!userDatas.phone.dialcode || userDatas.phone.dialcode === "" || !userDatas.phone.rawphone || userDatas.phone.rawphone === "") {
            isValid = false;
            errors["phone"] = "Enter phone number";
        }
        if (userDatas.phone.rawphone.length > 0 && userDatas.phone.rawphone.length < 10) {
            isValid = false;
            errors["phone"] = "Check phone number";
        }
        if (typeof dob == "undefined" || dob === "" || dob === null) {
            isValid = false;
            errors["dob"] = "Enter your date of birth";
        }
        if (typeof userDatas.gender == "undefined" || userDatas.gender === "" || userDatas.gender === null) {
            isValid = false;
            errors["gender"] = "Select gender";
        }
        setError(currentstate => ({
            ...currentstate,
            ...errors
        }));

        return isValid;
    }


    const handleContinue = async (e) => {


        e.preventDefault();



        if (validate() && isValidPhone && isValidEmail && isValidIdentity) {

            handleRegister();
        }
        //  else{
        //   setErrorMsg("please cehck email already registered or not")
        //   setErrorShow(true)
        //  }
    }

    const handleRegister = () => {

        if (userDatas.identitynumber !== "" && !isValidIdentity) {

            setErrorMsg("Check the identity number")
            setErrorShow(true)
        }
        else if (userDatas.state === "") {

            alert.error("Select the region")
        }
        else if (userDatas.identitycard.value !== "" && userDatas.identitynumber === "") {
            alert.error("Enter identity card number")
        }
        else {


            const tokenString = sessionStorage.getItem('usertoken');

            let str = tokenString.replace(/["]+/g, '');

            let user = {

                first_name: userDatas.firstname,
                last_name: userDatas.lastname,
                signup_method: signmethod.mobile_number ? "mobile" : signmethod.email ? "email" : "mobile",
                // email:userDatas.email,
                // phone : userDatas.phone.rawphone,
                gender: userDatas.gender,
                //country_code:userDatas.phone.dialcode,
                dob: userDatas.dob,
                province: userDatas.province ? userDatas.province : "-",
                city: userDatas.city ? userDatas.city : "-",
                country: "India",
                location: userDatas.location ? userDatas.location : "-",
                state: userDatas.state,
                pincode: userDatas.pincode,
                code: userDatas.phone.dialcode,
                mobile_number: userDatas.phone.rawphone,
                idcard_type: userDatas.identitycard.value ? userDatas.identitycard.value : "-",
                idcard_no: userDatas.identitynumber ? userDatas.identitynumber : "-",
                src: "web",
                login_enabled: loginEnable !== true ? "False" : true,
                login_consent: consentlog !== true ? "False" : true,
                hospital_id: login_datas.hospital_id,
                otp: otpval !== "" ? otpval : "1234",
                latitude: "10.12345",
                longitude: "10.12345"


            }

            if (userDatas.email) {
                user = { ...user, email: userDatas.email, }
            }



            axios.post(`${APIURL}/api/v1/account/signup/`, user, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + str
                }
            })
                .then(res => {


                    if (res.data.status === "success") {
                        alert.success(res.data.message)
                        if (from === 'csc') {
                            close()
                        } else {
                            window.location.reload();
                        }
                    }
                    else {
                        alert.error(res.data.message ? res.data.message : 'Error occured.Try again')
                    }

                })
                .catch(err => {
                    alert.error("Error in registration")

                })
        }

    }

    const ErrorPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <h5 className="text-danger">{errorMsg !== '' ? errorMsg : <h5 className="text-danger">Error in Data Submission. Please try again!</h5>}</h5>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={props.onHide}>Ok</Button>

                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div style={{ padding: from === 'csc' ? 0 : "20px 40px" }}>
            <div className="new-registration">
                <h4 className='title-of-tasks' style={{ margin: 0 }}>Patient Details</h4>
                <br />
                <div className='row'>
                    <div className='col-4 input-container'>
                        <label className={error.firstname ? 'error-label' : ''}>
                            {error.firstname || <>First Name<span> *</span></>}
                        </label>
                        <input
                            className={`input1 ${error.firstname ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[1] = el}
                            onKeyDown={(e) => handleKeyDown(e, 1)}
                            type="text"
                            maxLength="30"
                            name="firstname"
                            value={userDatas.firstname}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='col-4 input-container'>
                        <label className={error.lastname ? 'error-label' : ''}>
                            {error.lastname || <>Last Name<span> *</span></>}
                        </label>
                        <input
                            className={`input1 ${error.lastname ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[2] = el}
                            onKeyDown={(e) => handleKeyDown(e, 2)}
                            type="text"
                            maxLength="50"
                            value={userDatas.lastname}
                            name="lastname"
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.gender ? 'error-label' : ''}>
                            {error.gender || <>Gender<span> *</span></>}
                        </label>
                        <div className={`input1 ${error.gender ? 'error-border' : ''}`}>
                            <div className="col-4">
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={userDatas.gender === "male"}
                                    onClick={handleGenderRadioButton}
                                />
                                <label htmlFor="male">Male</label>
                            </div>
                            <div className="col-4">
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={userDatas.gender === "female"}
                                    onClick={handleGenderRadioButton}
                                />
                                <label htmlFor="female">Female</label>
                            </div>
                            <div className="col-4">
                                <input
                                    type="radio"
                                    id="others"
                                    name="gender"
                                    value="others"
                                    checked={userDatas.gender === "others"}
                                    onClick={handleGenderRadioButton}
                                />
                                <label htmlFor="others">Others</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='input-container col-4'>
                        <label className={error.phone ? 'error-label' : ''}>
                            {error.phone || <>Contact no<span> *</span></>}
                        </label>
                        <PhoneInput
                            style={{ border: error.phone ? '1px solid red' : 'none', borderRadius: '5px' }}
                            inputProps={{
                                ref: el => inputRefs.current[3] = el,
                                tabIndex: 0,
                                onKeyDown: e => handleKeyDown(e, 3),
                            }}
                            value={userDatas.phone.mobile_number}
                            country={"in"}
                            onlyCountries={['in']}
                            countryCodeEditable={false}
                            onChange={handlePhoneInput}
                            onBlur={handleCheckExistingPhone}
                        />
                    </div>
                    <div className='col-4 input-container'>
                        <label className={error.email ? 'error-label' : ''}>
                            {error.email || <>Email</>}
                        </label>
                        <input
                            className={`input1 ${error.email ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[4] = el}
                            onKeyDown={(e) => handleKeyDown(e, 4)}
                            type="email"
                            maxLength="50"
                            value={userDatas.email}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleCheckExisting}
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.dob ? 'error-label' : ''}>
                            {error.dob || <>Date of Birth<span> *</span></>}
                        </label>
                        <div className={`input1 ${error.dob ? 'error-border' : ''}`}>
                            <DatePicker
                                onChange={date => { handleDOB(date) }}
                                maxDate={new Date()}
                                value={dob}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='input-container col-4'>
                        <label>City</label>
                        <input
                            className='input1'
                            ref={el => inputRefs.current[5] = el}
                            onKeyDown={(e) => handleKeyDown(e, 5)}
                            type="text"
                            maxLength="50"
                            value={userDatas.city}
                            name="city"
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label>Province</label>
                        <input
                            className='input1'
                            ref={el => inputRefs.current[6] = el}
                            onKeyDown={(e) => handleKeyDown(e, 6)}
                            type="text"
                            maxLength="50"
                            value={userDatas.province}
                            name="province"
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.location ? 'error-label' : ''}>
                            {error.location || <>Location</>}
                        </label>
                        <input
                            className={`input1 ${error.location ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[7] = el}
                            onKeyDown={(e) => handleKeyDown(e, 7)}
                            type="text"
                            maxLength="50"
                            name="location"
                            value={userDatas.location}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='input-container col-4'>
                        <label>Country</label>
                        <input
                            className='input1'
                            value={"India"}
                            disabled type="text"
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.state ? 'error-label' : ''}>
                            {error.state || <>Region<span> *</span></>}
                        </label>
                        <RegionDropdown
                            className={`input1 ${error.state ? 'error-border' : ''}`}
                            country={"India"}
                            value={userDatas.state}
                            onChange={(val) => selectRegion(val)} />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.pincode ? 'error-label' : ''}>
                            {error.pincode || <>Pincode<span> *</span></>}
                        </label>
                        <input className={`input1 ${error.pincode ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[8] = el}
                            onKeyDown={(e) => handleKeyDown(e, 8)}
                            type="text"
                            maxLength="50"
                            value={userDatas.pincode}
                            name="pincode"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='input-container col-4'>
                        <label>ID card type</label>
                        <Select
                            ref={el => inputRefs.current[9] = el}
                            onKeyDown={(e) => handleKeyDown(e, 9)}
                            value={userDatas.identitycard.value !== "" ? userDatas.identitycard : "Select type"}
                            onChange={(data, e) => { IdentityChange(data, e) }}
                            options={identityoptions}
                            name='identitycard'
                            style={{ width: "100%", border: "none" }}
                        />
                    </div>
                    <div className='input-container col-4'>
                        <label className={error.identitynumber ? 'error-label' : ''}>
                            {error.identitynumber || <>ID Card Number</>}
                        </label>
                        <input
                            className={`input1 ${error.identitynumber ? 'error-border' : ''}`}
                            ref={el => inputRefs.current[10] = el}
                            onKeyDown={(e) => handleKeyDown(e, 10)}
                            type="text"
                            maxLength="20"
                            value={userDatas.identitynumber}
                            name="identitynumber"
                            placeholder="Space not allowed"
                            disabled={userDatas.identitycard.value === ''}
                            onChange={handleChangeIdnum}
                        />
                    </div>
                </div>
                <br />
                <div className="form-button">
                    <button
                        ref={submitButtonRef}
                        onClick={handleContinue}
                        className="btn btn-primary btn-col"
                    >Register</button>
                </div>
            </div>
            {
                verifyOTP ? (
                    <OtpPopUp
                        show={verifyOTP}
                        onHide={() => {
                            setVerifyOTP(false);
                        }}
                    />
                ) : null
            }
            {
                errorShow ?
                    <ErrorPopup
                        show={errorShow}
                        onHide={() => setErrorShow(false)} /> : null
            }

            {
                modalShow ? (
                    <SubmitPopUp
                        show={modalShow}
                        onHide={() => {
                            setModalShow(false);
                            // data.modalClose();

                            // setSubmitMsg("");
                        }}
                    />
                ) : (
                    ""
                )
            }

        </div >
    )
}

export default NewRegistartion


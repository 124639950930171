import React, { useImperativeHandle, useState, useEffect, useRef, forwardRef } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
import moment from "moment";
import ImageUpdate from '../ImageUpdate';
import EditRemark from '../EditRemark';
import ZoomableImage from '../../BDO/Admission/ZoomableImage';
import AddRemark from '../AddRemark';
const AddProcedure = forwardRef((props, ref) => {
    const alert = useAlert();
    const wrapperRef = useRef(null);
    const [prevProcedureList, setPrevProcedureList] = useState([])
    const [load, setLoad] = useState(false);
    const [assignList, setAssignList] = useState([]);
    const [index, setIndex] = useState();
    const [searchAssign, setSearchAssign] = useState("");
    const [procedureToggle, setProcedureToggle] = useState(false);
    const [procedureListCounter, setProcedureListCounter] = useState(0);
    const [procedureRefresh, setProcedureRefresh] = useState(false);
    const [deleteProcedureConfirmMsg, setDeleteProcedureConfirmMsg] = useState("");
    const [currentIndex, setCurrentIndex] = useState("");
    const [display, setDisplay] = useState(false);
    const [imageEditShow, setImageEditShow] = useState(false);
    const [imageViewShow, setImageViewShow] = useState(false);
    const [deleteProcedureConfirmModalShow, setDeleteProcedureConfirmModalShow] = useState(false);
    const [searchProcedure, setSearchProcedure] = useState("");
    const [procedureIndexToDelete, setProcedureIndexToDelete] = useState();
    const [procedureSuggest, setProcedureSuggest] = useState([]);
    const [isAreaChane, setIsAreaChange] = useState(false);
    const [reasonAddShow, setReasonAddShow] = useState(false);
    const [procedureReasonShow, setProcedureReasonShow] = useState(false);
    const [render, setRender] = useState(false);
    const [cell, setCell] = useState("");
    const [, setDateVal] = useState("");
    const [remarkViewShow, setRemarkViewShow] = useState(false);
    const [detailData, setDetailData] = useState("");
    const [editSuccess, setEditSuccess] = useState(false);
    const [tableRefresh, setTableRefresh] = useState(false);
    const [doctorId, setDoctorId] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [n1, setn1] = useState(3)
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas"))
        : null;
    const [procedureList, setProcedureList] = useState([
        {
            procedure_details: {
                procedure_name: "",
                procedure_id: "",
                procedure_type: "",
                amount: "",
            },
            date: "",
            area: "",
            session: "",
            quantity: "",
            details: "",
            procedure_reason: "",
            proc_image: "",
            assign_name: "",
            status: "",
        },
    ]);
    const delConfirmRef = useRef(null)
    useEffect(() => {
        const handleKey = (e) => {

            if (e.altKey && e.key === 's') {
                e.preventDefault()
                delConfirmRef.current.click()
            } else if (e.key === 'Enter') {
                e.preventDefault()
                delConfirmRef.current.click()
            } else if (e.altKey && e.key === 'c') {
                e.preventDefault()
                setDeleteProcedureConfirmModalShow(false);
            }

        }
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        }

    }, []);
    const deleteProcedureRow = (index) => {
        let newlist = [];
        if (index > procedureListCounter - 1) {
            newlist = procedureList.filter((test, i) => {
                return i !== index;
            });

            setProcedureList(newlist);
            if (newlist.length <= 0) {
                setProcedureList([
                    {
                        procedure_details: {
                            procedure_name: "",
                            procedure_id: "",
                            procedure_type: "",
                            amount: "",
                        },
                        date: "",
                        area: "",
                        session: "",
                        quantity: "",
                        details: "",
                        procedure_reason: "",
                        proc_image: "",
                        assign_name: "",
                        status: "",
                    },
                ]);
            }
        } else {
            handleDeleteRowProcedure(index);
        }
    };
    const handleView = (data) => {
        setDetailData(data);
        setRemarkViewShow(true);
    };
    useEffect(() => { if (procedureList.length > 2) { setn1(procedureList.length + 1) } }, [procedureList])
    const DeleteProcedureConfirmPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="modal-popout"
            >
                <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                    <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
                    <label style={{ margin: '20px auto', color: '#4e4e4e', fontSize:'18px' }}>Please confirm to delete scheduled procedure</label>
                    <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
                        <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
                        <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} ref={delConfirmRef}
                            onClick={() => {
                                setDeleteProcedureConfirmModalShow(false);
                                handleDeleteProcedure(props.index);
                                setProcedureIndexToDelete(null);
                            }}>
                            Confirm
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleDeleteProcedure = (index) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .delete(
                `${APIURL}/api/v1/doctor/procedure-list-details/${procedureList[index].id}`,
                {
                    headers: {
                        Authorization: "Token " + str,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("Deleted successfully")

                    setDoctorName(login_datas.doctor_name);
                    setSearchAssign("");


                    setProcedureRefresh(!procedureRefresh);
                } else {
                    alert.error("error")
                }
            })
            .catch((err) => {

            });
    };
    useEffect(() => {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            const tokenString = sessionStorage.getItem("usertoken");

            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(
                    `${APIURL}/api/v1/service-provider/procedure-assignee/?q=${searchAssign}`,
                    {
                        headers: {
                            Authorization: "Token " + str,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status === "success") {
                        setAssignList(res.data.details);
                        setDefaultDoctor();
                    }
                });
        }
    }, [searchAssign]);
    const setDefaultDoctor = () => {
        const hosp_doc_data = sessionStorage.getItem("hosp_doc_data");
        if (hosp_doc_data) {
            const { doctor_name, user_id } = JSON.parse(hosp_doc_data);
            setDoctorId(user_id);
            setDoctorName(doctor_name);
        }
    };
    const handleDeleteRowProcedure = (index) => {
        setDeleteProcedureConfirmMsg("success");
        setDeleteProcedureConfirmModalShow("true");
        setProcedureIndexToDelete(index);
    };
    const renderEditableProcedure = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureListCounter === 0
        ) {
            return (
                <div ref={wrapperRef}>
                    <input
                        style={{ backgroundColor: "#fafafa" }}
                        value={
                            procedureList[cellInfo.index][cellInfo.column.id].procedure_name
                        }
                        className="form-control"
                        name="procedure"
                        onChange={(e) => {
                            e.preventDefault();

                            setCurrentIndex(cellInfo.index);

                            setDisplay(true);
                            setSearchProcedure(e.target.value);
                            const procedures = procedureList;
                            procedures[cellInfo.index][cellInfo.column.id].procedure_name =
                                e.target.value;
                            setProcedureList(procedures);
                            if (e.target.value === "") {
                                setDisplay(false);
                            }
                        }}
                        autoComplete="off"
                    />
                    {currentIndex === cellInfo.index &&
                        cellInfo.column.id === "procedure_details" &&
                        display && (
                            <div className="suggestions-box">
                                {procedureSuggest.map((item, i) => {
                                    return (
                                        <div
                                            className="scan-item"
                                            key={item.id}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setRender(!render);
                                                const procedure = procedureList;
                                                procedure[cellInfo.index][
                                                    cellInfo.column.id
                                                ].procedure_id = item.id;
                                                procedure[cellInfo.index][
                                                    cellInfo.column.id
                                                ].procedure_name = item.procedure_name;
                                                procedure[cellInfo.index][
                                                    cellInfo.column.id
                                                ].procedure_type = item.procedure_type;
                                                procedure[cellInfo.index][cellInfo.column.id].amount =
                                                    item.amount;
                                                setProcedureList(procedure);
                                                setDisplay(false);
                                            }}
                                        >
                                            <div className="search-procedure">
                                                <p className="search-item">{item.procedure_name}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control test-name-col"
                        name="date"
                        value={
                            procedureList[cellInfo.index][cellInfo.column.id].procedure_name
                        }
                        onBlur={(e) => {
                            const procedure = procedureList;
                            procedureList[cellInfo.index][cellInfo.column.id].procedure_name =
                                e.target.innerHTML;
                            setProcedureList(procedure);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html:
                                procedureList[cellInfo.index][cellInfo.column.id]
                                    .procedure_name,
                        }}
                    />
                </div>
            );
        }
    };
    let sendData = null;

    function handleSend(childData) {
        sendData = childData;
    }
    const ReasonAddPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="remark-width"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <AddRemark update={handleSend} save={reasonAddHandle} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const reasonAddHandle = () => {
        if (procedureToggle) {
            setReasonAddShow(false);
            const procedure = procedureList;
            procedure[cell].procedure_reason = sendData;
            setProcedureList(procedure);
            setEditSuccess(true);
        }
    };

    const processPrevData = (scanData) => {
        return scanData.map(item => ({
            id: item.scan,
            date: item.date,
        }));
    };

    useEffect(() => {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            setProcedureList([
                {
                    procedure_details: {
                        procedure_name: "",
                        procedure_id: "",
                        procedure_type: "",
                        amount: "",
                    },
                    date: "",
                    area: "",
                    session: "",
                    quantity: "",
                    details: "",
                    procedure_reason: "",
                    proc_image: "",
                    assign_name: "",
                    status: "",
                },
            ]);

            setProcedureListCounter(0);
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(`${APIURL}/api/v1/doctor/procedure-list/?patient_id=${props.id}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        if (res.data.message.length > 0) {
                            const prevProcedure = processPrevData(res.data.message);
                            setPrevProcedureList(prevProcedure);
                        }
                    }
                });
        }
    }, [procedureRefresh]);
    const handleProcedureReasonEdit = (data, index) => {
        setDetailData(data);
        setCell(index);
        setProcedureReasonShow(true);
    };
    const renderProcedureArea = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            return (
                <div>
                    <input
                        type="text"
                        className="form-control"
                        value={procedureList[cellInfo.index].area}
                        placeholder="Enter Area"
                        onChange={(e) => {
                            setIsAreaChange(true);
                            e.preventDefault();
                            let data = e.target.value;

                            const procedure = procedureList;
                            procedure[cellInfo.index].area = data;
                            setTableRefresh(!tableRefresh);
                            setProcedureList(procedure);
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control test-name-col"
                        name="date"
                        value={procedureList[cellInfo.index].area}
                        onBlur={(e) => {
                            const procedure = procedureList;
                            procedureList[cellInfo.index].area = e.target.innerHTML;

                            setProcedureList(procedure);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html: procedureList[cellInfo.index].area,
                        }}
                    />
                </div>
            );
        }
    };

    const renderProcedureType = (cellInfo) => {
        if (
            procedureList[cellInfo.index][cellInfo.column.id].amount === "" &&
            procedureList[cellInfo.index][cellInfo.column.id].procedure_name === ""
        ) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    className="form-control test-name-col"
                ></div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control test-name-col"
                        name="date"
                        value={procedureList[cellInfo.index].session}
                        onBlur={(e) => {
                            const procedure = procedureList;
                            procedureList[cellInfo.index][cellInfo.column.id].procedure_type =
                                e.target.innerHTML;

                            setProcedureList(procedure);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html:
                                procedureList[cellInfo.index][cellInfo.column.id]
                                    .procedure_type,
                        }}
                    />
                </div>
            );
        }
    };
    const renderProcedureSession = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            return (
                <div>
                    <input
                        type="text"
                        className="form-control"
                        value={procedureList[cellInfo.index].session}
                        placeholder="Enter Session"
                        onChange={(e) => {
                            e.preventDefault();
                            let data = e.target.value;

                            const procedure = procedureList;
                            procedure[cellInfo.index].session = data;
                            setTableRefresh(!tableRefresh);
                            setProcedureList(procedure);
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control test-name-col"
                        name="date"
                        value={procedureList[cellInfo.index].session}
                        onBlur={(e) => {
                            const procedure = procedureList;
                            procedureList[cellInfo.index].session = e.target.innerHTML;

                            setProcedureList(procedure);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html: procedureList[cellInfo.index].session,
                        }}
                    />
                </div>
            );
        }
    };

    const renderProcedureDetails = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            let data = "";
            if (
                procedureList[cellInfo.index] &&
                procedureList[cellInfo.index].details
            ) {
                data = procedureList[cellInfo.index].details;
            }
            return (
                <div>
                    <textarea
                        className="text-area"
                        placeholder="Add Procedure Details"
                        cols="100"
                        rows="2"
                        defaultValue={data}
                        onChange={(e) => {
                            e.preventDefault();
                            data = e.target.value;

                            const procedure = procedureList;
                            procedure[cellInfo.index].details = data;

                            setProcedureList(procedure);
                        }}
                    />
                </div>
            );
        } else {
            return (
                <textarea
                    className="scan-text"
                    value={procedureList[cellInfo.index].details}
                    cols="100"
                    rows="2"
                />
            );
        }
    };
    const handleReasonAdd = (index, data) => {
        if (data === "toggle") {
            setProcedureToggle(true);
        } else {
            setProcedureToggle(false);
        }
        setCell(index);
        setReasonAddShow(true);
    };

    const renderProcedureReason = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            if (
                procedureList[cellInfo.index].procedure_reason === "" ||
                procedureList[cellInfo.index].procedure_reason === null
            ) {
                return (
                    <button
                        onClick={() => handleReasonAdd(cellInfo.index, "toggle")}
                        className="btn btn-smallest add-remark-btn"
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                );
            } else {
                return (
                    <button
                        onClick={() =>
                            handleProcedureReasonEdit(
                                procedureList[cellInfo.index].procedure_reason,
                                cellInfo.index
                            )
                        }
                        className="btn btn-primary btn-col btn-square btn-smallest"
                    >
                        View/Edit
                    </button>
                );
            }
        } else {
            if (
                procedureList[cellInfo.index].procedure_reason === "" ||
                procedureList[cellInfo.index].procedure_reason === null
            ) {
                return "";
            } else {
                return (
                    <button
                        onClick={() =>
                            handleView(procedureList[cellInfo.index].procedure_reason)
                        }
                        className="btn btn-primary btn-col btn-square btn-smallest"
                    >
                        View
                    </button>
                );
            }
        }
    };
    const RemarkViewPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="remark-width"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">{detailData}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    useEffect(() => {
        if (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof") {
            const tokenString = sessionStorage.getItem("usertoken");

            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(`${APIURL}/api/v1/doctor/procedures/?search=${searchProcedure}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setProcedureSuggest(res.data.message);
                    }
                });
        }
    }, [searchProcedure]);
    const editProcedureImage = (pos) => {
        setCell(pos);
        const fileInput = document.getElementById("procedureImage");
        fileInput.click();
    };

    const renderProcedureImage = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            if (
                procedureList[cellInfo.index].proc_image === null ||
                procedureList[cellInfo.index].proc_image === ""
            ) {
                return (
                    <div>
                        <input
                            id="procedureImage"
                            hidden="hidden"
                            type="file"
                            onChange={uploadProcedureImage}
                        />

                        <i
                            onClick={() => {
                                editProcedureImage(cellInfo.index);
                            }}
                            class="fas fa-image select-img"
                        ></i>
                    </div>
                );
            } else {
                return (
                    <button
                        onClick={() => {
                            setImageEditShow(true);
                            setIndex(cellInfo.index);
                        }}
                        className="btn btn-primary btn-col btn-square btn-smallest"
                    >
                        View/Edit
                    </button>
                );
            }
        } else {
            if (
                procedureList[cellInfo.index].proc_image === null ||
                procedureList[cellInfo.index].proc_image === ""
            ) {
                return "";
            } else {
                return (
                    <button
                        onClick={() => {
                            setImageViewShow(true);
                            setIndex(cellInfo.index);
                        }}
                        className="btn btn-primary btn-col btn-square btn-smallest"
                    >
                        View
                    </button>
                );
            }
        }
    };
    const handleUpdateProcedure = (i) => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        const fd = new FormData();

        fd.append(`procedure_id`, procedureList[i].procedure_details.procedure_id);
        fd.append(`date`, procedureList[i].date.split("T").join(" ").slice(0, 10));
        isAreaChane && fd.append(`area`, procedureList[i].area);
        fd.append(`session`, procedureList[i].session);
        fd.append(`quantity`, procedureList[i].quantity);
        fd.append(`details`, procedureList[i].details);
        fd.append(`procedure_reason`, procedureList[i].procedure_reason);

        if (procedureList[i].assign_id) {
            fd.append(`assign`, procedureList[i].assign_id);
        }

        if (procedureList[i].proc_image instanceof File) {
            fd.append(`proc_image`, procedureList[i].proc_image);
        }

        axios
            .put(
                `${APIURL}/api/v1/doctor/procedure-list-details/${procedureList[i].id}/`,
                fd,
                {
                    headers: {
                        Authorization: "Token " + str,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("successfully updated")
                    setProcedureRefresh(!procedureRefresh)
                } else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
                alert.error("Error in submission")
            });
    };
    const handleAssignee = () => {
        const data = procedureList;
        const sessionData = JSON.parse(sessionStorage.getItem("logindatas"));

        data.forEach((item) => {
            if (item.assign_id === "" || item.assign_name === "") {
                item.assign_id = sessionData.user_id;
                item.assign_name = sessionData.name;
            }
        });

        setProcedureList(data);
    };

    const handleSubmitProcedure = (index) => {
        let isValid = true;
        handleAssignee();

        const updatedProcedure = procedureList.filter((row) => {
            return row.procedure_details.procedure_name !== "";
        });

        const procedureData = procedureList.filter((row) => {
            return row.procedure_details.procedure_name !== "" || row.details !== "";
        });

        // Check for duplicate entries
        outerLoop: for (const item of procedureData) {
            for (const procedure of procedureList) {
                if (
                    procedure.id === item.id &&
                    procedure.procedure_details.procedure_name === item.procedure_details.procedure_name &&
                    procedure.area === item.area &&
                    procedure.session === item.session &&
                    moment(procedure.date).format("dd-MM-yyyy") === moment(item.date).format("dd-MM-yyyy")
                ) {
                    alert.error(`Procedure ${procedure.procedure_details.procedure_name} already exists!`);
                    isValid = false;
                    break outerLoop;  // Exit both loops on first duplicate found
                }
            }
        }

        // Validate mandatory fields
        if (isValid) {
            for (let i = 0; i < procedureList.length; i++) {
                const item = procedureList[i];

                if (item.procedure_details.procedure_name === "") {
                    alert.error('Procedure name is mandatory');
                    isValid = false;
                    break;
                }
                if (item.procedure_details.procedure_id === "") {
                    alert.error("*Select procedure name from the Dropdown!");
                    isValid = false;
                    break;
                }
                if (item.date === "") {
                    alert.error('Procedure date is mandatory');
                    isValid = false;
                    break;
                }
            }
        }
        
        console.log(isValid)
        // Update procedure if valid
        if (isValid) {
            props.onProcedureUpdate(updatedProcedure);
        }

        return isValid;
    };

    const renderEditableProcedureDate = (cellInfo) => {
        if (
            cellInfo.index > procedureListCounter - 1 ||
            procedureList[cellInfo.index].isEdit
        ) {
            const today = new Date();
            const offset = today.getTimezoneOffset();
            let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);
            let DateExtracted = dateUpdated.toISOString().split("T")[0];
            let showDate = "";
            if (
                procedureList[cellInfo.index] &&
                procedureList[cellInfo.index].date
            ) {
                showDate = procedureList[cellInfo.index].date;
            }
            return (
                <div>
                    <input
                        type="date"
                        data-date={showDate}
                        className="scan-date-col date-custom-format"
                        min={DateExtracted}

                        onChange={(e) => {
                            e.preventDefault();
                            let date = e.target.value;
                            let y = []
                            let x = procedureList.filter(x => {
                                return x.procedure_details.procedure_name === procedureList[cellInfo.index].procedure_name && x.procedure_details.area === procedureList[cellInfo.index].area && x.procedure_details.session === procedureList[cellInfo.index].session
                            })
                            console.log(x)
                            if (x.length > 0) {
                                y = x.filter(x => {
                                    return x.date === date
                                })
                                console.log(y)
                            }
                            if (y.length > 0) {
                                showDate = ""
                                return
                            }
                            else {
                                const procedure = procedureList;
                                procedure[cellInfo.index].date = date;

                                setProcedureList(procedure);


                                setTableRefresh(!tableRefresh);
                                const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

                                setDateVal(m);
                            }
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <div
                        style={{ backgroundColor: "#fafafa" }}
                        className="form-control test-date-col"
                        name="date"
                        value={procedureList[cellInfo.index].date}
                        onBlur={(e) => {
                            const procedure = procedureList;
                            procedure[cellInfo.index].date = e.target.innerHTML;

                            setProcedureList(procedure);
                        }}
                        autoComplete="off"
                        dangerouslySetInnerHTML={{
                            __html: procedureList[cellInfo.index].date,
                        }}
                    />
                </div>
            );
        }
    };
    const renderAddedByPro = (cellInfo) => {
        const createdByPro = procedureList[cellInfo.index].created_by;
        const hospitalNamePro = procedureList[cellInfo.index].hospital_name;

        const value1 = `${createdByPro}-${hospitalNamePro}`;

        return (
            procedureList[cellInfo.index].created_by && procedureList[cellInfo.index].hospital_name &&
            <input
                style={{ backgroundColor: "#fafafa", whiteSpace: "pre-line" }}
                className="form-control scan-name-col"
                readOnly
                value={value1}
                cols="100"
                rows="2"
            />
        );
    };

    const uploadProcedureImage = (e) => {

        const key = e.target.name;
        const file = e.target.files[0];
        const procedure = procedureList;


        if (!file) {
            alert.error("Please Select an Image")
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select valid image");
            return false;
        }
        if (file.size > 10e6) {
            alert.error("Select valid image")
            return false;
        }

        procedure[cell].proc_image = file;
        setProcedureList(procedure);
        setIndex(cell);
        setTableRefresh(!tableRefresh);


    };

    const AddRowProcedure = () => {
        const list = procedureList;
        list.push({
            procedure_details: {
                procedure_name: "",
                procedure_id: "",
                procedure_type: "",
                amount: "",
            },
            date: "",
            area: "",
            session: "",
            quantity: "",
            details: "",
            procedure_reason: "",
            proc_image: "",
            assign_name: "",
            status: "",
        });

        setProcedureList(list);

        setRender(!render);
    };
    const columnsProcedure = [
        {
            Header: <i className="fas fa-trash-alt"></i>,
            Cell: (props) => {
                return (
                    <i
                        style={{ marginLeft: "30%" }}
                        className="fas fa-trash-alt trash-btn"
                        onClick={() => {
                            deleteProcedureRow(props.index);
                        }}
                    ></i>
                );
            },
            width: 60,
            sortable: false,
            filterable: false,
        },

        {
            Header: (
                <span>
                    Procedure Name

                </span>
            ),
            accessor: "procedure_details",
            Cell: renderEditableProcedure,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            width: 200,
            minWidth: 100,
            sortable: false,
        },

        {
            Header: (
                <span>
                    Date

                </span>
            ),
            accessor: "date",
            Cell: renderEditableProcedureDate,
            sortable: false,
            width: 150,
        },
        {
            Header: "Area",
            accessor: "area",
            Cell: renderProcedureArea,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            width: 140,
            minWidth: 100,
            sortable: false,
        },
        {
            Header: "Type",
            accessor: "procedure_details",
            Cell: renderProcedureType,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            width: 140,
            minWidth: 100,
            sortable: false,
        },
        {
            Header: "Sessions",
            accessor: "session",
            Cell: renderProcedureSession,
            style: {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
            width: 140,
            minWidth: 100,
            sortable: false,
        },
        {
            Header: "Procedure Details",
            accessor: "details",
            Cell: renderProcedureDetails,
            sortable: false,
            filterable: false,
            width: 200,
        },
        {
            Header: "Reason",
            accessor: "procedure_reason",
            Cell: renderProcedureReason,
            sortable: false,
            filterable: false,
            width: 100,
        },

        {
            Header: <i class="fas fa-image"></i>,
            accessor: "proc_image",
            Cell: renderProcedureImage,
            sortable: false,
            filterable: false,
            width: 100,
        },
        {
            Header: "Add",
            Cell: (props) => {
                return props.index === procedureList.length - 1 ? (
                    <button
                        className="btn btn-primary btn-col btn-small"
                        onClick={AddRowProcedure}
                    >
                        +Add
                    </button>
                ) : null;
            },
            width: 80,
            sortable: false,
            filterable: false,
        }
    ];
    let imgFile = null;

    function handleFile(childData) {
        imgFile = childData;
    }

    const ProcedureImgPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImageUpdate
                        img={procedureList[index].proc_image}
                        imgUpdate={handleFile}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button className="btn btn-primary btn-col" onClick={props.onSave}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const ProcedureReasonPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="remark-width"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditRemark data={detailData} update={handleSend} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                    <Button className="btn btn-primary btn-col" onClick={props.onSave}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const ProcedureImgViewPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {procedureList &&
                        procedureList[index] &&
                        procedureList[index].proc_image ? (
                        <>


                            <p className="text-center" style={{ textAlign: "center" }}>
                                <ZoomableImage src={APIURL + procedureList[index].proc_image} />

                            </p>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    useImperativeHandle(ref, () => ({
        handleSubmitProcedure
    }));


    return (

        <div>
            <ReactTable
                columns={columnsProcedure}
                data={procedureList}
                pageSize={n1}

                showPagination={false}
                style={{ color: "black" }}
                resizable
                className="observation-table"
            ></ReactTable>
            {imageEditShow ? (
                <ProcedureImgPopup
                    show={imageEditShow}
                    onHide={() => {
                        setImageEditShow(false);
                    }}
                    onSave={() => {
                        if (imgFile !== null) {
                            setImageEditShow(false);
                            const procedure = procedureList;
                            procedure[index].proc_image = imgFile;
                            setProcedureList(procedure);
                            setEditSuccess(true);
                        } else {
                            alert.error("Please upload an Image");
                        }
                    }}
                />
            ) : null}
            {procedureReasonShow ? (
                <ProcedureReasonPopup
                    show={procedureReasonShow}
                    onHide={() => {
                        setProcedureReasonShow(false);
                    }}
                    onSave={() => {
                        if (sendData !== procedureList[cell].procedure_reason) {
                            setProcedureReasonShow(false);
                            setEditSuccess(true);
                            const procedure = procedureList;
                            procedure[cell].procedure_reason = sendData;
                            setProcedureList(procedure);
                        }
                    }}
                />
            ) : null}
            {deleteProcedureConfirmMsg === "success" ? (
                <DeleteProcedureConfirmPopup
                    show={deleteProcedureConfirmModalShow}
                    onHide={() => setDeleteProcedureConfirmModalShow(false)}
                    index={procedureIndexToDelete}
                />
            ) : (
                ""
            )}
            {imageViewShow ? (
                <ProcedureImgViewPopup
                    show={imageViewShow}
                    onHide={() => {
                        setImageViewShow(false);
                    }}
                />
            ) : null}
            {reasonAddShow ? (
                <ReasonAddPopup
                    show={reasonAddShow}
                    onHide={() => {
                        setReasonAddShow(false);
                    }}
                />
            ) : null}
            {remarkViewShow ? (
                <RemarkViewPopup
                    show={remarkViewShow}
                    onHide={() => {
                        setRemarkViewShow(false);
                    }}
                />
            ) : null}
        </div>
    )
})

export default AddProcedure
import React, { useState, useRef, useEffect, Fragment } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import { Link, useNavigate } from "react-router-dom";
function SideMenu(props) {
    const navigate = useNavigate();
    const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
    const [imgUrl, setImgUrl] = useState();
    const [age, setAge] = useState();
    const [name, setName] = useState();
    const [gender, setGender] = useState();
    const [assignData, setAssignData] = useState([]);
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
    const handleClick = (ref) => {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "top",
        });
      };
      useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
    
        axios
          .get(`${APIURL}/api/v1/service-provider/assign-room/?patient=${props.id}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              setAssignData(res.data.data);
            }
          });
    
      }, []);
      const handleWound = () => {
        navigate("/wound_report", {
          state: { name: name, id: props.id, gender: gender, woundId: "", mobile_number: "1", room: assignData ? assignData.bedunit_code : "" },
        });
      };
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");
    
        let str = tokenString.replace(/["]+/g, "");
         axios
          .get(`${APIURL}/api/v1/doctor/patient-dashboard/?patient_id=${props.id}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setName(res.data.data.name);
              setAge(res.data.data.age);
              setGender(res.data.data.gender);
             
    
             
            
            
              setImgUrl(res.data.data.photo);
            }
          });
       
        },[]);
    return(<>
     <div className="col-2" style={{ borderRight: "4px solid rgba(205, 205, 205, 1)", paddingLeft: "1.5%",position:"fixed",height:"100%" ,paddingTop:"1%"}}>
         <div className="row">
         <div className="sub-section-img col-4" style={{paddingleft:"5%"}}>
       
         <img
           onClick={() => {
            navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
              state: { ref:"ref1" },
            });
          }}
                          style={{
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            cursor:"pointer"
                           
                          }}
                    src={imgUrl}
                    alt="Profile Photo"
                    
                  />
                </div>
                <div style={{cursor:"pointer"}} onClick={() => {
            navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
              state: { ref:"ref1" },
            });
          }}className="col-8">
                <div className="recdiv" style={{borderRight:"none",textAlign:"left",paddingTop:"8%"}}>
                  <h6 className='text-style-here-patient-rec 'style={{color:"black"}} ><b>{name}</b></h6>
                  <h6 className='text-style-here-patient-rec'style={{color:"black"}} >{gender}{age !== 0 && `, ${age}`}</h6>
                  </div>
                </div>
         </div>
         <div className="">

<button className="patientRecbutton"
style={{width:"100%"}}
  onClick={() => {
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref1" },
    });
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Personal Data{" "}

</button>

<button className="patientRecbutton"
style={{width:"100%",cursor: (login_datas &&
  login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
  onClick={() => {if(login_datas.user_type === 'hospital_doctor' || login_datas.user_type === 'hospital_allied_prof'|| login_datas.user_type === 'hospital_nurse'){
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref3" },
    });
  }
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M6.44 9.439A1.495 1.495 0 0 1 7.5 9h5a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5H9.121l-.56-.56A4.482 4.482 0 0 0 9 13.5V15h3.5a.5.5 0 0 0 .5-.5V13H9v.5a4.481 4.481 0 0 0-1-2.829V10h-.5a.5.5 0 0 0-.145.021a4.503 4.503 0 0 0-.915-.582ZM13 10.5a.5.5 0 0 0-.5-.5H9v2h4v-1.5ZM5 9.027a4.551 4.551 0 0 0-1 0V4a2 2 0 0 1 2-2h4.586a1.5 1.5 0 0 1 1.06.44l3.915 3.914A1.5 1.5 0 0 1 16 7.414V16a2 2 0 0 1-2 2h-3.085a1.495 1.495 0 0 0-.354-.56l-.44-.44H14a1 1 0 0 0 1-1V8h-3.5A1.5 1.5 0 0 1 10 6.5V3H6a1 1 0 0 0-1 1v5.027ZM11.5 7h3.293L11 3.207V6.5a.5.5 0 0 0 .5.5Zm-4.197 8.596a3.5 3.5 0 1 0-.707.707l2.55 2.55a.5.5 0 1 0 .708-.707l-2.55-2.55ZM7 13.5a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0Z" /></svg>
  &nbsp;
  Diagnostics Info{" "}

</button>


<button className="patientRecbutton"
style={{width:"100%",cursor:(login_datas &&
  login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
  onClick={() => {if(login_datas.user_type === 'hospital_doctor' || login_datas.user_type === 'hospital_allied_prof'|| login_datas.user_type === 'hospital_nurse'){
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref5" },
    });
  }
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 21 21"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path d="M16.5 15.5v-7l-5-5h-5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zm-10-5h5m-5 2h7m-7 2h3" /><path d="M11.5 3.5v3a2 2 0 0 0 2 2h3" /></g></svg>
  &nbsp;
  Observations{" "}

</button>
<button className="patientRecbutton"
style={{width:"100%",cursor:(login_datas &&
  login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
  onClick={() => {if(login_datas.user_type === 'hospital_doctor' || login_datas.user_type === 'hospital_allied_prof'|| login_datas.user_type === 'hospital_nurse'){
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref6" },
    });
  }
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M20 2a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2H3v-2h2v-2H3v-2h2v-2H3V9h2V7H3V5h2V3a1 1 0 0 1 1-1h14Zm-1 2H7v16h12V4Zm-5 4v3h3v2h-3.001L14 16h-2l-.001-3H9v-2h3V8h2Z" /></svg>
  &nbsp;
  Symptoms{" "}

</button>



<button className="patientRecbutton"
style={{width:"100%",cursor:(login_datas &&
  login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
  onClick={() => {if(login_datas.user_type === 'hospital_doctor' || login_datas.user_type === 'hospital_allied_prof'|| login_datas.user_type === 'hospital_nurse'){
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref7" },
    });
  }
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Allergy{" "}

</button>

<button className="patientRecbutton"
style={{width:"100%",cursor:(login_datas &&
  login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&& login_datas.user_type !== 'hospital_nurse')? "not-allowed" : "pointer"}}
  onClick={() => {if(login_datas.user_type === 'hospital_doctor' || login_datas.user_type === 'hospital_allied_prof'|| login_datas.user_type === 'hospital_nurse'){
    navigate(`/patient-record/id/${props.id}/mob/${"1"}`, {
      state: { ref:"ref8" },
    });
  }
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><g fill="currentColor"><path d="M16 20a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2h-7Zm-1-4a1 1 0 0 1 1-1h15.5a1 1 0 1 1 0 2H16a1 1 0 0 1-1-1Z" /><path fill-rule="evenodd" d="M15 28a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 3v-2h2v2h-2Zm11 0a3 3 0 1 0 0-6a3 3 0 1 0 0 6Zm0-2a1 1 0 1 0 .002-1.998A1 1 0 0 0 28 29Zm-6 6.182C22 33.066 25.997 32 28 32s6 1.066 6 3.182V39H22v-3.818Zm2.055.04a.927.927 0 0 0-.055.057V37h8v-1.72a.927.927 0 0 0-.055-.059c-.164-.16-.48-.372-.976-.583c-1-.425-2.234-.638-2.969-.638c-.735 0-1.969.213-2.969.638c-.496.21-.812.423-.976.583Z" clip-rule="evenodd" /><path fill-rule="evenodd" d="M17 7a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3h4a3 3 0 0 1 3 3v31a3 3 0 0 1-3 3H13a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h4Zm11 5a3 3 0 0 0 3-3h4a1 1 0 0 1 1 1v31a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h4a3 3 0 0 0 3 3h8Zm-8-6a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1h-8Z" clip-rule="evenodd" /></g></svg>
  &nbsp;
  Medical Reports{" "}

</button>

</div>
       
          {/* {features.includes("wound_management") &&<div >
                <div onClick={() => {
                  if (
                    login_datas &&
                    (login_datas.user_type === 'hospital_doctor' || login_datas.user_type === "hospital_allied_prof"|| login_datas.user_type === "hospital_nurse") && assignData &&
                    assignData.patient
                  ) {
                    handleWound();
                  }

                }}


                  style={{backgroundColor:"rgba(43, 147, 160, 1)",marginBottom:"2%",marginTop:"2%",color:"white",borderRadius:"5px", 
                    padding: "5%", cursor: (login_datas &&
                      login_datas.user_type !== 'hospital_doctor' && login_datas.user_type !== 'hospital_allied_prof'&&login_datas.user_type !== "hospital_nurse")||!assignData? "not-allowed" : "pointer"
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><path d="M3 10c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h2c3.771 0 5.657 0 6.828 1.172C21 4.343 21 6.229 21 10v4c0 3.771 0 5.657-1.172 6.828C18.657 22 16.771 22 13 22h-2c-3.771 0-5.657 0-6.828-1.172C3 19.657 3 17.771 3 14v-4Z" /><path stroke-linecap="round" d="M8 10h8m-8 4h5" /></g></svg>&nbsp;&nbsp;
                  <label className='text-style-here ' style={{color:"white"}}>Wound</label>
                </div>
              </div>} 
              {features.includes("personal_inventory") && <div onClick={() => {
                if (
                  login_datas &&
                  login_datas.user_type === 'hospital_bdo' && assignData &&
                  assignData.patient
                ) {
                  navigate("/personal-inventory", {
                    state: { name: name, id: props.id, gender: gender, woundId: "", mobile_number: "1", room: assignData ? assignData.bedunit_code : "" ,admission:assignData? assignData.admission_date:""},

                  })
                }


              }} style={{
               backgroundColor:"rgba(43, 147, 160, 1)",marginBottom:"2%",borderRadius:"5px", color:"white", padding: "5%", cursor: (login_datas &&
                  login_datas.user_type !== 'hospital_bdo') || (!assignData ) ? "not-allowed" : "pointer"
              }}>
                <svg style={{
                cursor: (login_datas &&
                  login_datas.user_type !== 'hospital_bdo') || (!assignData ) ? "not-allowed" : "pointer"
              }}xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M13 9V3h8v6h-8ZM3 13V3h8v10H3Zm10 8V11h8v10h-8ZM3 21v-6h8v6H3Zm2-10h4V5H5v6Zm10 8h4v-6h-4v6Zm0-12h4V5h-4v2ZM5 19h4v-2H5v2Zm4-8Zm6-4Zm0 6Zm-6 4Z" /></svg>&nbsp;&nbsp;
                <label className='text-style-here ' style={{color:"white"}} >Inventory</label>
              </div>}  */}
         
          
        
        </div>
       
    </>
      
    )
}
export default SideMenu;
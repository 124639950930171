
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";

import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./reports.css";

import moment from "moment";





import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DeathReport from "./DeathReport";
import PatientReports from './PatientReports';
import MedicinesReport from './MedicinesReport';
import VolunteerReport from './VolunteerReport';
import TreatmentReport from './TreatmentReport'
import VisitorReport from "./VisitorReport";
import InventoryReport from "./InventoryReport";
 import RegistrationReport from "./RegistrationReport";
 import PaymentReport from "./PaymentReport";
 import RemoteRequests from "./RemoteRequests";

import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Reports() {
  
  const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  const [activeTab, setActiveTab] = useState("registration");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const tabTitles = {
    admission: 'Admission ',
    death: 'Death',
    treatment: 'Treatments',
    medicine: 'Medicines',
    volunteer: 'Volunteer',
    visitor: 'Visitor',
    inventory: 'Inventory',
    registration: 'Registration',
    payment: 'Payment',
    remote: 'Remote',
  };
  const [selectedEventKey, setSelectedEventKey] = useState("registration");
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };
  
  const handleTabSelect = (eventKey) => {
    setSelectedEventKey(eventKey);
  };

  return (
    <>
 <div className="reports-menu">

      <Tab.Container id="left-tabs-example" defaultActiveKey="registration" >
        <Row style={{width:"100%"}}>
          <Col sm={2} >

          <span >
          <h6 className="title-of-reportpage" style={{marginLeft:"10%"}}>{tabTitles[selectedEventKey]}</h6>
          </span>
            <Nav variant="pills" className="checkBoxWebReport" style={{height:"420px",display:"flex",flexDirection:"column",alignItems:"flex-start",padding:"5%"}}>
              
            <Nav.Item>
                <Nav.Link  eventKey="registration" onSelect={() => handleTabSelect("registration")}> Registration   </Nav.Link>
              </Nav.Item> 
              {(features.includes("ip_form_admission")||features.includes("ip_admission"))&& <Nav.Item>
                <Nav.Link  eventKey="admission" onSelect={() => handleTabSelect("admission")}> Admission   </Nav.Link>
              </Nav.Item> }
            
              {features.includes("death_management")&& <Nav.Item>
                <Nav.Link eventKey="death" onSelect={() => handleTabSelect("death")}> Death </Nav.Link>
              </Nav.Item>}
              <Nav.Item>
                <Nav.Link eventKey="treatment" onSelect={() => handleTabSelect("treatment")}> Treatments   </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="medicine" onSelect={() => handleTabSelect("medicine")}> Medicines   </Nav.Link>
              </Nav.Item>
              {features.includes("volunteer_registration")&& <Nav.Item>
                <Nav.Link eventKey="volunteer" onSelect={() => handleTabSelect("volunteer")}> Volunteer   </Nav.Link>
              </Nav.Item>}
              {features.includes("visitor_registration")&& <Nav.Item>
                <Nav.Link eventKey="visitor" onSelect={() => handleTabSelect("visitor")}> Visitor   </Nav.Link>
              </Nav.Item>}
              {features.includes("personal_inventory")&&<Nav.Item>
                <Nav.Link eventKey="inventory" onSelect={() => handleTabSelect("inventory")}> Inventory   </Nav.Link>
              </Nav.Item>}
              {features.includes("payments")&&<Nav.Item>
                <Nav.Link eventKey="payment" onSelect={() => handleTabSelect("payment")}> Payment   </Nav.Link>
              </Nav.Item>}
              {features.includes("services")&&<Nav.Item>
                <Nav.Link eventKey="remote" onSelect={() => handleTabSelect("remote")}> Remote   </Nav.Link>
              </Nav.Item>} 
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
            <Tab.Pane eventKey="registration">
               <RegistrationReport/>
              </Tab.Pane>
              <Tab.Pane eventKey="admission">
                <PatientReports />
              </Tab.Pane>
              <Tab.Pane eventKey="death">
                <DeathReport /> 
              </Tab.Pane>
              <Tab.Pane eventKey="treatment">
                <TreatmentReport /> 
              </Tab.Pane>
              <Tab.Pane eventKey="medicine">
               <MedicinesReport/>
              </Tab.Pane>
              <Tab.Pane eventKey="volunteer">
               <VolunteerReport/>
              </Tab.Pane>
              <Tab.Pane eventKey="visitor">
               <VisitorReport/>
              </Tab.Pane>
              <Tab.Pane eventKey="inventory">
               <InventoryReport/>
              </Tab.Pane>
               <Tab.Pane eventKey="payment">
               <PaymentReport/>
              </Tab.Pane>
              <Tab.Pane eventKey="remote">
               <RemoteRequests/>
              </Tab.Pane> 
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </div>

    </>

  )
}

export default Reports

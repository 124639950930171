import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import "./monitor.css"
import ProgressBar from 'react-bootstrap/ProgressBar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import patient from "../../../assets/images/icons/nurse1.png";
import SimpleBar from 'simplebar-react';
import {
  Badge,

  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,

} from "react-bootstrap";
function NurseList() {
  const [nurselist, setNurseList] = useState([]);
  const [nurselistFree, setNurseListFree] = useState([]);
  const [showModal, setShowModal] = useState(false)
  const [beds, setbeds] = useState([])

  useEffect(() => {
    let isMounted = true;

    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/assigned-nurses/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (isMounted) {
          if (res.data.status === "success") {
            setNurseList(res.data.nurse);
            setNurseListFree(res.data.free_nurses)
          }
        } else {

        }
      })
      .catch((err) => {

      });
    return () => {
      isMounted = false;
    };
  }, []);


  return (

    <div className="row ">
      <div className="col-8 ">
        <Card style={{ padding: "1%" }}>
          <Card.Title as="h4" style={{ marginBottom: "2%" }}>Nurses on duty</Card.Title>

          <Row>

            {nurselist.length > 0 && nurselist.map((item, index) => {
              return (

                <Col lg="6" sm="6">
                  <Card className="icons-right-not" style={{ border: " 0.5px solid rgba(183, 183, 183, 1)", padding: "0px" }} key={index}>

                    <Card.Body>
                      <Row>
                        <Col xs="3">
                          <div className="">
                            <img src={item.photo && item.photo !== "" ? item.photo : patient} alt="Profile Photo" style={{
                              maxWidth: "none",
                              borderRadius: "50%",
                              width: "70px",
                              height: "70px",
                            }} />
                          </div>
                        </Col>
                        <Col xs="7" style={{ textAlign: "left", paddingTop: "5%", textTransform: "capitalize" }}>
                          <b>{item.name}</b>
                          <div style={{ display: "flex" }}>
                            <ProgressBar style={{ marginTop: "5%", width: "100%", height: "7px" }} variant="danger" now={(item.attended_beds.length / item.bedunits.length) * 100} label={(item.attended_beds.length / item.bedunits.length) * 100 + "%"} />{" "}
                            <span style={{ color: "red", fontSize: "12px" }} >&nbsp;{item.attended_beds.length}/{item.bedunits.length}&nbsp;</span>
                          </div>
                        </Col>
                        <Col xs="2" style={{ paddingTop: "7%" }}>
                          <span style={{ cursor: "pointer", backgroundColor: "rgba(40, 165, 216, 0.1)", padding: "3%", borderRadius: "50%" }} >
                            <svg onClick={() => { setbeds(item.bedunits); setShowModal(true) }} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path fill="currentColor" d="M6.75 4h10.5a2.75 2.75 0 0 1 2.745 2.582L20 6.75v3.354a2.752 2.752 0 0 1 1.994 2.459l.006.187v7.5a.75.75 0 0 1-1.493.102l-.007-.102V18h-17v2.25a.75.75 0 0 1-.648.743L2.75 21a.75.75 0 0 1-.743-.648L2 20.25v-7.5c0-1.259.846-2.32 2-2.647V6.75a2.75 2.75 0 0 1 2.582-2.745L6.75 4Zm12.5 7.5H4.75a1.25 1.25 0 0 0-1.244 1.122l-.006.128v3.75h17v-3.75a1.25 1.25 0 0 0-1.122-1.243l-.128-.007Zm-2-6H6.75a1.25 1.25 0 0 0-1.244 1.122L5.5 6.75V10H7a1 1 0 0 1 1-1h2a1 1 0 0 1 .993.883L11 10h2a1 1 0 0 1 1-1h2a1 1 0 0 1 .993.883L17 10h1.5V6.75a1.25 1.25 0 0 0-1.122-1.244L17.25 5.5Z" /></svg>
                          </span>
                        </Col>
                      </Row>


                    </Card.Body>

                  </Card>
                </Col>)
            })}
          </Row>
        </Card>
      </div>
      <div className="col-4" style={{ paddingLeft: "1%" }} >
        <Card style={{ padding: "1%" }}>
          <Card.Title as="h4">Free Nurses</Card.Title>
          <Row>

            {nurselistFree.length > 0 && nurselistFree.map((item, index) => {
              return (

                <Col lg="12" sm="12">
                  <Card className="icons-right-not" style={{ border: "none", marginBottom: "0px", padding: "0px" }} key={index}>

                    <Card.Body>
                      <Row style={{ border: " 0.5px solid rgba(183, 183, 183, 1)", paddingTop: "5%", paddingBottom: "5%", borderRadius: "5px" }}>
                        <Col xs="3">
                          <div className="">
                            <img src={patient} alt="Profile Photo" style={{
                              maxWidth: "none",
                              borderRadius: "50%",
                              width: "70px",
                              height: "70px",
                            }} />
                          </div>
                        </Col>
                        <Col xs="9" style={{ textAlign: "left", paddingTop: "5%", textTransform: "capitalize" }}>
                          <h5><b>{item.name}</b></h5>
                        </Col>
                      </Row>


                    </Card.Body>

                  </Card>
                </Col>)
            })}
          </Row>
        </Card>
      </div>



      <Modal
        className="modal-mini modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-bulb-63"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center" style={{ alignItems: "center", justifyContent: "center", margin: "0px" }}>
          <div className="row">
            {beds.length > 0 && beds.map((item) => {
              return (
                <p className="col-3" style={{ textAlign: "left" }}>{item}</p>
              )
            })}
          </div>

        </Modal.Body>
        <div className="modal-footer">

          <Button
            className="btn-simple"
            type="button"
            variant="link"
            onClick={() => setShowModal(false)}
            style={{ textDecoration: 'none' }}
          >
            Close
          </Button>
        </div>
      </Modal>



    </div>
  )
}
export default NurseList;
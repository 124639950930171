import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Folder from "./Folder"
import axios from 'axios';
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import RenderSelected from "./RenderSelected";
import { UserManagementContext } from "../contexts/UserManagementContext";
import './manageradd.css'
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";
function ChangeAccess({ data }) {
    const navigate = useNavigate();
    const [selected] = useContext(UserManagementContext)
    const [nodesData, setNodesData] = useState([])
    const alert = useAlert()
    const [node, setNode] = useState([])
    const [expanded, setExpanded] = useState()
    const [rendered, setRendered] = useContext(UserManagementRenderContext)
    useEffect(() => {
        const pushState = () => {
            window.history.pushState(null, null, window.location.pathname);
        };

        const handlePopState = (e) => {
            pushState();
            setRendered('users');
        };
        pushState();
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dataCall(0)
    }, [])

    const dataCall = (nodeid) => {
        const tokenString = sessionStorage.getItem("usertoken");
        const token = tokenString.replace(/["]+/g, "");

        axios.get(`${APIURL}/api/v1/service-provider/access-nodes/?parent_node=${nodeid}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Token " + token
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data.details
                    setNodesData(datas)
                } else {
                    alert.error("error");
                }
            })
            .catch((err) => {
                console.log(err);
                alert.error("error");
            });
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        console.log('hi')
    };

    const handleRender = (clickedNode) => {
        const { node_access } = clickedNode;
        const nodeAccessLevels = node_access.split('.').map(Number); // Convert "0.1.2" to [0, 1, 2]
        const updatedNodes = [];

        let currentNodeAccess = '';

        for (let i = 0; i < nodeAccessLevels.length; i++) {
            currentNodeAccess += (i > 0 ? '.' : '') + nodeAccessLevels[i];

            const matchingNode = findNodeByAccess(nodesData, currentNodeAccess);
            if (matchingNode) {
                updatedNodes.push(matchingNode);
            }
        }

        setNode(updatedNodes);
    };

    const findNodeByAccess = (nodes, access) => {
        for (let node of nodes) {
            if (node.node_access === access) return node;
            if (node.children) {
                const foundNode = findNodeByAccess(node.children, access);
                if (foundNode) return foundNode;
            }
        }
        return null;
    };


    return (
        <div className="add-dr-page">
            <div className="add-dr-form-holder" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="row" style={{ margin: "0 10%", boxShadow: "5px 5px 10px #ccc" }}>
                    <div className="col-lg-4 col-md-4" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#1877f20f', padding: 0, boxShadow: ' rgb(0 0 0 / 9%) 2px 0px 5px' }}>
                        <div style={{ padding: '3%', backgroundColor: '#2B69A3', color: '#fff', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <label style={{ margin: 0, }}>User access level</label>
                        </div>
                        <Folder
                            setNode={setNode}
                            expanded={expanded}
                            nodesData={nodesData}
                            setNodesData={setNodesData}
                            handleRender={handleRender}
                            onToggle={handleToggle} />
                    </div>
                    <div className="col-lg-8 col-md-8">
                        {node.length > 1 ?
                            < RenderSelected
                                node={node}
                                data={data}
                            /> : <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}><label className="access-selection-label" style={{ marginLeft: 'auto', textTransform: 'capitalize' }}>{data && data.name}</label></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeAccess;
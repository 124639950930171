import React, { useEffect, useState } from 'react';
import '../PRO/Dialysis/addMachine.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import Select from 'react-select';

const ConsultationFees = (props) => {
    const [physioId, setPhysioId] = useState('');
    const [physio, setPhysio] = useState('');
    const [physioOptions, setPhysioOptions] = useState([]);
    const [fees, setFees] = useState({
        feeOffline: '',
        feeOnline: '',
        idOffline: 0,
        idOnline: 0,
        isChangeOffline: false,
        isChangeOnline: false,
        isActiveOffline: false,
        isActiveOnline: false,
        updateOffline: false,
        updateOnline: false
    });
    const [mode, setMode] = useState('');
    const [activate, setActivate] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        addedPhysios(5);
    }, []);

    const addedPhysios = async (val) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        let dep = `department[]=${val}`;

        axios
            .get(`${APIURL}/api/v1/service-provider/access-department-users/?${dep}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.details;
                    const options = filteredData.map((physio) => ({
                        value: physio.id,
                        label: physio.name,
                        userId: physio.user,
                    }));
                    setPhysioOptions(options);
                }
            })
            .catch((err) => { });
    };

    const physioChange = (selectedOption) => {
        if (selectedOption) {
            setPhysio({
                value: selectedOption.value,
                name: selectedOption.label,
            });
            setPhysioId(selectedOption.userId);
            handleGetFee(selectedOption.userId);
        } else {
            setPhysio({
                value: '',
                name: '',
            });
        }
    };

    const handleGetFee = (p_id) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/doctor/consultation-fees/?consultant_id=${p_id}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const feeData = res.data;

                    let updatedFees = {
                        feeOffline: '',
                        feeOnline: '',
                        idOffline: 0,
                        idOnline: 0,
                        isChangeOffline: false,
                        isChangeOnline: false,
                        isActiveOffline: false,
                        isActiveOnline: false,
                        updateOffline: true,
                        updateOnline: true,
                    };

                    feeData.forEach((feeItem) => {
                        if (feeItem.consult_mode === 'offline') {
                            updatedFees = {
                                ...updatedFees,
                                feeOffline: feeItem.fees,
                                idOffline: feeItem.id,
                                isChangeOffline: true,
                                isActiveOffline: feeItem.is_active,
                                updateOffline: false,
                            };
                        } else if (feeItem.consult_mode === 'online') {
                            updatedFees = {
                                ...updatedFees,
                                feeOnline: feeItem.fees,
                                idOnline: feeItem.id,
                                isChangeOnline: true,
                                isActiveOnline: feeItem.is_active,
                                updateOnline: false,
                            };
                        }
                    });

                    setFees(updatedFees);
                }
            });
    };

    const callSave = () => {
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        let data = {
            consultant_id: physioId,
            fees: mode === "offline" ? fees.feeOffline : fees.feeOnline,
            consult_mode: mode,
        };

        if ((mode === 'offline' && (fees.feeOffline < 1)) || (mode === 'online' && (fees.feeOnline < 1))) {
            alert.error("Please Enter valid number");
        } else {
            axios.post(`${APIURL}/api/v1/doctor/consultation-fees/`, data, {
                headers: {
                    "Authorization": 'Token ' + str,
                },
            })
                .then(res => {
                    if (res.data.status === "success") {
                        alert.success("Successfully added");
                        handleGetFee(physioId);
                    }
                })
                .catch(err => { });
        }
    };

    const callUpdate = () => {
        if (mode === '') {
            alert.error('Edit to save')
        } else {
            const tokenString = sessionStorage.getItem('usertoken');
            let str = tokenString.replace(/["]+/g, '');
            let data = {
                consultant_id: physioId,
                fees: mode === "offline" ? fees.feeOffline : fees.feeOnline,
                consult_mode: mode,
            };

            if ((mode === "offline" && fees.feeOffline < 1) || (mode === "online" && fees.feeOnline < 1)) {
                alert.error("Please Enter valid Amount");
            } else {
                axios.put(`${APIURL}/api/v1/doctor/consultation-fees-detail/${mode === "offline" ? fees.idOffline : fees.idOnline}/`, data, {
                    headers: {
                        "Authorization": 'Token ' + str,
                    },
                })
                    .then(res => {
                        if (res.data.status === "success") {
                            alert.success("Successfully Updated");
                            handleGetFee(physioId);
                        } else {
                            alert.error(res.data.message || "Error while Updating");
                        }
                    })
                    .catch(err => {
                        alert.error("Error while Updating");
                    });
            }
        }
    };

    const callActivate = () => {
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        let data = {
            is_active: mode === "offline" ? !fees.isActiveOffline : !fees.isActiveOnline,
        };

        axios.put(`${APIURL}/api/v1/doctor/consultation-fees-detail/${mode === "offline" ? fees.idOffline : fees.idOnline}/`, data, {
            headers: {
                "Authorization": 'Token ' + str,
            },
        })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success("Successfully Updated");
                    handleGetFee(physioId)
                } else {
                    alert.error("Error while Updating");
                }
            })
            .catch(err => {
                alert.error("Error while Updating");
                handleGetFee(physioId)
            });
    };

    const handleChange = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        setMode(key);
        setFees({
            ...fees,
            [key === "offline" ? "feeOffline" : "feeOnline"]: val,
        });
    };

    return (
        <div className='mschedule-card'>
            <div className='msinput-container col-6'>
                <label>Select Allied Professional</label>
                <Select
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            minHeight: '44px',
                            border: '1px solid #E3E3E3',
                        }),
                    }}
                    value={physio.value ? { value: physio.value, label: physio.name } : null}
                    onChange={physioChange}
                    options={physioOptions}
                    name="physio"
                />
            </div>
            {physioId !== '' && <div className='col-6' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="conslt-div" style={{ display: 'flex' }}>
                    <div className='col-2'>
                        {fees.isChangeOffline ? <>
                            <i className={fees.isActiveOffline ? "fa fa-toggle-on " : "fa fa-toggle-on fa-rotate-180 "}
                                style={{ color: fees.isActiveOffline ? "green" : "red", cursor: "pointer", marginTop: "1%", marginLeft: '3%' }}
                                onClick={() => { setMode("offline"); setActivate(!activate); callActivate(); }}
                            ></i></> : ""}
                    </div>
                    <label className="col-3 label-consult">In-person:</label>
                    <input
                        disabled={!fees.updateOffline}
                        style={{ textAlign: "center", width: "87px", color: fees.isActiveOffline ? "black" : "silver" }}
                        className='confinput-consult col-3'
                        type="number"
                        min={0}
                        name="offline"
                        placeholder="In RS/-"
                        value={fees.feeOffline}
                        onChange={handleChange}
                    />
                    <div className='col-3'>
                        {(!fees.isChangeOffline || fees.updateOffline) ?
                            <button className='save-button' onClick={() => { setMode("offline"); if (fees.isChangeOffline) { callUpdate(); } else { callSave(); } }}>Save</button>
                            : <button className='save-button'
                                onClick={() => {
                                    setFees({
                                        ...fees,
                                        updateOffline: true,
                                    });
                                }}>Update</button>
                        }
                    </div>
                </div>
                <div className="conslt-div">
                    <div className='col-2'>
                        {fees.isChangeOnline ? <>
                            <i className={fees.isActiveOnline ? "fa fa-toggle-on " : "fa fa-toggle-on fa-rotate-180 "}
                                style={{ color: fees.isActiveOnline ? "green" : "red", cursor: "pointer", marginTop: "1%", marginLeft: '3%' }}
                                onClick={() => { setMode("offline"); setActivate(!activate); callActivate(); }}
                            ></i></> : ""}
                    </div>
                    <label className="col-3 label-consult" style={{ paddingTop: "1%", }}>Online:</label>
                    <input
                        disabled={!fees.updateOnline}
                        style={{ textAlign: "center", width: "87px", color: fees.isActiveOnline ? "black" : "silver" }}
                        className='confinput-consult col-3'
                        type="number"
                        min={0}
                        name="online"
                        placeholder="In RS/-"
                        value={fees.feeOnline}
                        onChange={handleChange}
                    />
                    <div className='col-4'>
                        {(!fees.isChangeOnline || fees.updateOnline) ?
                            <button className='save-button' style={{ marginLeft: 'auto' }} onClick={() => { setMode("online"); if (fees.isChangeOnline) { callUpdate(); } else { callSave(); } }}>Save</button>
                            : <button className='save-button' style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    setFees({
                                        ...fees,
                                        updateOnline: true,
                                    });
                                }}>Update</button>
                        }
                    </div>
                </div>
            </div>}
        </div >
    );
};

export default ConsultationFees;

import React, { useContext, useState, useEffect, useRef } from "react";
import ChartistGraph from "react-chartist";
// import { Pie } from 'react-chartjs-2';
import { Chart, ChartConfiguration, LineController, LineElement, PointElement, LinearScale, Tooltip, Title, TimeScale } from 'chart.js'
import 'chartjs-adapter-date-fns';
import { Scatter, Bubble } from "react-chartjs-2";
import moment from 'moment-timezone';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from "axios";
import { APIURL } from "../../../../Global";
import { SOCKETURL } from "../../../../Global";
import "../monitor.css"

import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,

} from "react-bootstrap";
import NurseList from "../NurseList";
import MyPieChart from "./MyPieChart";
Chart.register(LineController, LineElement, Tooltip, PointElement, LinearScale, TimeScale, Title);
function Monitor(props) {
  const [date, setDate] = useState(new Date())
  const [date1, setDate1] = useState(new Date().toLocaleDateString('en-CA'))
  const webSocket = useRef(null);
  const currentDate = new Date();
  const [startTime,setStartTime] = useState(currentDate.setHours(1, 0, 0, 0))
  const [endTime,setEndTime] = useState(currentDate.setHours(24, 0, 0, 0));
  const [type, setType] = useState("medicine");
  const [completedCon, setCompletedCon] = useState(0)
  const [pendingCon, setPendingCon] = useState(0)
  const [missedCon, setMissedCon] = useState(0)
  const [completedPatCon, setCompletedPatCon] = useState(0)
  const [missedPatCon, setMissedPatCon] = useState(0)
  const [totalCon, setTotalCon] = useState(0)
  const [graphDataCon, setGraphDataCon] = useState([])

  const [completedTest, setCompletedTest] = useState(0)
  const [pendingTest, setPendingTest] = useState(0)
  const [missedTest, setMissedTest] = useState(0)
  const [completedPatTest, setCompletedPatTest] = useState(0)
  const [missedPatTest, setMissedPatTest] = useState(0)
  const [totalTest, setTotalTest] = useState(0)
 const [graphDataTest, setGraphDataTest] = useState([])

  const [completedScan, setCompletedScan] = useState(0)
  const [pendingScan, setPendingScan] = useState(0)
  const [missedScan, setMissedScan] = useState(0)
  const [completedPatScan, setCompletedPatScan] = useState(0)
  const [missedPatScan, setMissedPatScan] = useState(0)
  const [totalScan, setTotalScan] = useState(0)
 const [graphDataScan, setGraphDataScan] = useState([])

  const [completedPro, setCompletedPro] = useState(0)
  const [pendingPro, setPendingPro] = useState(0)
  const [missedPro, setMissedPro] = useState(0)
  const [completedPatPro, setCompletedPatPro] = useState(0)
  const [missedPatPro, setMissedPatPro] = useState(0)
  const [totalPro, setTotalPro] = useState(0)
 const [graphDataPro, setGraphDataPro] = useState([])

  const [completedMed, setCompletedMed] = useState(0)
  const [pendingMed, setPendingMed] = useState(0)
  const [delayedMed, setDelayedMed] = useState(0)
  const [missedMed, setMissedMed] = useState(0)

  const [completedLqd, setCompletedLqd] = useState(0)
  const [pendingLqd, setPendingLqd] = useState(0)
  const [delayedLqd, setDelayedLqd] = useState(0)
  const [missedLqd, setMissedLqd] = useState(0)

  const [completedFd, setCompletedFd] = useState(0)
  const [pendingFd, setPendingFd] = useState(0)
  const [delayedFd, setDelayedFd] = useState(0)
  const [missedFd, setMissedFd] = useState(0)

  const [completed, setCompleted] = useState(0)
  const [completedPatMed, setCompletedPatMed] = useState(0)
  const [completedPatLq, setCompletedPatLq] = useState(0)
  const [completedPatFd, setCompletedPatFd] = useState(0)
  const [completedPat, setCompletedPat] = useState(0)
  const [delayedPat, setDelayedPat] = useState(0)
  const [missedPat, setMissedPat] = useState(0)
  const [pending, setPending] = useState(0)
  const [delayedd, setDelayed] = useState(0)
  const [delayedPatMed, setDelayedPatMed] = useState(0)
  const [delayedPatFd, setDelayedPatFd] = useState(0)
  const [delayedPatLq, setDelayedPatLq] = useState(0)
  const [missed, setMissed] = useState(0)
  const [missedPatMed, setMissedPatMed] = useState(0)
  const [missedPatFd, setMissedPatFd] = useState(0)
  const [missedPatLq, setMissedPatLq] = useState(0)
  const [completedP, setCompletedP] = useState(0)
  const [pendingP, setPendingP] = useState(0)
  const [delayedP, setDelayedP] = useState(0)
  const [missedP, setMissedP] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [refresh1, setRefresh1] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [graphDataMed, setGraphDataMed] = useState([])
  const [graphDataLqd, setGraphDataLqd] = useState([])
  const [graphDataFd, setGraphDataFd] = useState([])
  const currentTime = new Date().getTime();
  const checkTime = new Date();
  
  const morningStart = new Date(checkTime.setHours(7, 0, 0, 0)).getTime();
  const morningEnd = new Date(checkTime.setHours(11, 59, 0, 0)).getTime();
  
  const afternoonStart = new Date(checkTime.setHours(12, 0, 0, 0)).getTime();
  const afternoonEnd = new Date(checkTime.setHours(15, 29, 0, 0)).getTime();
  
  const eveningStart = new Date(checkTime.setHours(15, 30, 0, 0)).getTime();
  const eveningEnd = new Date(checkTime.setHours(19, 29, 0, 0)).getTime();
  
  const nightStart = new Date(checkTime.setHours(19, 30, 0, 0)).getTime();
  const nightEnd = new Date(checkTime.setHours(23, 59, 59, 999)).getTime();
  
  const [serveTime,setServeTime]=useState((currentTime >= morningStart && currentTime <= morningEnd) ?
    'morning'
 : (currentTime >= afternoonStart && currentTime <= afternoonEnd) ?
    'afternoon'
  : (currentTime >= eveningStart && currentTime <= eveningEnd) ?
    'evening'
  : (currentTime >= nightStart && currentTime <= nightEnd) ?
    'night':"")
  
  
  console.log(serveTime);
  const [totalMed, setTotalMed] = useState(0)
  const [totalLqd, setTotalLqd] = useState(0)
  const [totalFd, setTotalFd] = useState(0)
  const [total, setTotal] = useState(0)
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const annotationPadding = 0.05;
  const [medFlag, setMedFlag] = useState(false)
  const [fdFlag, setFdFlag] = useState(false)
  const [lqFlag, setLqFlag] = useState(false)
  const [conFlag, setConFlag] = useState(false)
  const [testFlag, setTestFlag] = useState(false)
  const [scanFlag, setScanFlag] = useState(false)
  const [proFlag, setProFlag] = useState(false)
  const [wsFlag,setWSFlag]=useState("")
  const [wsrefresh,setWSRefresh]=useState(false)
  const [socketLoaded,setSocketLoaded] = useState(false);

 
  useEffect(() => {
    if (type === "medicine") {
      setCompleted(completedMed)
      setMissed(missedMed)
      setDelayed(delayedMed)
      setTotal(totalMed)
      setPending(totalMed - (completedMed + missedMed + delayedMed))
      setGraphData(graphDataMed)
      setMissedPat(missedPatMed)
      setCompletedPat(completedPatMed)
      setDelayedPat(delayedPatMed)
    }
    else if (type === "food") {
      setCompleted(completedFd)
      setMissed(missedFd)
      setDelayed(delayedFd)
      setTotal(totalFd)
      setPending(totalFd - (completedFd + missedFd + delayedFd))
      setGraphData(graphDataFd)
      setMissedPat(missedPatFd)
      setCompletedPat(completedPatFd)
      setDelayedPat(delayedPatFd)
    }
    else if (type === "liquid") {
      setCompleted(completedLqd)
      setMissed(missedLqd)
      setDelayed(delayedLqd)
      setTotal(totalLqd)
      setPending(totalLqd - (completedLqd + missedLqd + delayedLqd))
      setGraphData(graphDataLqd)
      setMissedPat(missedPatLq)
      setCompletedPat(completedPatLq)
      setDelayedPat(delayedPatLq)
    }

    else if (type === "consumables") {
      setCompleted(completedCon)
      setMissed(missedCon)
      setTotal(totalCon)
      setPending(totalCon - (completedCon + missedCon ))
      setGraphData(graphDataCon)
      setMissedPat(missedPatCon)
      setCompletedPat(completedPatCon)
   
    }
    else if (type === "test") {
      setCompleted(completedTest)
      setMissed(missedTest)
      setTotal(totalTest)
      setPending(totalTest - (completedTest + missedTest ))
      setGraphData(graphDataTest)
      setMissedPat(missedPatTest)
      setCompletedPat(completedPatTest)
   
    }
    else if (type === "scan") {
      setCompleted(completedScan)
      setMissed(missedScan)
      setTotal(totalScan)
      setPending(totalScan - (completedScan + missedScan ))
      setGraphData(graphDataScan)
      setMissedPat(missedPatScan)
      setCompletedPat(completedPatScan)
   
    }
    else if (type === "procedure") {
      setCompleted(completedPro)
      setMissed(missedPro)
      setTotal(totalPro)
      setPending(totalPro - (completedPro + missedPro ))
      setGraphData(graphDataPro)
      setMissedPat(missedPatPro)
      setCompletedPat(completedPatPro)
   
    }

  }, [ refresh,graphDataMed,graphDataFd,graphDataLqd,graphDataCon,graphDataScan,graphDataPro,graphDataTest])
  useEffect(() => {
    if (wsFlag === "medication_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "medicine") {
        handlingAll()
      }
      else {
        setMedFlag(true)
      }
    }
    if (wsFlag === "food_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "food") {
        handlingAll()
      }
      else {
        setFdFlag(true)
      }
    }
    if (wsFlag === "liquid_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "liquid") {
        handlingAll()
      }
      else {
        setLqFlag(true)
      }
    }
    if (wsFlag === "consumables_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "consumables") {
        handlingAll()
      }
      else {
        setConFlag(true)
      }
    }
    if (wsFlag === "test_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "test") {
        handlingAll()
      }
      else {
        setTestFlag(true)
      }
    }
    if (wsFlag === "scan_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "scan") {
        handlingAll()
      }
      else {
        setScanFlag(true)
      }
    }
    if (wsFlag === "procedure_monitor") {
      if (date1 === new Date().toLocaleDateString('en-CA') && type === "procedure") {
        handlingAll()
      }
      else {
        setProFlag(true)
      }
    }
  }, [wsrefresh])

  const handlingWebsocket = () => {
    if(socketLoaded) {
      return;
    }
    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/pharmacy_request/sp_` +
      hospId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
      setSocketLoaded(true);
    };

    webSocket.current.onmessage = (evt) => {
    
      const message = JSON.parse(evt.data);
      console.log(message);
     
      if (message.message.message_type === "medication_monitor") {
      setWSFlag("medication_monitor")
      
      setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "food_monitor") {
        setWSFlag("food_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "liquid_monitor") {
        setWSFlag("liquid_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "consumables_monitor") {
        setWSFlag("consumables_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "test_monitor") {
        setWSFlag("test_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "scan_monitor") {
        setWSFlag("scan_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }
      if (message.message.message_type === "procedure_monitor") {
        setWSFlag("procedure_monitor")
        setWSRefresh(wsrefresh => !wsrefresh)
      }

    };

    webSocket.current.onclose = () => {

    };
  }
  useEffect(() => {
    setGraphData([])
    handlingGraph();
    handlingCard();
  }, [type,serveTime,date1,props.id])
  useEffect(() => {
    if (type === "medicine" && date1 === new Date().toLocaleDateString('en-CA')) {
      setMedFlag(false)
    }
    else if (type === "food" && date1 === new Date().toLocaleDateString('en-CA')) {
      setFdFlag(false)
    }
    else if (type === "liquid" && date1 === new Date().toLocaleDateString('en-CA')) {
      setLqFlag(false)
    }
    else if (type === "consumables" && date1 === new Date().toLocaleDateString('en-CA')) {
      setConFlag(false)
    }
    else if (type === "test" && date1 === new Date().toLocaleDateString('en-CA')) {
      setTestFlag(false)
    }
    else if (type === "scan" && date1 === new Date().toLocaleDateString('en-CA')) {
      setScanFlag(false)
    }
    else if (type === "procedure" && date1 === new Date().toLocaleDateString('en-CA')) {
      setProFlag(false)
    }
  }, [type, date1])
  const handlingAll = () => { handlingGraph(); handlingCard() }
  useEffect(() => {
      handlingWebsocket();
  }, [])
  const handlingCard = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')



    axios.get(`${APIURL}/api/v1/service-provider/current-drug-admin/?serv_time=${serveTime}&category=${type}&date=${date1}&bedunit_data=${props.id}&timeZone=${currentTimezone}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          if (type === "medicine") {
            setCompletedMed(res.data.med_completed)
            setMissedMed(res.data.med_missed)
            setDelayedMed(res.data.med_delayed)
            setTotalMed(res.data.med_total)
            setPendingMed(res.data.med_total - (res.data.med_completed + res.data.med_missed + res.data.med_delayed))
            setCompletedPatMed(res.data.completed_pat_count)
            setMissedPatMed(res.data.missed_pat_count)
            setDelayedPatMed(res.data.delayed_pat_count)
            
          }
          else if (type === "food") {
            setCompletedFd(res.data.food_completed)
            setMissedFd(res.data.food_missed)
            setDelayedFd(res.data.food_delayed)
            setTotalFd(res.data.food_total)
            setPendingFd(res.data.food_total - (res.data.food_completed + res.data.food_missed + res.data.food_delayed))
            setCompletedPatFd(res.data.completed_pat_count)
            setMissedPatFd(res.data.missed_pat_count)
            setDelayedPatFd(res.data.delayed_pat_count)
           
          }
          else if (type === "liquid") {
            setCompletedLqd(res.data.liquid_completed)
            setMissedLqd(res.data.liquid_missed)
            setDelayedLqd(res.data.liquid_delayed)
            setTotalLqd(res.data.liquid_total)
            setPendingLqd(res.data.liquid_total - (res.data.liquid_completed + res.data.liquid_missed + res.data.liquid_delayed))
            setCompletedPatLq(res.data.completed_pat_count)
            setMissedPatLq(res.data.missed_pat_count)
            setDelayedPatLq(res.data.delayed_pat_count)
           
          }
          else if (type === "consumables") {
            setCompletedCon(res.data.completed)
            setMissedCon(date1 === new Date().toLocaleDateString('en-CA')?0:res.data.missed)
            setTotalCon(res.data.total)
            setPendingCon(date1 === new Date().toLocaleDateString('en-CA')?res.data.total - (res.data.completed  ):res.data.total - (res.data.completed + res.data.missed ))
            setCompletedPatCon(res.data.completed_pat_count)
            setMissedPatCon(res.data.missed_pat_count)
           
            
          }
          else if (type === "test") {
            setCompletedTest(res.data.completed)
            setMissedTest(date1 === new Date().toLocaleDateString('en-CA')?0:res.data.missed)
            setTotalTest(res.data.total)
            setPendingTest(date1 === new Date().toLocaleDateString('en-CA')?res.data.total - (res.data.completed  ):res.data.total - (res.data.completed + res.data.missed ))
            setCompletedPatTest(res.data.completed_pat_count)
            setMissedPatTest(res.data.missed_pat_count)
           
            
          }
          else if (type === "scan") {
            setCompletedScan(res.data.completed)
            setMissedScan(date1 === new Date().toLocaleDateString('en-CA')?0:res.data.missed)
            setTotalScan(res.data.total)
            setPendingScan(date1 === new Date().toLocaleDateString('en-CA')?res.data.total - (res.data.completed  ):res.data.total - (res.data.completed + res.data.missed ))
            setCompletedPatScan(res.data.completed_pat_count)
            setMissedPatScan(res.data.missed_pat_count)
           
            
          }
          else if (type === "procedure") {
            setCompletedPro(res.data.completed)
            setMissedPro(date1 === new Date().toLocaleDateString('en-CA')?0:res.data.missed)
            setTotalPro(res.data.total)
            setPendingPro(date1 === new Date().toLocaleDateString('en-CA')?res.data.total - (res.data.completed  ):res.data.total - (res.data.completed + res.data.missed ))
            setCompletedPatPro(res.data.completed_pat_count)
            setMissedPatPro(res.data.missed_pat_count)
           
            
          }
        }
        setRefresh(!refresh)

      })
      .catch(err => {

      })


  }

  const handlingGraph = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    let data = []

    axios.get(`${APIURL}/api/v1/service-provider/administer-graph/?category=${type}&date=${date1}&bedunit_data=${props.id}&timeZone=${currentTimezone}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          res.data.message.length > 0 && res.data.message.map((item) => {

            data = ([...data, { x: item.administered_time.slice(0, 19), y: item.patient_id, nurse: item.administered_by }])
          })

        }
        if (type === "medicine") {
          setGraphDataMed(data)
        
        }
        else if (type === "food") {
          setGraphDataFd(data)
          
        }
        else if (type === "liquid") {
          setGraphDataLqd(data)
        
        }
        else if (type === "consumables") {
          setGraphDataCon(data)
        
        }
        else if (type === "test") {
          setGraphDataTest(data)
         
        }
        else if (type === "scan") {
          setGraphDataScan(data)
          
        }
        else if (type === "procedure") {
          setGraphDataPro(data)
          
        }
        
      })
      .catch(err => {

      })


  }



  const formatNumber = (number) => {
    const roundedNumber = parseFloat(number).toFixed(2);
    return roundedNumber.endsWith('.00') ? roundedNumber.split('.')[0] : roundedNumber;
  };
  useEffect(() => {
    if (total > 0 && completed > 0) {
      let n = formatNumber(completed / total * 100)
      setCompletedP(n)
    }
    else setCompletedP(0)
    if (total > 0 && pending > 0) {
      let n = formatNumber(pending / total * 100)
      setPendingP(n)
    }
    else setPendingP(0)
    if (total > 0 && missed > 0) {
      let n = formatNumber(missed / total * 100)
      setMissedP(n)
    }
    else setMissedP(0)
    if (total > 0 && delayedd > 0) {
      let n = formatNumber(delayedd / total * 100)
      setDelayedP(n)
    }
    else setDelayedP(0)
  }, [completed, total, pending, missed, delayedd])

  const getColor = (context) => {
    const value = context.dataset.data[context.dataIndex];


    return "#28a745";
  };

  const getRadius = (context) => {
    const value = context.dataset.data[context.dataIndex];
    return 5;
  };
  const plugins = {

    tooltip: {

      callbacks: {

        label: function (context) {
          const data = context.dataset.data[context.dataIndex];
          return `Service: ${data.x}, Count: ${data.y}`;
        },
      },
    },
    afterDatasetsDraw: (chartInstance) => {
      var ctx = chartInstance.chart.ctx;
      chartInstance.data.datasets.forEach((dataset, i) => {
        var meta = chartInstance.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.forEach((element, index) => {
            ctx.fillStyle = "white";
            var dataString = dataset.data[index].count.toString() + "人";
            ctx.font = "bold 11px arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var position = element.tooltipPosition();
            ctx.fillText(dataString, position.x, position.y);
          });
        }
      });
    }

  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const e = context.dataset.data[context.dataIndex];
            return (e.y, e.nurse);
          },
        },
      },
    },
    elements: {
      bubble: {
        label: (context) => {
          console.log(context)
          return context.raw.service
        }
      },
      point: {
        backgroundColor: getColor.bind(null),
        radius: getRadius.bind(null)
      }
    },
    tooltips: {
      callbacks: {
        label: (context) => {

        }
      }
    },

    scales: {
      x: {
        type: 'time',  // Set the x-axis scale to 'time'

        time: {
          unit: 'hour',
          tooltipFormat: 'h:mm a',
          displayFormats: {
            hour: 'h:mm a',
            timezone: { currentTimezone },
          },

        },
        min: startTime,
        max: endTime,
        title: {
          display: true,
          text: 'Time',
        },
        grid: {
          display: false, // hide x-axis grid lines
        },

      },
      y: {
        type: 'linear',
        ticks: {
          // Set the step size to 1 to display only integer values
          stepSize: 1,
        },
        // min:100,
        // max:500,
        title: {
          display: true,
          text: 'Patient ID',
        },

        grid: {
          display: false, // hide x-axis grid lines
        },
      },
    },
    annotation: {
      annotations: [
        {
          type: "box",
          drawTime: "beforeDatasetsDraw",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          xMin: 2 + annotationPadding,
          xMax: 5 - annotationPadding,
          yMin: 4 + annotationPadding,
          yMax: 6 - annotationPadding,
          borderWidth: 2,
          backgroundColor: "rgba(255, 99, 132, 0.25)"
        },
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: 4.5,
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          xMin: 2 + annotationPadding,
          yMin: 4 + annotationPadding,
          borderColor: "transparent",
          backgroundColor: "transparent",
          label: {
            backgroundColor: "transparent",
            fontFamily: "sans-serif",
            fontSize: 12,
            fontStyle: "bold",
            fontColor: "red",
            xPadding: 12,
            yPadding: 6,
            cornerRadius: 4,
            position: "center",
            yAdjust: -100,
            enabled: true,
            content: "Test Label"
          }
        }
      ]
    }
  };

  const data = {
    datasets: [
      {

        label: "Nurses on duty",
        data: graphData

      }
    ]
  };
  useEffect(() => {
    if (date.toLocaleDateString('en-CA') === new Date().toLocaleDateString('en-CA')) {
      if (currentTime >= morningStart && currentTime <= morningEnd) {
        setServeTime('morning')
      } else if (currentTime >= afternoonStart && currentTime <= afternoonEnd) {
        setServeTime( 'afternoon')
      } else if (currentTime >= eveningStart && currentTime <= eveningEnd) {
        setServeTime('evening')
      } else if (currentTime >= nightStart && currentTime <= nightEnd) {
        setServeTime('night')
      }
    }
    else { setServeTime("night") }

    if (date !== "") {
      setDate1(date.toLocaleDateString('en-CA'))
    }
    else {
      setDate1("")
    }
    setStartTime(new Date(date).setHours(1, 0, 0, 0))
    setEndTime(new Date(date).setHours(24,0,0,0))
  }, [date])
  function getPrevDate(date1) {
    if (date1 === "") {
      setDate1(new Date().toLocaleDateString('en-CA'))
      setDate(new Date())
    }
    else {
      var yest = new Date(date1);
      yest.setDate(yest.getDate() - 1);
      setDate(yest)

    }


  }
  function getNextDate(date1) {
    var tomorrow = new Date(date1)
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDate(tomorrow)
  }

  return (
    <>
   
      <Container fluid style={{ paddingTop: "1%" }}>
        <div className='row' style={{ paddingBottom: "1%" }}>
          <div className="col-6 row ">
            {/* <div className="col-1"></div> */}
            <div className='col-1'><button onClick={() => { getPrevDate(date) }} style={{ background: "none" }} >
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="currentColor"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity=".2" /><path fill-rule="evenodd" d="m14.453 14l4.2-5.04c1.28-1.536-1.025-3.457-2.305-1.92l-4.957 5.948c-.256.272-.42.63-.418 1.012c-.002.382.162.74.418 1.012l4.957 5.948c1.28 1.537 3.585-.384 2.304-1.92z" clip-rule="evenodd" opacity=".2" /><path d="m15.384 18.68l-5-6l-.768.64l5 6z" /><path d="m14.616 19.32l-5-6c-.427-.512.341-1.152.768-.64l5 6c.427.512-.341 1.152-.768.64" /><path d="m14.616 6.68l-5 6l.768.64l5-6z" /><path d="m15.384 7.32l-5 6c-.427.512-1.195-.128-.768-.64l5-6c.427-.512 1.195.128.768.64" /><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd" /></g></svg></button></div>

            <div className=' col-4' style={{ textAlign:"center" ,fontSize: "18px" }}>
              {date1 === new Date().toLocaleDateString('en-CA') ? <span className='day-head'>Today</span>
                : <span className='day-head'>{date1}</span>}
            </div>


            <div className='col-1'><button onClick={() => { getNextDate(date) }} disabled={date1 === new Date().toLocaleDateString('en-CA')} style={{ background: "none" }}>{date1 === new Date().toLocaleDateString('en-CA') ? <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="currentColor"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity=".2" /><path d="M18.812 14.729a1.49 1.49 0 0 1-.177.252l-4.983 5.98a1.5 1.5 0 0 1-2.304-1.921l4.2-5.04l-4.2-5.04a1.5 1.5 0 1 1 2.304-1.92l5 6a1.5 1.5 0 0 1 .16 1.689" opacity=".2" /><path d="M10.116 7.32a.5.5 0 1 1 .768-.64l5 6a.5.5 0 0 1-.768.64z" /><path d="M10.884 19.32a.5.5 0 0 1-.768-.64l5-6a.5.5 0 1 1 .768.64z" /><path d="M4.15 4.878a.514.514 0 0 1 .728-.727l16.971 16.971a.514.514 0 0 1-.727.727z" /><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd" /></g></svg> :
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g fill="currentColor"><path fill-rule="evenodd" d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24" clip-rule="evenodd" opacity=".2" /><path d="M18.812 14.729a1.49 1.49 0 0 1-.177.252l-4.983 5.98a1.5 1.5 0 0 1-2.304-1.921l4.2-5.04l-4.2-5.04a1.5 1.5 0 1 1 2.304-1.92l5 6a1.5 1.5 0 0 1 .16 1.689" opacity=".2" /><path d="M10.116 7.32a.5.5 0 1 1 .768-.64l5 6a.5.5 0 0 1-.768.64z" /><path d="M10.884 19.32a.5.5 0 0 1-.768-.64l5-6a.5.5 0 1 1 .768.64z" /><path fill-rule="evenodd" d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5" clip-rule="evenodd" /></g></svg>}</button></div>
          </div>
        </div>




        <div className="row">
          <div className="col-11">
            <Row>
              <Col lg={(type==="medicine"||type==="food"||type==="liquid")?"3":"4"} sm="6">
                <Card className="card-stats " >
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
<circle cx="17" cy="17" r="16.25" fill="white" stroke="#36B73B" stroke-width="1.5"/>
<path d="M5.28125 18.2812L9.96875 22.9688M16.5312 15.4688L21.2188 10.7812M12.7812 18.2812L17.4688 22.9688L28.7188 10.7812" stroke="#36B73B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Completed</p>
                          <Card.Title as="h4">{completed}/{completedPat}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                   

                    <ProgressBar style={{ marginTop: "1%", width: "100%", height: "21px" }} variant="success" now={completedP} label={completedP + "%"} />

                  </Card.Footer>
                </Card>
              </Col>
              <Col lg={(type==="medicine"||type==="food"||type==="liquid")?"3":"4"} sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="darkblue" d="M6.998 13q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709Q7.418 11 7.002 11q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm5 0q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709q-.291-.293-.707-.293q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm5 0q.415 0 .709-.291q.293-.291.293-.707q0-.415-.291-.709q-.291-.293-.707-.293q-.415 0-.709.291q-.293.291-.293.707q0 .415.291.709q.291.293.707.293Zm-4.995 8q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924q-1.216-1.214-1.925-2.856Q3 13.87 3 12.003q0-1.866.708-3.51q.709-1.643 1.924-2.859q1.214-1.216 2.856-1.925Q10.13 3 11.997 3q1.866 0 3.51.708q1.643.709 2.859 1.924q1.216 1.214 1.925 2.856Q21 10.13 21 11.997q0 1.866-.708 3.51q-.709 1.643-1.924 2.859q-1.214 1.216-2.856 1.925Q13.87 21 12.003 21ZM12 20q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z" /></svg>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Pending</p>
                          <Card.Title as="h4">{pending}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                  

                    <ProgressBar style={{ marginTop: "1%", width: "100%", height: "21px" }} now={pendingP} label={pendingP + "%"} />

                  </Card.Footer>
                </Card>
              </Col>
              {(type==="medicine"||type==="food"||type==="liquid")&& <Col lg="3" sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 36 36"><path fill="#FFA534" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2Zm0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14Z" class="clr-i-outline clr-i-outline-path-1" /><path fill="#FFA534" d="M18.92 18.4v-7.65a1 1 0 0 0-2 0v8.72l5.9 4a1 1 0 1 0 1.11-1.66Z" class="clr-i-outline clr-i-outline-path-2" /><path fill="#FFA534" d="M8 17.94a9.94 9.94 0 0 1 15.41-8.35l.85-1.36a11.55 11.55 0 1 0-8.53 21L16 27.7a10 10 0 0 1-8-9.76Z" class="clr-i-outline clr-i-outline-path-3" /><path fill="none" d="M0 0h36v36H0z" /></svg>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Delayed</p>
                          <Card.Title as="h4">{delayedd}/{delayedPat}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                  
                    <ProgressBar style={{ marginTop: "1%", width: "100%", height: "21px" }} variant="warning" now={delayedP} label={delayedP + "%"} />

                  </Card.Footer>
                </Card>
              </Col>}
              <Col lg={(type==="medicine"||type==="food"||type==="liquid")?"3":"4"} sm="6">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="5">
                      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="16.25" fill="white" stroke="#ED0000" stroke-width="1.5"></circle>
<path d="M7.69999 15.11L15.29 22.7C15.68 23.09 16.31 23.09 16.7 22.7L23 16.4V20C23 20.55 23.45 21 24 21C24.55 21 25 20.55 25 20V14C25 13.45 24.55 13 24 13H18C17.45 13 17 13.45 17 14C17 14.55 17.45 15 18 15H21.59L16 20.59L9.10999 13.7C9.01748 13.6073 8.90759 13.5337 8.78662 13.4836C8.66564 13.4334 8.53596 13.4076 8.40499 13.4076C8.27402 13.4076 8.14434 13.4334 8.02337 13.4836C7.9024 13.5337 7.79251 13.6073 7.69999 13.7C7.31999 14.09 7.31999 14.73 7.69999 15.11Z" fill="#ED0000"></path>
</svg>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Missed</p>
                          <Card.Title as="h4">{missed}/{missedPat}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                  

                    <ProgressBar style={{ marginTop: "1%", width: "100%", height: "21px" }} variant="danger" now={missedP} label={missedP + "%"} />

                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Service Behavior</Card.Title>
                    <p className="card-category">24 Hours performance</p>
                  </Card.Header>
                  <Card.Body>  
                    <div  style={{ height: "350px", overflow: "auto", display:"flex", alignItems: "center" }}><Bubble data={data} options={options} /></div>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-history"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Body>                
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Medication Statistics</Card.Title>
                    <p className="card-category">24 Hours performance</p>
                  </Card.Header>
                  <Card.Body>
                  <div  style={{ height: "350px", overflow: "auto" }}>
                    <div
                      className="ct-chart ct-perfect-fourth"
                      id="chartPreferences"
                    >
                      {
                      total > 0 ? 
                      <MyPieChart completedP={completedP} missedP={missedP} delayedP={delayedP} pendingP={pendingP}  type={type}/>
                      
                      // <ChartistGraph
                      //   data={{
                      //     labels: [completedP > 0 ? (completedP + "%") : "", missedP > 0 ? (missedP + "%") : "",(type==="medicine"||type==="food"||type==="liquid")&& delayedP > 0 ? (delayedP + "%") : "", pendingP > 0 ? (pendingP + "%") : ""],
                      //     series: [completedP, missedP, (type==="medicine"||type==="food"||type==="liquid")&&delayedP, pendingP],
                      //   }}
                      //   options={{
                      //     showLabel: false,
                      //   }}
                      //    type="Pie"
                      // /> 
                      : 
                      <div style={{ textAlign: "center" }}><i style={{ fontSize: "41px" }} className="nc-icon nc-chart-pie-36" /><br /><p style={{ color: "red" }}>No data!!</p></div>}
                    </div>
                    <div className="legend">
                      <i style={{color:"#83f783"}} className="fas fa-circle "></i>
                      Completed <i className="fas fa-circle text-danger"></i>
                      Missed
                      {(type==="medicine"||type==="food"||type==="liquid")&&<><i className="fas fa-circle text-warning"></i>
                      Delayed</>}<i  className="fas fa-circle text-info"></i>Pending
                    </div></div>
                    <hr></hr>
                    <div className="stats">
                      <i className="far fa-clock"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

          </div>
          <div className="col-1" style={{ fontSize: "xx-small" }}>
            <div style={{ border: medFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("medicine"); }} className={type === "medicine" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M8.6 16.4a4.6 4.6 0 0 1-6.5-6.5l7.8-7.8a4.6 4.6 0 0 1 6.5 6.5M5.999 5.999l4.25 4.25" /><path d="M23.25 15.75a7.669 7.669 0 0 1-6 7.5a7.669 7.669 0 0 1-6-7.5V13.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.25Zm-6-.75v4.5M15 17.25h4.5" /></g></svg><br />Medicine</div><br />
            <div style={{ border: fdFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("food");  }} className={type === "food" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 3a1 1 0 0 1 .993.883L19 4v16a1 1 0 0 1-1.993.117L17 20v-5h-1a1 1 0 0 1-.993-.883L15 14V8c0-2.21 1.5-5 3-5Zm-6 0a1 1 0 0 1 .993.883L13 4v5a4.002 4.002 0 0 1-3 3.874V20a1 1 0 0 1-1.993.117L8 20v-7.126a4.002 4.002 0 0 1-2.995-3.668L5 9V4a1 1 0 0 1 1.993-.117L7 4v5a2 2 0 0 0 1 1.732V4a1 1 0 0 1 1.993-.117L10 4l.001 6.732a2 2 0 0 0 .992-1.563L11 9V4a1 1 0 0 1 1-1Z" /></svg><br />Food</div><br />
            <div style={{ border: lqFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("liquid");}} className={type === "liquid" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18.32 8H5.67l-.44-4h13.54M12 19a3 3 0 0 1-3-3c0-2 3-5.4 3-5.4s3 3.4 3 5.4a3 3 0 0 1-3 3M3 2l2 18.23c.13 1 .97 1.77 2 1.77h10c1 0 1.87-.77 2-1.77L21 2H3Z" /></svg><br />Fluid</div><br />
            <div style={{ border: conFlag && "2px solid red" }} onClick={() => {  setDate(new Date()); setType("consumables");}} className={type === "consumables" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M22.003 3v4.497A.503.503 0 0 1 21.5 8v0a.52.52 0 0 1-.466-.3A10 10 0 0 0 12.003 2c-5.185 0-9.449 3.947-9.95 9" /><path d="M17 10v5a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2m-5 1V8" /><path d="M2.05 21v-4.497c0-.278.226-.503.504-.503v0c.2 0 .38.119.466.3a10.001 10.001 0 0 0 9.03 5.7c5.186 0 9.45-3.947 9.952-9" /></g></svg><br />Consumables</div><br />
            <div style={{ border: testFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("test"); }} className={type === "test" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M2 23.5h20M8.646 15a4.5 4.5 0 1 0 4.262-7.408M5.124 15a7.519 7.519 0 0 0 4.84 4.22M6.5 9.5L8 11m-6 2.5h9m4.258-8.258l.712-.712a1.81 1.81 0 0 0 0-2.56l-.94-.94a1.81 1.81 0 0 0-2.56 0L7 6.5l3.5 3.5l2.408-2.408m2.35-2.35a7.503 7.503 0 0 1-1.222 13.978m1.222-13.978l-2.35 2.35M9.964 19.22a3.235 3.235 0 0 1 4.072 0m-4.072 0a3.233 3.233 0 0 0-.858 1.069L8.5 21.5s-1 2-3 2h13c-2 0-3-2-3-2l-.606-1.211a3.234 3.234 0 0 0-.858-1.069M15.5 1.5L16.6.4" /></svg><br />Test</div><br />
            <div style={{ border: scanFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("scan");}} className={type === "scan" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" d="M3.765 7A10.498 10.498 0 0 1 13 1.5c5.799 0 10.5 4.701 10.5 10.5S18.799 22.5 13 22.5A10.503 10.503 0 0 1 3.289 16M18 16H3.289M0 16h3.289M2 13h9.5v-1a3 3 0 0 0-3-3c-.54 0-4.692.437-8.4.84m13.4 1.56s1 1.6 2.25 1.6a1.75 1.75 0 0 0 1.75-1.75c0-.966-.784-1.746-1.75-1.746c-1.25 0-2.25 1.596-2.25 1.596z" /></svg><br />Scan</div><br />
            <div style={{ border: proFlag && "2px solid red" }} onClick={() => {  setDate(new Date());setType("procedure");}} className={type === "procedure" ? "icons-right" : "icons-right-not"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18.27 10.73q-.74 0-1.622-.46q-.883-.46-1.673-1.276q-.79-.815-1.251-1.698q-.46-.883-.46-1.634q0-.233.048-.4q.048-.168.144-.264q.534-.535 2.233-.907q1.7-.372 3.546-.354q.298.011.49.063q.19.052.294.156q.098.098.153.278t.066.46q.03 1.86-.32 3.56t-.891 2.24q-.115.116-.31.176t-.448.06m.51-2.326q.223-.68.334-1.594q.112-.914.156-2.08q-.98-.024-1.897.1q-.916.124-1.685.366q.525.296.97.617q.445.322.824.681q.442.423.77.904q.328.48.528 1.006m-.51 1.327q-.129-.596-.519-1.267q-.39-.67-.983-1.256q-.586-.587-1.231-.965q-.646-.378-1.242-.512q.05.575.425 1.29q.375.716.944 1.285q.6.6 1.303.987q.703.388 1.303.438m3.031 8.257L16.812 13.5h-10l-4.8-4.8l.688-.688L7.188 12.5h10l4.8 4.8zM8.5 20.981v-3.385q0-.671.472-1.143t1.143-.472h3.77q.67 0 1.143.472q.472.472.472 1.143v3.385zm1-1h5v-2.385q0-.27-.173-.442t-.442-.173h-3.77q-.269 0-.442.173t-.173.442zm0 0v-3z" /></svg><br />Procedure</div><br />
          </div>
          

            <NurseList />
         
        </div>


      </Container>
    </>
  );
}

export default Monitor;

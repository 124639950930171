import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import "./assets/css/style.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import flag from "./assets/img/flag/us-flag.png";
import logo1 from "../../assets/images/felixa_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import prod1 from "./assets/img/products-img1.jpg";
import prod2 from "./assets/img/products-img2.jpg";
import prod5 from "./assets/img/products-img5.jpg";
import NewArrivalProducrs from "./NewArrivalProducrs";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import { CartContext } from "./AddToCartContext";
import { deleteFavorite } from "./handleFav";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
// import { Fav } from "../patient/Context";
// import { FavDelete } from "../patient/Context";
import { Fav } from "./Context";
import { FavDelete } from "./Context";
import QuickView from "./QuickView";
import classes from "./favorite.module.css";
import { NavDropdown } from "react-bootstrap";
import service2 from "../../assets/images/2.png";
function PatientNav(cartData) {
  const { handleAddToCart } = useContext(CartContext);
  const { favAdded, setFavAdded } = useContext(Fav);
  const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
  const ref = useRef(null);
  const [display, setDisplay] = useState(false);
  const [favorite, setFavorite] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [searchItem, setSearchItem] = useState([]);
  const { cartItems, cartItems1 } = useContext(CartContext);
  const navigate = useNavigate();
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showCart, setShowCart] = useState(false);

  const [submitMsg, setSubmitMsg] = useState("");
  // const [cartItems1, setCatItems1] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [show, setShow] = useState(false);
  const [logindatas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
  );
  const alert = useAlert();
  const [showWishList, setShowWishList] = useState(false);
  const [checkId, setCheckId] = useState("");
  const [appName, setAppName] = useState();
  const [appLogo, setAppLogo] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteFav, setDeleteFav] = useState(false);
  const [logoFlag, setLogoFlag] = useState(false);
  let CartIDs = [];
  const handleLogout1 = () => {
    setLogoutConfirm(true);
  };
  useEffect(() => {
    setCartCount(logindatas ? cartItems1.length : cartItems.length);
  }, [cartItems, cartItems1]);

  //const localStorageKey1 = `myFavorite_${userId}`;
  ///const StoredValues1 = localStorage.getItem(localStorageKey1);
  const initialRender = useRef(true);
  let storedCartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
  useEffect(() => {
    axios.get(`${APIURL}/api/v1/account/customer-info/`).then((res) => {
      if (res.data.status === "success") {
        setAppName(res.data.message.app_name);
        setAppLogo(res.data.app_logo);
        if (
          res.data.app_logo !== APIURL + "/media/null" &&
          res.data.app_logo !== ""
        ) {
          setLogoFlag(true);
        }
        console.log(APIURL + "/media/null");
      }
    });
  }, []);

  useEffect(() => {
    //let totAmt = 0;
    if (logindatas) {
      const userId = sessionStorage.getItem("userid");
      console.log("userid", userId);
      const localStorageKey = `myFavorite_${userId}`;
      const StoredValues = localStorage.getItem(localStorageKey);
      if (StoredValues) {
        const favorites = JSON.parse(StoredValues);
        setFavorite(favorites);
        console.log("fav item no", favorites.length);
      } else {
        setFavorite(null);
      }
      setDeleteFav(false);
      setFavAdded(false);
    }
    
  }, [favDeletedList, favAdded, logindatas]);

  const updateFunctionCalled = () => {
    setDeleteFav(true);
    setFavDeletedList(true);
  };

  const deleteFavorites = (did) => {
    console.log("delete fav fun called");
    deleteFavorite(did);
    console.log("after delete fav fun");
    updateFunctionCalled();
  };
  //add to cart method call
  const addToCart = (item) => {
   // 
   console.log("inside add to card p:id is ",item.p_id);
    axios
      .get(
        `https://uniapi.cianlogic.com/api/v1/patient/hospital-services-detail/${item.p_id}/`
        
      )
      .then((res) => {
        if (res.status === 200) {
         console.log("api response data",res.data.data);
     
       handleAddToCart(res.data.data);
       console.log("after fucnction call");
        } else {
        }
      })
      .catch((error) => {
        
      });
   // handleAddToCart(item);
    
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }

    if (searchTerm !== "") {
      // const tokenString = sessionStorage.getItem("usertoken");

      // let v2 = tokenString.replace(
      //     /["]+/g,
      //     ""
      // ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(
          `${APIURL}/api/v1/service-provider/search/?search=${searchTerm}&is_active=true`
          // {
          //     headers: { Authorization: "Token " + v2 },
          // }
        )
        .then((res) => {
          if (res.status === 200) {
            setSearchList(res.data);
            setDisplay(true);
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);
  

  // const handleRefreshCart = () => {
  //   Cartpage();
  // };

  // useEffect(() => {
  //   if (logindatas) {
  //     Cartpage();
  //   }
  // }, [logindatas, cartData, refresh]);

  // const Cartpage = () => {
  //   const tokenString = sessionStorage.getItem("usertoken");

  //   let v2 = tokenString.replace(/["]+/g, "");
  //   axios
  //     .get(`${APIURL}/api/v1/patient/cart/`, {
  //       headers: { Authorization: "Token " + v2 },
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCatItems1(res.data.cart_items);
  //     })
  //     .catch((err) => {});
  // };

  CartIDs = cartItems1.map((item) => {
    return item.id;
  });
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  // const handleDelete = (item) => {
  //   

  //   // const tokenString = sessionStorage.getItem("usertoken");

  //   // let str = tokenString.replace(/["]+/g, "");

  //   axios
  //     .delete(
  //       `${APIURL}/api/v1/patient/cart-detail/${item}`
  //       //  {
  //       //     headers: {
  //       //         Authorization: "Token " + str,
  //       //         "Content-Type": "application/json",
  //       //     },

  //       // }
  //     )

  //     .then((res) => {
  //       if (res.status === 204) {
  //         alert.success("Deleted Successfully");
  //         // setModalShow(true);

  //         // setRefresh(!refresh)
  //         Cartpage();
  //       } else {
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value); // sets state to false to hide the overlay
  };

  const handleProceed = (item) => {
    if (item.length > 0) {
      const tokenStr = sessionStorage.getItem("usertoken");

      let v2 = tokenStr.replace(/["]+/g, "");

      const dataTosend = {
        cart_ids: CartIDs,
      };

      axios
        .post(`${APIURL}/api/v1/patient/cart-checkout/`, dataTosend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert.show("Please do the Payments to complete the order!!!");

            setCheckId(res.data.checkout_cart_id);
            navigate(
              `/checklist?checkout_cart_id=${res.data.checkout_cart_id}`
            );

            setModalShow(true);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => {
          setErrorMsg("Error in submission");
        });
    } else {
      alert.error("Cart is empty");
    }
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
         
          navigate("/webordering");
          refreshPage();
        } else {
        }
      })
      .catch((err) => {});
  };
  const handleLogout = () => {
    setLogoutConfirm(false);
    callLogout();

    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
  };

  const WishPopup = (props) => {
    console.log("fav datas ", favorite);
    function FavoriteItems({ fav }) {
      
      return (
        <div className={classes.card}>
          {favorite ? (
            <div className="products-cart d-flex align-items-center">
              <a
                  //href="#"
                  onClick={() => deleteFavorites(fav.p_id)}
                  className="remove-btn col-1"
                  style={{ marginBottom:"60px",marginLeft:"-15px",color:"red" }}
                >
                  <i className="bx bx-trash"></i>
                </a>
              <div
                className="products-image col-5"
                style={{ width: "130px", height: "100px", overflow: "hidden" }}
              >
                
                <a href="#">
                  {fav.p_category === "master_scan" || fav.p_category === "master_procedure" || fav.p_category === "master_labtest" || fav.p_category === "misc" || fav.service_category === "master_scan" || fav.service_category === "master_procedure" || fav.service_category === "master_labtest" || fav.service_category === "misc" ? (
                    <img
                      src={service2}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={fav.p_img || fav.image}
                      alt="image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </a>
              </div>
              <div className="products-content col-5">
                <h6>
                  <a href="#">{fav.p_name || fav.item_service_name ||fav.item_name}</a>
                </h6>
                <div className="products-price">
                  {/* <span>1</span>
                  <span>x</span> */}
                  <span className="price">
                    {" "}
                    ${fav.p_amount || fav.amount}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  className="my-button"
                   style={{ marginLeft: "0%" }}
                  onClick={() => addToCart(fav)}
                >
                  Add to Cart
                </Button>
              
                
              </div>
            </div>
          ) : (
            <p>No FavoriteItems listed</p>
          )}
        </div>
      );
    }
    return (
      <Modal
        style={{ marginLeft: "30%" }}
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-cart"
      >
        <Modal.Header closeButton>
          <h6>My Favorites ( {favorite.length} )</h6>
        </Modal.Header>
        <Modal.Body style={{ width: "96%" }}>
          <SimpleBar style={{ maxHeight: "500px" }}>
            <div className=" right shoppingWishlistModal">
              <div className="modal-dialog" role="document">
                <div
                  className="modal-content"
                  //  style={{width:"auto",height:"400px",overflow:"hidden",overflowY:"scroll",padding:"5px"}}
                >
                  {/* <div > */}
                  <div className="products-cart-content">
                    {favorite ? (
                      <div>
                        {favorite.map((fav, index) => (
                          <FavoriteItems key={index} fav={fav} />
                        ))}
                      </div>
                    ) : (
                      <p>No Favorite Item Selected</p>
                    )}
                  </div>
                  {/* </div> */}

                  {/* <div
                      style={{ marginLeft: "60%" }}
                      className="products-cart-subtotal"
                    >
                      <span>Subtotal </span>
  
                      <span className="subtotal">
                        <b>$ {totalAmt}</b>
                      </span>
                    </div> */}
                  {/* <br /> */}

                  {/* <div className="products-cart-btn">
                    <a href="#" className="default-btn">
                      View Shopping Cart
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </SimpleBar>
        </Modal.Body>
      </Modal>
    );
  };

  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={props.onHide}>
            ok
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  function QuickViewPopup(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}
        >
          <button
            style={{ backgroundColor: "white", marginLeft: "95%" }}
            variant="secondary"
            onClick={props.onHide}
          >
            <i
              class="fa fa-times-circle fa-2x"
              style={{ color: "#F0A639" }}
              aria-hidden="true"
            ></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <QuickView
            item={searchItem}
            // handleRefreshCart={handleRefreshCart}
            from="pn"
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    if (e.target.value === "") {
      setDisplay(false);
    }
    // document.body.addEventListener("click", bodyClickHandler);
  };
  const handleDetails = (item) => {
    setSearchItem(item);
    setDetailShow(true);
  };
  const handleOpen = () => {
    setShow(!show);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  const LogoutConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="modal-popout"
      >
       <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{margin:'20px auto', color:'#4e4e4e', fontSize:'18px'}}> Please confirm to logout </label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} onClick={handleLogout} >Confirm </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div className="web-ordering">
      <div className="top-header" style={{ background: "white" }}>
        <div className="">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-3"></div>

            <div className="col-lg-9 col-md-9">
              <ul
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  paddingRight: "3%",
                }}
                className="top-header-menu"
              >
                <li>
                  <i className="bx bx-phone-call"></i>{" "}
                  <a
                    style={{ fontSize: "13px", fontWeight: "500" }}
                    href="tel:+1234567898"
                  >
                    (+123) 456-7898
                  </a>
                </li>
                <li>
                <div  onClick={() => navigate("/appointment")} className="book-doctor"><i class="fa fa-stethoscope" aria-hidden="true"></i>{" "}Find My Doctor</div>
                </li>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div className="dropdown currency-switcher d-inline-block">
                    <span>
                      <b style={{ color: "#17a2b8" }}>
                        {logindatas ? (
                          <>
                            {" "}
                            <li   onClick={() => {navigate("/my-orders")}}>{logindatas.name.toUpperCase()}</li>{" "}
                            <li>
                              <button
                               
                                onClick={handleLogout1}
                                style={{ color: "#17a2b8", backgroundColor:"white" }}
                              >
                                Logout
                              </button>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <a
                                href="/loginpatient"
                                style={{
                                  fontSize: "12px",
                                  color: "#17a2b8",
                                  cursor: "pointer",
                                }}
                              >
                                <i 
                               
                                  class="fa fa-user-o"
                                  aria-hidden="true"
                                  style={{ marginTop: "1%", color: "#17a2b8" }}
                                ></i>{" "}
                                <span>&nbsp;&nbsp;</span>Login{" "}
                              </a>
                            </li>

                            <li>
                              <a
                                href="/registerpat"
                                style={{
                                  fontSize: "12px",
                                  marginTop: "2%",
                                  color: "#17a2b8",
                                  cursor: "pointer",
                                }}
                              >
                                Register
                              </a>
                            </li>
                          </>
                        )}
                      </b>
                    </span>
                  </div>
                </li>
                {/* <li><a href="#" onClick={handleLogout1} style={{color:"#17a2b8"}} >Logout</a></li> */}
              </ul>
            </div>
            <div
              className="drodo-nav"
              style={{
                height: "44px",
                backgroundColor: "#6BCBB8",
                marginTop: "85px",
                boxShadow: "10px 10px 10px rgba(0,0,0,0.3)",
                marginLeft: "1%",
              }}
            >
              <div className="">
                <nav className="navbar navbar-expand-md navbar-light ">
                  <div
                    className="navbar-collapse "
                    style={{ marginTop: "-8px" }}
                  >
                    <div
                      style={{
                        height: "48px",
                        border: "1px solid #94868630",
                        backgroundColor: "white",
                        paddingLeft: "0.5%",
                        marginTop: "-1.6px",
                        width: "280px",
                        marginLeft: "-12px",
                      }}
                    >
                      <Link to="/webordering">
                        <img
                          style={{
                            marginTop: "-14px",
                            boxShadow: "4px 4px 10px #999696a8",
                            borderRadius: "10px",
                            height: "75px",
                            width: "75px",
                            marginLeft: "2%",
                          }}
                          className="logo-img"
                          src={logoFlag ? appLogo : logo1}
                          alt="Logo"
                        />
                        &nbsp;&nbsp;&nbsp;
                        {appName ? (
                          <span
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "20px",
                              color: "#d89d6c",
                              fontWeight: "600",
                              marginLeft: "4px",
                            }}
                          >
                            {appName}
                          </span>
                        ) : (
                          <img className="logo-img1" src={logo2} alt="Logo" />
                        )}
                      </Link>
                    </div>
                    <ul
                      className="navbar-nav"
                      style={{ marginLeft: "6%", marginTop: "0.6%" }}
                    >
                      <li className="nav-item">
                        <a
                          href="/webordering"
                          className="nav-link "
                          style={{ fontSize: "16px", color: "white" }}
                        >
                          Home{" "}
                        </a>
                      </li>
                      <span>&nbsp;&nbsp;</span>

                      <li className="nav-item">
                        <a
                          href="/servicespatient"
                          className="nav-link "
                          style={{ fontSize: "16px", color: "white" }}
                        >
                          Services{" "}
                        </a>
                      </li>

                      <span>&nbsp;&nbsp;</span>

                      <span>&nbsp;&nbsp;</span>

                      <li className="nav-item">
                        <a
                          href="/contact"
                          className="nav-link"
                          style={{ fontSize: "16px", color: "white" }}
                        >
                          Contact
                        </a>
                      </li>

                      {/* <li className="nav-item">
                        <a
                          href="/createaccountuser"
                          className="nav-link"
                          style={{ fontSize: "16px", color: "white" }}
                        >
                          Skip
                        </a>
                      </li> */}
                    </ul>
                    <NavDropdown
                      ref={ref}
                      style={{ zIndex: "1001" }}
                      title={
                        <>
                          <div
                            className="search-webordering"
                            style={{
                              height: "38px",
                              marginTop: "-1px",
                              border: "1px solid #948686",
                              borderRadius: "40px",
                              backgroundColor: "white",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                marginTop: "0.8%",
                                marginLeft: "2%",
                                paddingRight: "1%",
                              }}
                            >
                              <input
                                onKeyDown={(e) => e.stopPropagation()}
                                type="text"
                                className=""
                                placeholder="Search for Medicines, Tests, Devices... "
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{
                                  width: "500px",
                                  paddingLeft: "10px",
                                  border: "none",
                                  fontSize: "15px",
                                }}
                              />
                              <div
                                style={{
                                  backgroundColor: "#fbb90d",
                                  paddingTop: "0.9%",
                                  paddingLeft: "1.5%",
                                  color: "white",
                                  borderRadius: "50%",
                                  height: "30px",
                                  width: "30px",
                                }}
                                className="search-btn1"
                              >
                                {" "}
                                <i className="fas fa-search"></i>{" "}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      id="collasible-nav-dropdown"
                      renderMenuOnMount={true}
                      onClick={handleOpen}
                      show={show}
                    >
                      <NavDropdown.Item
                        style={{
                          overflow: "scroll",
                          overflowX: "hidden",
                          overflowY: "auto",
                          borderRadius: "15px",
                          backgroundColor: "white",
                          maxHeight: "350px",
                          width: "528px",
                        }}
                      >
                        <div style={{}}>
                          {searchList.length > 0 ? (
                            searchList.map((item) => {
                              return (
                                <div className="list-item111 ">
                                  <Link
                                    to="/search-nav"
                                    state={{ details: item.id }}
                                    onClick={() => {
                                      setDisplay(false);
                                    }}
                                  >
                                    <span style={{ color: "#4f4d4d" }}>
                                      &nbsp;&nbsp;<b>{item.service}</b>
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        float: "right",
                                        marginTop: "3%",
                                        marginRight: "2%",
                                      }}
                                    >
                                      &nbsp;&nbsp;${item.amount}
                                    </span>
                                    <br />
                                    <small style={{ fontSize: "12px" }}>
                                      &nbsp;&nbsp;&nbsp;Vendor:&nbsp;{item.name}
                                    </small>{" "}
                                    <br />
                                  </Link>
                                </div>
                              );
                            })
                          ) : (
                            <h6 className="text-muted" style={{ padding: 10 }}>
                              No Items found !!
                            </h6>
                          )}
                        </div>
                      </NavDropdown.Item>
                    </NavDropdown>
                    &nbsp; &nbsp; &nbsp;
                    <div
                      style={{
                        border: "none",
                        marginRight: "20px",
                        paddingTop: "",
                      }}
                      className="others-option"
                    >
                      <div
                        onClick={() => setShowWishList(true)}
                        className="option-item"
                      >
                        <div className="cart-btn">
                          <div className="wishlist-btn">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#shoppingWishlistModal"
                            >
                              <i
                                style={{ fontSize: "20px", color: "white" }}
                                className="bx bx-heart"
                              ></i>
                              {/* <span>{favorite.length}</span> */}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="option-item">
                        <div className="cart-btn">
                          <a
                            href="/proceedpage"
                            data-toggle="modal"
                            data-target="#shoppingCartModal"
                          >
                            <i
                              style={{ fontSize: "20px", color: "white" }}
                              className="bx bx-shopping-bag"
                            ></i>
                            <span>{cartCount}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      {showWishList ? (
        <WishPopup
          show={showWishList}
          onHide={() => {
            setShowWishList(false);
          }}
        />
      ) : (
        ""
      )}
      {detailShow ? (
        <QuickViewPopup
          show={detailShow}
          onHide={() => {
            setDetailShow(false);
          }}
        />
      ) : (
        ""
      )}
      {logoutConfirm === true ? (
        <LogoutConfirmPopup
          show={logoutConfirm}
          onHide={() => {
            setLogoutConfirm(false);
          }}
        />
      ) : null}
      {submitMsg !== "" ? (
        <SubmitPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            // Cartpage();
            setSubmitMsg("");
          }}
        />
      ) : null}
    </div>
  );
}

export default PatientNav;

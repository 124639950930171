import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const MyPieChart = ({ completedP, missedP, delayedP, pendingP, type }) => {
  const data = {
    labels: [
      completedP > 0 ? `${completedP}%` : "",
      missedP > 0 ? `${missedP}%` : "",
      (type === "medicine" || type === "food" || type === "liquid") && delayedP > 0 ? `${delayedP}%` : "",
      pendingP > 0 ? `${pendingP}%` : ""
    ].filter(label => label), // Remove any empty labels

    datasets: [
      {
        data: [
          completedP || 0,
          missedP || 0,
          (type === "medicine" || type === "food" || type === "liquid") ? delayedP || 0 : 0,
          pendingP || 0,
        ],
        backgroundColor: ['#4caf50', '#FF4A55', '#ffc107', '#2196f3'], // Customize as needed
        hoverBackgroundColor: ['#66bb6a', '#ff4a55c9', '#ffd54f', '#42a5f5']
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  return <Pie data={data} options={options} />;
};

export default MyPieChart;

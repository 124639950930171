import React, { useImperativeHandle, useState, useEffect, useRef, forwardRef } from 'react';
import axios from "axios";
import { APIURL } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import moment from "moment";
import { useAlert } from 'react-alert';

const AddTest = forwardRef((props, ref) => {
    const [n, setN] = useState(1);
    const [currentIndex, setCurrentIndex] = useState("");
    const [testSuggest, setTestSuggest] = useState([]);
    const [searchTest, setSearchTest] = useState("");
    const [testRefresh, setTestRefresh] = useState(false);
    const [prevTest, setPrevTest] = useState([])
    const [testList, setTestList] = useState([
        {
            id: "",
            test_name: "",
            date: "",
            test_details: "",
            created_by: "",
            hospital_name: ""
        },
    ]);
    const alert = useAlert()
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas")) || null;
    const delConfirmRef = useRef(null);

    useEffect(() => {
        const handleKey = (e) => {
            if (e.altKey && e.key === 's') {
                e.preventDefault();
                delConfirmRef.current.click();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                delConfirmRef.current.click();
            } else if (e.altKey && e.key === 'c') {
                e.preventDefault();
                setTestRefresh(false);
            }
        };
        document.addEventListener("keydown", handleKey);
        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);

    useEffect(() => {
        const processPrevTestData = (testData) => {
            return testData.map(item => ({
                id: item.test,
                date: item.date,
            }));
        };

        const TestData = async () => {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            await axios
                .get(`${APIURL}/api/v1/doctor/tests-list/?patient_id=${props.id}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success" && res.data.message.length > 0) {
                        const prevTests = processPrevTestData(res.data.message);
                        setPrevTest(prevTests);
                    }
                });
        };

        TestData();
    }, []);

    const handleSubmitTest = () => {

        const isValid = testList.every(item => item.test_name && item.date);

        if (!isValid) {
            console.log(isValid)
            alert.error("Please add date for test");
        }
        else {
            props.onTestUpdate(testList);
        }

        return isValid
    };


    useEffect(() => {
        if (testList.length > 1) {
            props.setSelected('current');
        }
    }, [testRefresh]);

    useEffect(() => {
        if (searchTest !== '') {
            const tokenString = sessionStorage.getItem("usertoken")?.replace(/["]+/g, "");

            if (tokenString && (login_datas.user_type === "hospital_doctor" || login_datas.user_type === "hospital_allied_prof")) {
                axios.get(`${APIURL}/api/v1/doctor/laboratory-tests/?patient_id=${props.id}&search=${searchTest}`, {
                    headers: { Authorization: "Token " + tokenString }
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setTestSuggest(res.data.message);
                        }
                    });
            }
        }
    }, [searchTest]);

    const deleteRowTest = (index) => {
        if (index === 0 && testList.length === 1) {
            setTestList([{
                id: "",
                test_name: "",
                date: "",
                test_details: "",
                created_by: "",
                hospital_name: ""
            },]);
        } else {
            const newList = testList.filter((_, i) => i !== index);
            setTestList(newList);
            setN(n - 1)
        }
    };

    const renderEditableTest = (cellInfo) => (
        <div>
            <input
                style={{ backgroundColor: "#fafafa" }}
                value={testList[cellInfo.index][cellInfo.column.id]}
                className="form-control"
                onChange={(e) => {
                    const updatedList = [...testList];
                    updatedList[cellInfo.index].test_name = e.target.value;
                    setTestList(updatedList);
                    setSearchTest(e.target.value);
                    setCurrentIndex(cellInfo.index);
                    if (e.target.value === "") setTestSuggest([]);
                }}
                autoComplete="off"
            />
            {currentIndex === cellInfo.index && testSuggest.length > 0 && (
                <div className="suggestions-box">
                    {testSuggest.map((item, i) => (
                        <div key={i} className="test-item" onClick={() => {
                            const updatedList = [...testList];
                            updatedList[cellInfo.index].test_name = item.test_name;
                            updatedList[cellInfo.index].id = item.id;
                            setTestList(updatedList);
                            setTestSuggest([]);
                        }}>
                            <p className="search-item">{item.test_name}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const renderEditableTestDate = (cellInfo) => {
        const today = moment().format("YYYY-MM-DD");

        const checkForDuplicateTest = (testId, testDate) => {
            const duplicateInPrevTests = prevTest.some(
                (test) => test.id === testId && test.date === testDate
            );

            const duplicateInCurrentTests = testList.some(
                (test, index) =>
                    test.id === testId &&
                    test.date === testDate &&
                    index !== cellInfo.index
            );

            return duplicateInPrevTests || duplicateInCurrentTests;
        };

        return (
            <input
                style={{ fontSize: '14px', width: '100%', height: '44px', border: '1px solid #cccccc87', borderRadius: '4px' }}
                type="date"
                min={today}
                disabled={testList[cellInfo.index].id === ''}
                value={testList[cellInfo.index].date || ""}
                onChange={(e) => {
                    const updatedDate = e.target.value;
                    const updatedList = [...testList];
                    const testId = updatedList[cellInfo.index].id;

                    if (checkForDuplicateTest(testId, updatedDate)) {
                        updatedList[cellInfo.index].date = '';
                        setTestList(updatedList);
                        alert.error("A test with the same ID and date already exists.");
                        return;
                    }
                    else {
                        updatedList[cellInfo.index].date = updatedDate;
                        setTestList(updatedList);
                    }
                }}
            />
        );
    };


    const renderDetails = (cellInfo) => (
        <textarea
            className="text-area"
            placeholder="Add Test Details"
            value={testList[cellInfo.index].test_details || ""}
            onChange={(e) => {
                const updatedList = [...testList];
                updatedList[cellInfo.index].test_details = e.target.value;
                setTestList(updatedList);
            }}
            cols="100"
            rows="2"
        />
    );

    const AddRowTest = () => {
        setN(n + 1)
        const newList = [...testList, { id: "", test_name: "", date: "", test_details: "" }];
        setTestList(newList);
    };

    const columnsTests = [
        {
            Header: <i className="fa fa-trash"></i>,
            Cell: (props) => (
                <i
                    style={{ marginLeft: "30%" }}
                    className="fa fa-trash trash-btn"
                    onClick={() => deleteRowTest(props.index)}
                />
            ),
            width: 50,
            sortable: false,
            filterable: false,
        },
        {
            Header: "Test Name",
            accessor: "test_name",
            Cell: renderEditableTest,
            width: 250,
            sortable: false,
        },
        {
            Header: "Date (YYYY-MM-DD)",
            accessor: "date",
            Cell: renderEditableTestDate,
            sortable: false,
            width: 150,
        },
        {
            Header: "Test Details",
            accessor: "test_details",
            Cell: renderDetails,
            sortable: false,
        },
        {
            Header: "Add New",
            Cell: (props) => (
                props.index === testList.length - 1 && testList[props.index].id && (
                    <button className="btn btn-primary" onClick={AddRowTest}> + </button>
                )
            ),
            sortable: false,
            filterable: false,
        },
    ];

    useImperativeHandle(ref, () => ({
        handleSubmitTest
    }));

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ReactTable
                columns={columnsTests}
                data={testList}
                pageSize={n}
                showPagination={false}
                className="observation-table"
            />
            <br />
        </div>
    );
});

export default AddTest;

import React, { useState, useEffect, useRef } from 'react'
import './treat.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
function TreatPlan(props) {
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date())
    const [date1, setDate1] = useState(new Date().toLocaleDateString('en-CA'))
    const [morning, setMorning] = useState(true)
    const [afternoon, setAfternoon] = useState(false)
    const [evening, setEvening] = useState(false)
    const [night, setNight] = useState(false)
    const [item, setItem] = useState("")
    const [treatChart, setTreatChart] = useState([])
    const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
    useEffect(() => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(/["]+/g, "");

        axios
            .get(
                `${APIURL}/api/v1/doctor/treatment-plan/?patient_id=${props.id}&date=${date1}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                }
            )
            .then((res) => {

                if (res.data.status === "success") {
                    setTreatChart(res.data.treatment_plan)
                }

            })



    }, [date1]);

    useEffect(() => {
        if (date !== "") {
            setDate1(date.toLocaleDateString('en-CA'))
        }
        else {
            setDate1("")
        }

    }, [date])
    function getNextDate(date1) {
        var tomorrow = new Date(date1)
        tomorrow.setDate(tomorrow.getDate() + 1);
        setDate(tomorrow)

    }
    function getPrevDate(date1) {
        if (date1 === "") {
            setDate1(new Date().toLocaleDateString('en-CA'))
            setDate(new Date())
        }
        else {
            var yest = new Date(date1);
            yest.setDate(yest.getDate() - 1);
            setDate(yest)

        }


    }
    const displayAll = (time) => {
        return (<ul className='row' style={{width:"96%"}}>
            {treatChart["medicine"] &&treatChart["medicine"].length > 0 &&<h6 style={{ textDecoration: "underline"}}>Medicine</h6>}
            {treatChart["medicine"] && treatChart["medicine"].length > 0 && treatChart["medicine"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />
                        <small>{item.quantity}</small>
                    </span>
                )
            })}
            {treatChart["liquid"] &&treatChart["liquid"].length > 0 &&<h6 style={{ textDecoration: "underline"}}>Liquid</h6>}
            {treatChart["liquid"] && treatChart["liquid"].length > 0 && treatChart["liquid"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />
                        <small>{item.quantity}ml</small>
                    </span>
                )
            })}
           { treatChart["food"]&&treatChart["food"].length > 0 &&<h6 style={{ textDecoration: "underline" }}>Food</h6>}
            {treatChart["food"] && treatChart["food"].length > 0 && treatChart["food"].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />
                          <small>{item.quantity!==0&&item.quantity + "gram"}</small>{' '} {item.calorie!==0&&<small> {item.calorie + "cal"}</small>}
                    </span>
                )
            })}
            {treatChart["consumables"]&&treatChart["consumables"].length > 0 &&<h6 style={{ textDecoration: "underline" }}>Consumables</h6>}
            {treatChart["consumables"] && treatChart["consumables"].length > 0 && treatChart["consumables"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />
                        <small>{item.quantity}</small>
                    </span>
                )
            })}
           { treatChart["scan"]&&treatChart["scan"].length > 0 &&<h6 style={{ textDecoration: "underline" }}>Scan</h6>}
            {treatChart["scan"] && treatChart["scan"].length > 0 && treatChart["scan"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />

                    </span>
                )
            })}
            { treatChart["test"]&&treatChart["test"].length > 0 &&<h6 style={{ textDecoration: "underline" }}>Test</h6>}
            {treatChart["test"] && treatChart["test"].length > 0 && treatChart["test"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />

                    </span>
                )
            })}
            { treatChart["procedure"]&&treatChart["procedure"].length > 0 &&<h6 style={{ textDecoration: "underline" }}>Procedure</h6>}
            {treatChart["procedure"] && treatChart["procedure"].length > 0 && treatChart["procedure"].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />

                    </span>
                )
            })}</ul>
        )
    }
    const displayOthers = (type) => {
        return (<ul className='row'style={{width:"96%"}}>
         
            {treatChart[type] && treatChart[type].length > 0 && treatChart[type].map((item) => {
                return (
                    <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />

                    </span>
                )
            })}</ul>
        )
    }
    const display = (type, time) => {
        return (<ul className='row'style={{width:"96%"}}>
             {treatChart[type] && treatChart[type].length > 0 &&<h6 style={{ textDecoration: "underline",textTransform:"capitalize"}}>{type}</h6>}
            {treatChart[type] && treatChart[type].length > 0 && treatChart[type].map((item) => {
                return (
                    item.serv_time.includes(time) && <span style={{ marginBottom: "2%" }} className='col-4'><span style={{fontWeight:"700"}}>{item.name}</span><br />
                        {type === "liquid" ? <small>{item.quantity}ml</small> : type === "food" ?<> <small>{item.quantity!==0 && item.quantity + "gram"} </small>{" "}<small>{item.calorie!==0&& item.calorie+ "cal"}</small> </>: ""}
                    </span>
                )
            })}</ul>
        )
    }
    return (
        <div className='container' style={{ padding:"2%", backgroundColor:"rgba(88, 182, 222, 0.15)" }}>
            <div className='row' style={{paddingBottom:"1%"}}>
                <div className='col-6' style={{display:"flex"}}>
                    <div className='round-button'><button onClick={() => { getPrevDate(date) }} style={{ background: "none" }} disabled={date1 === new Date().toLocaleDateString('en-CA')}><i class="fa fa-angle-left" aria-hidden="true"></i></button></div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div onClick={() => { setItem("") }} className=' ' style={{alignContent: "center", alignItems: "center", textAlign: "center",paddingTop:"1%" }}>
                    {date1 === new Date().toLocaleDateString('en-CA') ? <span className='day-head'>Today</span>
                        : <span className='day-head'>{date1}</span>}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className=''>
                    <div className='round-button'><button onClick={() => { getNextDate(date) }} style={{ background: "none" }}><i class="fa fa-angle-right" aria-hidden="true"></i></button></div>
                </div>
                </div>
                <div className='col-3'></div>
               <div className='col-3'> <button disabled={login_datas.user_type !=="hospital_doctor" && login_datas.user_type !== "hospital_allied_prof"} onClick={() => { navigate(`/treat-plan/${props.id}`, { state: { cat:"medicine" } }) }} style={{ margin: "0px", backgroundColor: "rgba(43, 147, 160, 1)",color:"white" ,padding:"4%"}} className='treat-button'><b>Manage treatment plan</b></button></div>
            </div>

            <div className='row' style={{ paddingTop: "1%", display: "flex",backgroundColor:"white" }}>
                <div className='col-2'>
                    <button style={{  backgroundColor:item!=="consumables"&&item!=="others"&& morning && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&& morning && "white",border:"none",margin:"6%" }} onClick={() => { setMorning(true); setAfternoon(false); setEvening(false); setNight(false) }} className='treat-button'>Morning</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&afternoon && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&afternoon &&"white",border:"none",margin:"6%"  }} onClick={() => { setMorning(false); setAfternoon(true); setEvening(false); setNight(false) }} className='treat-button'>Afternoon</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&evening && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&evening && "white",border:"none",margin:"6%"  }} onClick={() => { setMorning(false); setAfternoon(false); setEvening(true); setNight(false) }} className='treat-button'>Evening</button>
                    <button style={{ backgroundColor: item!=="consumables"&&item!=="others"&&night && "rgb(60, 104, 158)",color:item!=="consumables"&&item!=="others"&&night&& "white",border:"none",margin:"6%"  }} onClick={() => { setMorning(false); setAfternoon(false); setEvening(false); setNight(true) }} className='treat-button'>Night</button>
                </div>

                <div className='col-3 treat-first-div treat-div'>
                    <button style={{paddingTop:"2%", color: item === "medicine" &&  "white", backgroundColor: item === "medicine" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("medicine") }} className='treat-button'>Medicines</button>
                    <button style={{ color: item === "liquid" &&  "white", backgroundColor: item === "liquid" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("liquid") }} className='treat-button'>Liquid</button>
                    <button style={{ color: item === "food" && "white",  backgroundColor: item === "food" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("food") }} className='treat-button'>Food</button>
                    <button style={{ color: item === "consumables" && "white",  backgroundColor: item === "consumables" && "rgb(60, 104, 158)" ,margin:"4%"}} onClick={() => { setItem("consumables") }} className='treat-button'>Consumables</button>
                    <button style={{ color: item === "others" && "white",  backgroundColor: item === "others" && "rgb(60, 104, 158)",margin:"4%" }} onClick={() => { setItem("others") }} className='treat-button'>Others</button>
                </div>

                <div className='col-7 treat-sec-div treat-div' >

                    <SimpleBar style={{ maxHeight: "200px" }}>
                        {item === "others" ?
                            <>{treatChart["scan"]&&treatChart["scan"].length > 0 &&<span ><h6 style={{ textDecoration: "underline" }}>Scan</h6>{displayOthers("scan")}</span>}<br />
                                {treatChart["test"]&&treatChart["test"].length > 0 &&<span><h6 style={{ textDecoration: "underline" }}>Test</h6>{displayOthers("test")}</span>}<br/>
                                {treatChart["procedure"]&&treatChart["procedure"].length > 0 &&<span><h6 style={{ textDecoration: "underline"}}>Procedure</h6>{displayOthers("procedure")}</span>}<br/>
                            </> :
                             item === "consumables" ?
                             <>{treatChart["consumables"]&&treatChart["consumables"].length > 0 &&<span ><h6 style={{ textDecoration: "underline"}}>Consumables</h6>{displayOthers("consumables")}</span>}<br />
                                 
                             </> :
                             morning ? (
                                item === "medicine" ? display("medicine", "morning")
                                    : item === "food" ? display("food", "morning")
                                        : item === "liquid" ? display("liquid", "morning")
                                           
                                                : displayAll("morning")) :
                                afternoon ? (
                                    item === "medicine" ? display("medicine", "afternoon")
                                        : item === "food" ? display("food", "afternoon")
                                            : item === "liquid" ? display("liquid", "afternoon")
                                               
                                                    : displayAll("afternoon")) :
                                    evening ? (
                                        item === "medicine" ? display("medicine", "evening")
                                            : item === "food" ? display("food", "evening")
                                                : item === "liquid" ? display("liquid", "evening")
                                                   
                                                        : displayAll("evening")) :
                                        night ? (
                                            item === "medicine" ? display("medicine", "night")
                                                : item === "food" ? display("food", "night")
                                                    : item === "liquid" ? display("liquid", "night")
                                                        
                                                            : displayAll("night")) : ""}
                    </SimpleBar>
                </div>
            </div>
        </div>

    )
}

export default TreatPlan

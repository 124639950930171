import React, { Component, useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Redirect } from "react-router";
//import "./pagination.css";
//import './userlist.css'
import "./admission.css";
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from "../../../Global";
import ZoomableImage from "./ZoomableImage";
import { Modal } from "react-bootstrap";
import { Link as Scroll } from "react-scroll";
import SimpleBar from "simplebar-react";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
//import HospDocNavbar from '../BackoffcieNav/BackofficeNavBar';
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
let lastPageCalled = 1
let rejlastPageCalled = 1
let admlastPageCalled = 1
function AdmissionPatient() {
  const [count, setCount] = useState({
    request: 0,
    rejected: 0,
    pending: 0,
  });
  const fileInputRef = useRef(null);
  let relativesData = [];
  const [searchQuery, setSearchQuery] = useState("");
  const [enquiryData, setEnquiryData] = useState([]);
  let navigate = useNavigate();
  const alert = useAlert();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);  
  const [hasNextPage, setHasNextPage] = useState(false)
  const [rejLoading, setRejLoading] = useState(false);  
  const [rejPage, setRejPage] = useState(1);
  const [rejHasNextPage, setRejHasNextPage] = useState(false)
  const [admLoading, setAdmLoading] = useState(false);  
  const [admPage, setAdmPage] = useState(1);
  const [admHasNextPage, setAdmHasNextPage] = useState(false)
  const [admissionList, setAdmissionList] = useState([])
  const [view1, setView1] = useState(false);
  const [approveShow, setApproveShow] = useState(true);
  const [consentView, setConsentView] = useState(false);
  const [qualityView, setQualityView] = useState(false);
  const [weaknessView, setWeaknessView] = useState(false);
  const [relativeView, setRelativeView] = useState(false);
  const [recommenderView, setRecommenderView] = useState(false);
  const [refererView1, setRefererView1] = useState(false);
  const [fileName1, setFileName1] = useState("Upload Image");
  const [refresh, setRefresh] = useState(false)
  const [rejectView, setRejectView] = useState(false);
  const [admissionView, setAdmissionView] = useState(false);
  const [img, setImg] = useState("");
  const [viewData, setViewData] = useState([]);
  const [peData, setPeData] = useState([]);
  const [peView, setPeview] = useState(false);
  const [viewData1, setViewData1] = useState([]);
  const [itemId, setItemId] = useState("");
  const [itemId1, setItemId1] = useState("");
  const [itemId2, setItemId2] = useState("");
  const [approvedList, setApprovedList] = useState([]);
  const [rejectList, setRejectList] = useState([]);
  const [viewType, setViewType] = useState("")
  const [user, setUser] = useState("");
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  let lastScrollTop = 0;
  let lastScrollLeft = 0;
  useEffect(() => {
    if(showConfirmation){
    closeConfirmationPopup();
    }
  }, [showConfirmation, closeConfirmationPopup]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = () => {
    setRelativeView(false);
    setQualityView(false);
    setWeaknessView(false);
    setRecommenderView(false);
    // setRefererView1(false);
    setConsentView(false);
    if (viewType === "primary1") {
      setPeview(true);
    } else {

      setView1(true);
    }
    setViewType("")

  };

  const handleButtonClick1 = () => {
    setPeview(true);
    setRefererView1(false);
  };

  const handleQualityView = (e) => {
    e.preventDefault();
    setQualityView(true);
    setView1(false);
  };

  const handleWeaknessView = (e) => {
    e.preventDefault();
    setWeaknessView(true);
    setView1(false);
  };
  const handleRelativeView = (e) => {
    e.preventDefault();
    setRelativeView(true);
    setView1(false);
  };


  const handleRecommenderView = (e) => {
    e.preventDefault();
    setRecommenderView(true);
    setView1(false);
  };
  const handleRefererView1 = (e) => {
    e.preventDefault();
    setRefererView1(true);
    //setView1(false)
    setPeview(false);
  };

  const handleClickRequest = (id) => {
    setItemId(id);
  };
  const handleEnvelopClick = (id) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPeData(res.data);
          setPeview(true);
        } else {
          alert.error(res.data.message);
        }
      });
  };
  const handleEnvelopClick1 = (id) => {
    setItemId1(id);
    setItemId2("");
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPeData(res.data);
          setPeview(true);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const handleConsentView = () => {

    if (viewType === "primary1") {
      setPeview(false);
    } else {

      setView1(false);
    }
    setConsentView(true);
  };

  // const formattedRelatives = JSON.stringify(JSON.parse(enquiryData[0].relatives));
  const EnquiryFormPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b> Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>
              <ListGroup style={{ textAlign: "left" }}
                className="list-group-flush"
              >
                <ListGroupItem>
                  <b>Enquiry Date </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].enquiry_date !== null && (enquiryData[0].enquiry_date).slice(0, 10)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(enquiryData[0].first_name)} {enquiryData[0].last_name && formatFirstName(enquiryData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}>
                    {" "}
                    {enquiryData[0].gender && formatFirstName(enquiryData[0].gender)}
                  </span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {enquiryData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].location && formatFirstName(enquiryData[0].location)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>
                    {enquiryData[0].province && formatFirstName(enquiryData[0].province)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Manager Recommendation </b>{" "}
                  <span style={{ float: "right" }}>
                    {enquiryData[0].mgr_recommend_enquiry && formatFirstName(enquiryData[0].mgr_recommend_enquiry)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Approved Trust User </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].approving_chairman_name && enquiryData[0].approving_chairman_name}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Trust Decision </b>{" "}
                  <span style={{ float: "right" }}>
                    {enquiryData[0].chairman_comment}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].city && formatFirstName(enquiryData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{enquiryData[0].state && formatFirstName(enquiryData[0].state)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>
                    {enquiryData[0].country && formatFirstName(enquiryData[0].country)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>
                    {enquiryData[0].pincode}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{enquiryData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].idcard_no}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Referrer </b>
                  <span style={{ float: "right" }}>{enquiryData[0].referer}</span>
                </ListGroupItem> */}

                <ListGroupItem>
                  <b>Field Findings </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].field_report && formatFirstName(enquiryData[0].field_report)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Post Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].post_care ? formatFirstName(enquiryData[0].post_care) : "No"}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Manager's Recommendation </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].manager_recommend}
                  </span>
                </ListGroupItem> */}

                <ListGroupItem>
                  <b>Primary Enquiry Status </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].pe_status && formatFirstName(enquiryData[0].pe_status)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Parent Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].parent_name && formatFirstName(enquiryData[0].parent_name)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Marital Status</b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].marital_status === true
                      ? "Yes"
                      : enquiryData[0].marital_status === false
                        ? "No"
                        : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Is Parent Alive </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_aliveparent === true
                      ? "Yes"
                      : enquiryData[0].is_aliveparent === false
                        ? "No"
                        : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Home </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].is_home === true
                      ? "Yes"
                      : enquiryData[0].is_home === false
                        ? "No"
                        : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Property </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_property
                      ? formatFirstName(enquiryData[0].own_property)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Land </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_land
                      ? formatFirstName(enquiryData[0].own_land)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Ration Card </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_rationcard === true ? "Yes" : " No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Own Income </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].own_income
                      ? formatFirstName(enquiryData[0].own_income)
                      : "No"}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Name </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].bystander_name && formatFirstName(enquiryData[0].bystander_name)}
                    {/* {enquiryData[0].bystander_name} */}

                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Bystander Contact </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].bystander_contact !== "undefined" ? enquiryData[0].bystander_contact : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Qualities </b>
                  <span style={{ float: "right" }}>
                    {/* {enquiryData[0].qualities} */}
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => handleQualityView(e)}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Weakness </b>
                  <span style={{ float: "right" }}>
                    {/* {enquiryData[0].qualities} */}
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => handleWeaknessView(e)}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Relatives</b>
                  <span style={{ float: "right" }}>
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => handleRelativeView(e)}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
  <b>Referer</b>
  <span style={{ float: "right" }}>
  <button style={{backgroundColor: "rgb(52, 104, 194)",
                                  color: "white",}} onClick={(e)=>handleRefererView(e)}> View</button>
  </span>
</ListGroupItem> */}
                <ListGroupItem>
                  <b>Recommendations</b>
                  <span style={{ float: "right" }}>
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => handleRecommenderView(e)}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Physical Support </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].physical_support === true
                      ? "Yes"
                      : enquiryData[0].physical_support === false
                        ? "No"
                        : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Police Case Any </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].police_case === true
                      ? "Yes"
                      : enquiryData[0].police_case === false
                        ? "No"
                        : ""}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Undergoing Treatment </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].undergoing_treatment && formatFirstName(enquiryData[0].undergoing_treatment)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Treatment Place </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].treatment_place && formatFirstName(enquiryData[0].treatment_place)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Care Details </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].care_details && formatFirstName(enquiryData[0].care_details)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Auto Biography </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].autobiography && formatFirstName(enquiryData[0].autobiography)}
                  </span>
                </ListGroupItem>
                {/* <ListGroupItem>
                  <b>Investigator </b>
                  <span style={{ float: "right" }}>
                    {enquiryData[0].investigator}
                  </span>
                </ListGroupItem> */}

                <ListGroupItem>
                  <b>Consent form</b>
                  <span style={{ float: "right" }}>
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => { handleConsentView(e); setViewType("consent") }}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>
                {/* {enquiryData[0].pe_report !== null && <ListGroupItem>
                  <b>Primary Form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("primary") }}> View</button>
                  </span>
                </ListGroupItem>} */}
                {enquiryData[0].enquiry_report !== null && <ListGroupItem>
                  <b>Enquiry Form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("enquiry") }}> View</button>
                  </span>
                </ListGroupItem>}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>

      </Modal>
    );
  };

  const handleEnquiryClick1 = (id) => {
    setItemId2(id);
    setItemId1("");
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEnquiryData(res.data);
          relativesData = JSON.parse(enquiryData[0].relatives);

          setView1(true);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const handleEnquiryClick = (id) => {
    setItemId2(id);
    setItemId1("");
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEnquiryData(res.data);
          setView1(true);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const handleReopen = (id, pe, en) => {
    if (pe === "rejected") {
      navigate("/primaryenquiryedit", { state: { itemId: id } });
    } else if (en === "rejected") {
      navigate("/enquiryedit", { state: { itemId: id } });
    } else if (en === "approved" && pe === "approved") {
      navigate("/primaryenquiryedit", { state: { itemId: id } });
    } else {
      alert.error("select correct input");
    }
  };

  const formatFirstName = (name) => {
    return name && name !== "" && name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const PePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "green", textAlign: "center" }}>
            <b>Primary Enquiry Details</b>
          </h4>
          <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
            <hr />

            <Card.Body>
              <ListGroup
                style={{ textAlign: "left" }}
                className="list-group-flush"
              >
                <ListGroupItem>
                  <b>Enquiry Number </b>
                  <span style={{ float: "right" }}>
                    {peData[0].pv_enquiry_number}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>PE Date </b>
                  <span style={{ float: "right" }}>
                    {peData[0].pe_date !== null && (peData[0].pe_date).slice(0, 10)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Name </b>
                  <span style={{ float: "right" }}>
                    {formatFirstName(peData[0].first_name)} {peData[0].last_name && formatFirstName(peData[0].last_name)}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Gender </b>{" "}
                  <span style={{ float: "right" }}> {peData[0].gender && formatFirstName(peData[0].gender)}</span>
                </ListGroupItem>

                <ListGroupItem>
                  <b>Date of Birth </b>
                  <span style={{ float: "right" }}> {peData[0].dob}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Mobile Number </b>
                  <span style={{ float: "right" }}>
                    {peData[0].mobile_number}{" "}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Location</b>
                  <span style={{ float: "right" }}>{peData[0].location && formatFirstName(peData[0].location)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Province </b>{" "}
                  <span style={{ float: "right" }}>{peData[0].province && formatFirstName(peData[0].province)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>City </b>{" "}
                  <span style={{ float: "right" }}>{peData[0].city && formatFirstName(peData[0].city)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Region </b>{" "}
                  <span style={{ float: "right" }}>{peData[0].state && peData[0].state !== "null" ? formatFirstName(peData[0].state) : ""}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Country </b>{" "}
                  <span style={{ float: "right" }}>{peData[0].country && formatFirstName(peData[0].country)}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Pincode </b>{" "}
                  <span style={{ float: "right" }}>{peData[0].pincode}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Email </b>
                  <span style={{ float: "right" }}>{peData[0].email}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card </b>
                  <span style={{ float: "right" }}>
                    {peData[0].idcard_type}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>ID Card Number </b>
                  <span style={{ float: "right" }}>{peData[0].idcard_no}</span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Manager Recommendation </b>
                  <span style={{ float: "right" }}>
                    {peData[0].manager_recommend && formatFirstName(peData[0].manager_recommend)}
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Referrer </b>
                  <span style={{ float: "right" }}>
                    <button
                      style={{
                        backgroundColor: "rgb(52, 104, 194)",
                        color: "white",
                      }}
                      onClick={(e) => handleRefererView1(e)}
                    >
                      {" "}
                      View
                    </button>
                  </span>
                </ListGroupItem>
                {peData[0].pe_report !== null && <ListGroupItem>
                  <b>Primary Form</b>
                  <span style={{ float: "right" }}>
                    <button style={{
                      backgroundColor: "rgb(52, 104, 194)",
                      color: "white",
                    }} onClick={(e) => { handleConsentView(e); setViewType("primary1"); setPeview(false) }}> View</button>
                  </span>
                </ListGroupItem>}
                {/* <ListGroupItem>
                <b>Field Findings </b>
                <span style={{ float: "right" }}>
                  {peData[0].field_report}
                </span>
              </ListGroupItem> */}
                {/* <ListGroupItem>
                <b>Manager's Recommendation </b>
                <span style={{ float: "right" }}>
                  {peData[0].manager_recommend}
                </span>
              </ListGroupItem> */}
              </ListGroup>

              {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
            </Card.Body>
          </Card>
        </Modal.Body>

      </Modal>
    );
  };

  const reader = new FileReader();
  const uploadImage = async (e) => {
    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    if (!file) {
      alert.error("Please Select an Image");
      if (key === "image") {
        setFileName1("Upload Image");
      }

      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      alert.error("Select valid image");
      if (key === "image") {
        setFileName1("Upload Image");
      }

      return false;
    }
    if (file.size > 10e6) {
      alert.error("Select valid image");
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setFileName1(file.name);
      };

      img.onerror = () => {
        if (key === "image") {
          setFileName1("Upload Image");
        }

        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };



  const handleClick = (e) => {
    
    e.preventDefault();
    setApproveShow(true);
    setRejectView(false);
    setAdmissionView(false)
    if(searchQuery===""){
      if(approvedList.length === 0){
        liability(1)
      }else if(hasNextPage){
        liability(page)
      }
    }    
  };
  const handleClick1 = (e) => {
    e.preventDefault();    
    setRejectView(true);
    setApproveShow(false);
    setAdmissionView(false)
    if(searchQuery===""){
      if(rejectList.length === 0){
        rejectCase(1)
      }else if(rejHasNextPage){
        rejectCase(rejPage)
      }
    }
  };
  const handleClick2 = (e) => {
    e.preventDefault();    
    setApproveShow(false);
    setRejectView(false);
    setAdmissionView(true)
    if(searchQuery===""){
      if(admissionList.length === 0){
        approveCase(1)
      }else if(admHasNextPage){
        approveCase(admPage)
      }
    }
  };
  useEffect(() => {
    liability(1)
  }, [refresh]);

  const approveCase = async (currentPage) => {

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setAdmLoading(true)
      try {
        const response = await axios.get(`${APIURL}/api/v1/service-provider/user-admission/?page=${currentPage}&per_page=10`, {
          headers: {
            Authorization: "Token " + str,
          },
        });

        if (response.data.status === "success") {
          const pageData = response.data.results.filter((item) => (
            item.admission_status === "pending"
          ));

          setAdmissionList((prevAdmissionList) => [...prevAdmissionList, ...pageData]);
          setAdmPage(currentPage + 1)
          setAdmHasNextPage(response.data.has_next)
          setCount(prevCount => ({
            ...prevCount,
            pending: response.data.total_records
          }));          
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    setAdmLoading(false)
  };

  const liability = async (currentPage) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    setLoading(true);
      try {
        const response = await axios.get(
          `${APIURL}/api/v1/service-provider/enquiry-status/?page=${currentPage}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        );

        if (response.status === 200) {
          const pageData = response.data.message
          setApprovedList((prevApprovedList) => [...prevApprovedList, ...pageData]);
          setPage(currentPage + 1)
          setHasNextPage(response.data.has_next)
          setCount(prevCount => ({
            ...prevCount,
            request: response.data.total_records
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false)
  };
  const handleScroll = (event) => {
    const scrollContainer = event.target;
    const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

    const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
    lastScrollTop = scrollTop;
    lastScrollLeft = scrollLeft;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 10; 

    if (isBottom && !loading && hasNextPage && isVerticalScroll && (page !== lastPageCalled) ) {
      liability(page);
      lastPageCalled = page;  
    }
};


useEffect(() => {
    const scrollContainer = document.getElementById('pendinglist');
    if (scrollContainer) {
        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }
}, [loading, page, hasNextPage]);

const handleRScroll = (event) => {
  const scrollContainer = event.target;
  const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

  const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
  lastScrollTop = scrollTop;
  lastScrollLeft = scrollLeft;

  const isBottom = scrollTop + clientHeight >= scrollHeight - 10; 

  if (isBottom && !rejLoading && rejHasNextPage && isVerticalScroll && (rejPage !== rejlastPageCalled) ) {
      rejectCase(rejPage)
      rejlastPageCalled = rejPage
  }
};


useEffect(() => {
  const scrollContainer = document.getElementById('rejectedlist');
  if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleRScroll);

      return () => {
          scrollContainer.removeEventListener('scroll', handleRScroll);
      };
  }
}, [rejLoading, rejPage, rejHasNextPage]);

const handleAScroll = (event) => {
  const scrollContainer = event.target;
  const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

  const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
  lastScrollTop = scrollTop;
  lastScrollLeft = scrollLeft;

  const isBottom = scrollTop + clientHeight >= scrollHeight - 10; 

  if (isBottom && !admLoading && admHasNextPage && isVerticalScroll && (admPage !== admlastPageCalled) ) {
      approveCase(admPage); 
      admlastPageCalled = admPage
  }else{
  }
};


useEffect(() => {
  const scrollContainer = document.getElementById('approvedlist');
  if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleAScroll);

      return () => {
          scrollContainer.removeEventListener('scroll', handleAScroll);
      };
  }
}, [admLoading, admPage, admHasNextPage]);

const onAction =() => {
  lastPageCalled = 1
  rejlastPageCalled = 1
  admlastPageCalled = 1
  setSearchQuery("")
  setApproveShow(true);
  setRejectView(false);
  setAdmissionView(false)
  setApprovedList([])
  setRejectList([])
  setAdmissionList([])
  setRefresh(!refresh)
}

  const rejectCase = async (currentPage) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    setRejLoading(true);
      try {
        const response = await axios.get(
          `${APIURL}/api/v1/service-provider/enquiry-status/?status=rejected&page=${currentPage}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        );

        if (response.status === 200) {
          const pageData = response.data.message
          setRejectList((prevRejectList) => [...prevRejectList, ...pageData]);
          setRejPage(currentPage + 1)
          setRejHasNextPage(response.data.has_next)
          setCount(prevCount => ({
            ...prevCount,
            rejected: response.data.total_records
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setRejLoading(false);
  };

  const QualityPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header>
          <h4 style={{ marginLeft: "40%" }}>Qualities</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F" }}>
          {enquiryData[0].qualities ? (
            <div style={{textAlign:'left'}}>
              {enquiryData[0].qualities.split(",").map((detail, index) => (
                <div key={index}>
                  {" "}
                  {index + 1}. {formatFirstName(detail.trim())}
                </div>
              ))}
            </div>
          ) : (
            <div>No qualities available</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const WeaknessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header>
          <h4 style={{ marginLeft: "40%" }}>Weakness</h4>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E4DD9F" }}>
          {enquiryData[0].weakness ? (
            <div style={{textAlign:'left'}}>
              {enquiryData[0].weakness.split(",").map((detail, index) => (
                <div key={index}>
                  {" "}
                  {index + 1}. {formatFirstName(detail.trim())}
                </div>
              ))}
            </div>
          ) : (
            <div>No weakness available</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const RelativePopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header>
          <h4 style={{ marginLeft: "32%" }}>Relatives Details</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#E4DD9F",
            textAlign: "center",
          }}
        >
          <div>
            {enquiryData[0].relatives &&
              enquiryData[0].relatives !== null &&
              enquiryData[0].relatives !== "[]" &&
              enquiryData[0].relatives !== undefined ? (
              <div style={{textAlign:'left'}}>
                {JSON.parse(enquiryData[0].relatives).map((relative, index) => (
                  <div key={index}>
                    <strong>Name:</strong> {relative.name && formatFirstName(relative.name)}
                    <br />
                    <strong>Age:</strong> {relative.age}
                    <br />
                    <strong>Relation:</strong> {relative.relation && formatFirstName(relative.relation)}
                    <br />
                    <strong>Job:</strong> {relative.job && formatFirstName(relative.job)}
                    <br />
                    <strong>Phone:</strong>{" "}
                    {relative.phone !== "91" ? relative.phone : ""}
                    <br />
                    **********************************************
                  </div>
                ))}
              </div>
            ) : (
              <div>No relatives data available</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RefererPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header>
          <h4 style={{ marginLeft: "32%" }}>Referrer Details</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign:"left"
          }}
        >
          <div>
            {peData[0].referer &&
              peData[0].referer !== null &&
              peData[0].referer !== undefined ? (
              <div  style={{textAlign:'left'}}>

                {
                  peData[0].referer.map((relative, index) => (
                    relative.name && relative.name !== "null" &&
                    <div key={index}>
                      <strong>Referrer Name:</strong> {relative.name !== "null" ? formatFirstName(relative.name) : ""}
                      <br />
                      <strong>Referrer Phone:</strong> {relative.contact !== "91" ? relative.contact : ""}
                      <br />
                      <strong>Referrer Comment:</strong> 
                      <div style={{ padding: "2%", backgroundColor: "#E4DD9F", borderRadius: "5px" }}>
                        {relative.comments && relative.comments.split(/\r?\n/).map((segment, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {segment}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ))}

              </div>
            ) :

              <div style={{ padding:"5px", backgroundColor: "#E4DD9F"}}>No referrer data available</div>}
            <div>{peData[0].referer &&
              peData[0].referer !== null &&
              peData[0].referer !== undefined && ((peData[0].referer.length === 1 && !peData[0].referer[0].name) ||
                (peData[0].referer.length === 1 && peData[0].referer[0].name === "null") || (peData[0].referer.length === 2 && !peData[0].referer[0].name && !peData[0].referer[1].name) || (peData[0].referer.length === 2 && peData[0].referer[0].name === "null" && peData[0].referer[1].name === "null")) ? <div>No referrer data available</div> : ""}</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick1()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const RecommenderPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header>
          <h4 style={{ marginLeft: "32%" }}>Recommender Details</h4>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#E4DD9F",
            textAlign: "center",
          }}
        >
          <div>
            {enquiryData[0].govt_member &&
              enquiryData[0].govt_member !== null &&
              enquiryData[0].govt_member !== "[]" &&
              enquiryData[0].govt_member !== undefined &&
              enquiryData[0].govt_member.length > 0 ? (
              <div style={{textAlign:'left'}}>
                {JSON.parse(enquiryData[0].govt_member).map(
                  (relative, index) => (
                    <div key={index}>
                      <strong>Recommender:</strong>{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {relative.referer}
                      </span>
                      <br />
                      <strong>Recommender Name:</strong> {relative.name && formatFirstName(relative.name)}
                      <br />
                      <strong>Recommender Phone:</strong>{" "}
                      {relative.phone !== "91" ? relative.phone : ""}
                      <br />
                      **********************************************
                    </div>
                  )
                )}
              </div>
            ) : (
              <div>No recommender data available</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const [isZoomed, setIsZoomed] = useState(false);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{ textAlign: "center" }}>
            <ZoomableImage src={viewType === "consent" ? enquiryData[0].consent_form : viewType === "primary" ? enquiryData[0].pe_report : viewType === "primary1" ? peData[0].pe_report : enquiryData[0].enquiry_report} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleZoomOut}>Zoom Out</Button>
        <Button variant="secondary" onClick={handleZoomIn}>Zoom In</Button> */}
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPopUp1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{ width: "95%", minHeight: "400px", paddingRight: "5%" }}
        >
          <div className="flex-row">
            <img
              src={viewData[0].enquiry_report}
              alt="Enquiry Report"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const AddPopUp2 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        style={{ minHeight: "450px" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          style={{ width: "95%", minHeight: "400px", paddingRight: "5%" }}
        >
          <div className="flex-row">
            <img
              src={viewData1[0].consent_form}
              alt="consent Report"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
if(searchQuery!==""){
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?search=${searchQuery}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(req => {
            return req.user === null
          })
          let data1 = res.data.filter(req => {
            return req.user !== null
          })

          const filteredData = data.length > 0 && data.filter(x => {
            return x.pe_status === "rejected" || x.enquiry_status === "rejected" || x.chairman_status === "rejected"

          })
          const filteredData1 = data.length > 0 && data.filter(x => {
            return x.pe_status === "pending" || (x.enquiry_status === "pending" && x.pe_status !== "rejected") || (x.chairman_status === "pending" && x.enquiry_status !== "rejected" && x.pe_status !== "rejected") || (x.chairman_status === null && x.enquiry_status !== "rejected" && x.pe_status !== "rejected")
          })
          const filteredData2 = data1.length > 0 && data1.filter(x => {
            return x.admission_status === "pending"
          })
          setApprovedList(filteredData1===false?[]:filteredData1)
          setRejectList(filteredData===false?[]:filteredData)

          setAdmissionList(filteredData2===false?[]:filteredData2)

          if (filteredData1.length > 0) {
            setApproveShow(true);
            setRejectView(false);
            setAdmissionView(false)
          }

          else if (filteredData.length > 0) {
            setApproveShow(false);
            setRejectView(true);
            setAdmissionView(false)
          }
          else if (admissionList.length > 0) {
            setApproveShow(false);
            setRejectView(false);
            setAdmissionView(true)
          }

          else {
            setApproveShow(true);
            setRejectView(false);
            setAdmissionView(false)
          }


        }
        else {
          alert.error(res.data.message)
        }
      });
    }
  }, [searchQuery]
  )

  return (
    <>
      <div className="facilityouter container" style={{ marginTop: "1%", maxWidth: "100%" }}>
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <h4
              style={{
                color: "#245693",
                marginTop: "1.5em", // Using relative unit
                fontFamily: "inter",
                fontWeight: "700",
                marginLeft: "2.5%", // Using relative unit
              }}
            >
              Admissions
            </h4>
            <div className="leftsidewindow" style={{ maxWidth: "100%", padding: "10px", height: "300px" }}>
              <div className="searchboxindside" style={{ overflow: "hidden", display: "flex", alignItems: "center" }}>
                <input
                  type="text"
                  style={{
                    border: "0",
                    outline: "none",
                    width: "100%",
                    paddingLeft: "10px"
                  }}
                  value={searchQuery}
                  onChange={(e) => { if (e.target.value === "") { onAction() } setSearchQuery(e.target.value) }}
                  placeholder="Name/PV Id"
                />
                <i className="fa fa-search" aria-hidden="true" style={{ marginright: "10px" }}></i>
              </div>
              <h6
                style={{
                  color: "#2D3748",
                  fontFamily: "Helvetica",
                  fontWeight: "700",
                  marginLeft: "1em", // Using relative unit
                  marginTop: "5px",
                  fontSize: "0.75em", // Using relative unit
                }}
              >
              </h6>
              <button
                className="approverequset"
                onClick={(e) => handleClick(e)}
                style={{
                  backgroundColor: approveShow ? "#6D4482" : "#F8F8F8",
                  color: approveShow ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%",
                }}
              >
                {" "}
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{ color: approveShow ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Approved / Pending 
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{approvedList.length>0?approvedList.length:"NIL"}</span>}
              </button>
              <button
                className="rejectedrequests"
                onClick={(e) => handleClick1(e)}
                style={{
                  backgroundColor: rejectView ? "#6D4482" : "#F8F8F8",
                  color: rejectView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"

                }}
              >
                {" "}
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{ color: rejectView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Rejected Requests
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{rejectList.length>0?rejectList.length:"NIL"}</span>}
              </button>
              <button
                className="rejectedrequests"
                onClick={(e) => handleClick2(e)}
                style={{
                  backgroundColor: admissionView ? "#6D4482" : "#F8F8F8",
                  color: admissionView ? "white" : "black",
                  fontSize: "0.8em",
                  width: "100%"

                }}
              >
                {" "}
                <i
                  className="fa fa-file"
                  aria-hidden="true"
                  style={{ color: admissionView ? "white" : "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Admission Pending
                {searchQuery!==""&&<span style={{float:"right",width:"15%"}}>|&nbsp;{admissionList.length>0?admissionList.length:"NIL"}</span>}
              </button>
              <button
                className="rejectedrequests"
                onClick={() => navigate("/prmary-enquiry")}
                style={{
                  fontSize: "0.8em",
                  width: "100%"
                }}
              >
                {" "}
                <i
                  className="fa fa-user"
                  aria-hidden="true"
                  style={{ color: "#6D4482" }}
                ></i>{" "}
                &nbsp;&nbsp;Create Primary Enquiry
              </button>
            </div>
          </div>

          <div className="col-lg-10 col-md-10">
            <h5
              style={{
                color: "red",
                marginLeft: "10%",
                marginTop: "10px",
                fontFamily: "inter",
                fontSize: "18px",
                fontStyle: "italic",
                fontWeight: "600",
                textAlign: "right",
              }}
            >
              {approveShow && approvedList.length > 0 && (
                <div>
                  {`${count.request} ${count.request === 1 ? "request" : "requests"}`}
                </div>
              )}

              {/* Conditionally display rejected requests for rejectList */}
              {rejectView && count.rejected > 0 && (
                <div>
                  {`${count.rejected} ${count.rejected === 1
                      ? "rejected request"
                      : "rejected requests"
                    } pending`}
                </div>
              )}
              {admissionView && count.pending > 0 && (
                <div>
                  {`${count.pending} ${count.pending === 1 ? "request is" : "requests are"
                    } pending`}
                </div>
              )}
              {/* Display "No pending requests" if both approvedList and rejectList are empty */}
              {approveShow && approvedList.length === 0 && (
                <div>No pending requests</div>
              )}
              {rejectView && rejectList.length === 0 && (
                <div>No rejected requests found</div>
              )}
              {admissionView && admissionList.length === 0 && (
                <div>No admission requests found</div>
              )}
            </h5>

            <div id="pendinglist" style={{ maxHeight: "800px",overflow: "auto" }}>
              {approveShow && <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "41%",
                  }}
                >
                  Primary
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "18%",
                  }}
                >
                  Enquiry
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "15%",
                  }}
                >
                  Status
                </h6>
              </div>
              }
              {approveShow &&
                approvedList.length>0&&approvedList

                  //         .filter((item) => {
                  //   // Replace 'fullName' with the property that contains the patient's full name
                  //   const fullName = `${item.first_name} ${item.last_name}`;
                  //   const idString = item.pv_enquiry_number.toString();
                  //   return fullName.toLowerCase().includes(searchQuery.toLowerCase()) || // Search by name
                  //   idString.includes(searchQuery) // Search by id
                  // })
                  .map((item) => (
                    <div
                      className="row" onClick={() => setUser(item.pv_enquiry_number)}
                      style={{ display: "flex", width: "62rem" }}
                    >
                      <div
                        className="patientlistview"
                        style={{
                          border: user === item.pv_enquiry_number && "1px solid #6D4482",
                          display: "flex",
                          marginBottom: "1%",
                          marginLeft: "2%"

                        }}
                      >
                        <div className="col-4">
                          <h6
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontFamily: "inter",
                              marginTop: "10px",
                              marginLeft: "20px",
                              justifyContent: "center",
                              textTransform: "capitalize",
                              fontSize: "16px",
                              
                            }}
                          >
                            {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span><br/>
                          </h6>
                        </div>
                        {/* <div className="col-2"><i class="fa fa-file" aria-hidden="true" style={{marginTop:'30px',cursor:"pointer"}}
     //onClick={()=>handleViewDetails(item.pv_enquiry_number)}
     >

     </i>
              </div> */}
                        <div className="col-3" >
                          {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                          <div
                            style={{
                              width: "123px",
                              height: "40px",
                              border: "1px solid grey",
                              display:'flex',
                              borderRadius: "5px",
                              marginTop: "20px",
                              marginLeft: "20px",
                            }}
                          >
                            {item.pe_status === "pending" ? (
                              <>
                                  <div className="col-6 eye-content"
                                    style={{ borderRight: '1px solid #ccc', padding: 0 }}
                                  >
                                      <i
                                        class="fa fa-envelope"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "28px",
                                          color: "#4F87CB",
                                          width:'100%'
                                        }}
                                      ></i>
                                  </div>
                                  <div className="col-6 eye-content"
                                    style={{ cursor: "pointer", padding: 0 }}
                                    onClick={() => handleEnvelopClick(item.pv_enquiry_number)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="28"
                                      viewBox="0 0 32 32"
                                      style={{width:'100%'}}
                                    >
                                      <circle
                                        cx="16"
                                        cy="16"
                                        r="4"
                                        fill="#245693"
                                      />
                                      <path
                                        fill="#245693"
                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                      />
                                    </svg>
                                  </div>
                              </>
                            ) : item.pe_status === "approved" ? (
                              <>
                                <div className="col-6 eye-content"
                                  style={{ padding: 0, borderRight: '1px solid #ccc'}}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="28"
                                    fill="#5ECB5B"
                                    class="bi bi-envelope-check-fill"
                                    viewBox="0 0 16 16"
                                    style={{width:'100%'}}
                                  >
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                  </svg>
                                </div>
                                <div className="col-6 eye-content"
                                  style={{  cursor: "pointer", padding: 0 }}
                                  onClick={() => handleEnvelopClick(item.pv_enquiry_number)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="28"
                                    viewBox="0 0 32 32"
                                    style={{width:'100%'}}
                                  >
                                    <circle
                                      cx="16"
                                      cy="16"
                                      r="4"
                                      fill="#245693"
                                    />
                                    <path
                                      fill="#245693"
                                      d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                                  fill="red"
                                />
                              </svg>
                            )}
                          </div>
                        </div>


                        <div className="col-3" style={{}}>
                          <div
                            style={{
                              width: "123px",
                              height: "40px",
                              border: "1px solid #CDCDCD",
                              borderRadius: "5px",
                              marginTop: "23px",
                              marginLeft: "20px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.enquiry_status === null ? (
                              <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                                style={{
                                  fontSize: "23px",
                                  fontWeight: "900",
                                  color: "#852ea0",
                                  width: "44px",
                                  height: "20px",
                                  margin:'auto',
                                  cursor:
                                    item.pe_status !== "approved"
                                      ? "not-allowed"
                                      : "pointer", // Change cursor style
                                }}
                                onClick={() => {
                                  if (item.pe_status === "approved" && item.chairman_status === null) {
                                    navigate("/enquiry", {
                                      state: { itemId: item.pv_enquiry_number },
                                    });
                                  }
                                  else if (item.pe_status === "approved" && item.chairman_status === "pending") {
                                    navigate("/enquiryedit", {
                                      state: { itemId: item.pv_enquiry_number },
                                    });
                                  }
                                }}
                              ></i>
                            ) : item.enquiry_status === "pending" ? (
                              <>
                              <div className="col-6 eye-content">
                                    <i
                                      class="fa fa-envelope"
                                      aria-hidden="true"
                                      style={{
                                        width:'100%',
                                        fontSize: "28px",
                                        color: "#4F87CB",
                                        borderRight: '1px solid #ccc',
                                      }}
                                    ></i>
                                </div>                               
                                <div className="col-6 eye-content"
                                  style={{padding: 0, cursor: "pointer" }}
                                  onClick={() => handleEnquiryClick(item.pv_enquiry_number)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="28"
                                    viewBox="0 0 32 32"
                                    style={{width:'100%'}}
                                  >
                                    <circle
                                      cx="16"
                                      cy="16"
                                      r="4"
                                      fill="#245693"
                                    />
                                    <path
                                      fill="#245693"
                                      d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                    />
                                  </svg>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-6 eye-content" style={{borderRight: '1px solid #ccc', padding:0}}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="25"
                                    fill="#5ECB5B"
                                    class="bi bi-envelope-check-fill"
                                    viewBox="0 0 16 16"
                                    style={{width:'100%'}}
                                  >
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                  </svg>
                                </div>
                                <div className="col-6 eye-content"
                                  style={{padding:0,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEnquiryClick(item.pv_enquiry_number)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="28"
                                    viewBox="0 0 32 32"
                                    style={{width:'100%'}}
                                  >
                                    <circle
                                      cx="16"
                                      cy="16"
                                      r="4"
                                      fill="#245693"
                                    />
                                    <path
                                      fill="#245693"
                                      d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                    />
                                  </svg>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-2">
                          {item.pe_status === "approved" &&
                            item.enquiry_status === "approved" ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",

                              }}
                            >
                              Waiting for trust decision
                            </h6>
                          ) : item.pe_status === "approved" &&
                            item.enquiry_status === "pending" ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",
                              }}
                            >
                              Waiting for enquiry report approval
                            </h6>
                          ) : item.pe_status === "approved" &&
                            item.enquiry_status === null ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",
                              }}
                            >
                              {" "}
                              Enquiry form is not submitted
                            </h6>
                          ) : item.pe_status === "pending" ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",
                              }}
                            >
                              Primary form is not approved
                            </h6>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
            <div id = "rejectedlist" style={{ maxHeight: "800px", overflow:"auto"}}>
              {rejectView && <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "41%",
                  }}
                >
                  Primary
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "18%",
                  }}
                >
                  Enquiry
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "15%",
                  }}
                >
                  Reopen
                </h6>
              </div>
              }

              {rejectView &&rejectList.length>0&&
                rejectList.map((item) => (
                  <div
                    onClick={() => setUser(item.pv_enquiry_number)}
                    className="row"
                    style={{ display: "flex", width: "62rem" }}
                  >
                    <div
                      className="patientlistview"
                      style={{ border: user === item.pv_enquiry_number && "1px solid #6D4482", display: "flex", marginLeft: "2%", marginBottom: '1%' }}
                    >

                      <div className="col-4">
                        <h6
                          style={{
                            color: "black",
                            fontWeight: "700",

                            fontFamily: "inter",
                            marginTop: "10px",
                            marginLeft: "20px",
                            justifyContent: "center",
                            textTransform: "capitalize",
                            fontSize: "16px",
                          }}
                        >
                          {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span><br/>

                        </h6>
                      </div>
                      {/* <div className="col-2"><i class="fa fa-file" aria-hidden="true" style={{marginTop:'30px',cursor:"pointer"}}
     //onClick={()=>handleViewDetails(item.pv_enquiry_number)}
     >

     </i>
              </div> */}
                      <div className="col-3" style={{}}>
                        {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                        <div
                          style={{
                            width: "123px",
                            height: "40px",
                            border:
                              itemId1 === item.pv_enquiry_number
                                ? "1px solid blue"
                                : "1px solid grey",
                            borderRadius: "5px",
                            marginTop: "20px",
                            marginLeft: "20px",
                            cursor: "pointer",
                          }}
                        >
                          {item.pe_status === "pending" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="21"
                              viewBox="0 0 22 21"
                              fill="none"
                            >
                              <path
                                d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                                fill="grey"
                              />
                            </svg>
                          ) : item.pe_status === "approved" ? (
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div style={{ marginTop: "4%" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="#5ECB5B"
                                  class="bi bi-envelope-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>{" "}
                              </div>{" "}
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnvelopClick1(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : item.pe_status === "rejected" ? (
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div style={{ marginTop: "4%" }}>
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="red"
                                  class="bi bi-envelope-x-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                </svg>
                              </div>
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnvelopClick1(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : ""}
                        </div>
                      </div>

                      <div className="col-3" style={{}}>
                        <div
                          style={{
                            flexDirection: "column",
                            width: "123px",
                            height: "40px",
                            border:
                              itemId2 === item.pv_enquiry_number
                                ? "1px solid blue"
                                : "1px solid grey",

                            borderRadius: "5px",
                            marginTop: "23px",
                            marginLeft: "20px",
                            textAlign: "center",
                            display: "flex",

                            alignItems: "center",
                          }}
                        >
                          {item.enquiry_status === null ? (
                            <i
                              class="fa fa-plus-circle"
                              aria-hidden="true"
                              style={{
                                fontSize: "23px",
                                fontWeight: "900",
                                color: "#852ea0",
                                width: "44px",
                                cursor: "pointer",
                                height: "20px",
                                margin:'auto',
                                cursor:
                                  item.enquiry_status === null
                                    ? "not-allowed"
                                    : "pointer", // Change cursor style
                              }}
                              onClick={() => {
                                if (item.enquiry_status !== null)
                                  navigate("/enquiry", {
                                    state: { itemId: item.pv_enquiry_number },
                                  });
                              }}
                            ></i>
                          ) : item.enquiry_status === "pending" ? (
                            <div style={{ display: "flex" }}>
                              {" "}
                              <div style={{ marginTop: "4%" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="grey"
                                  marginTop="20"
                                  class="bi bi-envelope-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>
                              </div>
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnquiryClick1(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : item.enquiry_status === "rejected" ? (
                            <div style={{ display: "flex" }}>
                              <div style={{ marginTop: "4%" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="red"
                                  class="bi bi-envelope-x-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                </svg>
                              </div>
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnquiryClick1(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "5px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="#5ECB5B"
                                  class="bi bi-envelope-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>{" "}
                              </div>
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnquiryClick1(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Buttons for approval */}

                      <div className="col-2">
                        <button
                          onClick={() =>
                            handleReopen(
                              item.pv_enquiry_number,
                              item.pe_status,
                              item.enquiry_status
                            )
                          }
                          style={{
                            width: "100px",
                            height: "40px",
                            borderRadius: "5px",
                            color: "red",

                            backgroundColor: "#FF8A00",
                            cursor: "pointer",
                            marginTop: "20px",
                          }}
                        >
                          <i
                            class="fa fa-refresh"
                            aria-hidden="true"
                            style={{ color: "white", fontSize: "18px" }}
                          ></i>
                        </button>
                      </div>
                      <div className="col-2" style={{}}>
                        {item.pe_status === "approved" &&
                          item.enquiry_status === "approved" &&
                          item.chairman_status === "rejected" ? (
                          <>
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "30px",
                                fontFamily: "inter",
                                fontStyle: "italic",
                                marginLeft: "10px",
                                color: "red",
                              }}
                            >
                              *Trust rejected!!
                            </h6>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ))}{" "}
            </div>
            <div id = "approvedlist" style={{ maxHeight: "800px",overflow: "auto" }}>
              {admissionView && <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "41%",
                  }}
                >
                  Primary
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "18%",
                  }}
                >
                  Enquiry
                </h6>
                <h6
                  style={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#6D4482",
                    marginLeft: "15%",
                  }}
                >

                </h6>
              </div>
              }
              {admissionView &&
                admissionList.length>0&&admissionList


                  .map((item) => (
                    <div
                      className="row" onClick={() => setUser(item.pv_enquiry_number)}
                      style={{ display: "flex", width: "62rem" }}
                    >
                      <div
                        className="patientlistview"
                        style={{
                          border: user === item.pv_enquiry_number && "1px solid #6D4482",
                          display: "flex",
                          marginBottom: "1%",
                          marginLeft: "2%"

                        }}
                      >
                        <div className="col-4">
                          <h6
                            style={{
                              color: "black",
                              fontWeight: "700",
                              fontFamily: "inter",
                              marginTop: "10px",
                              marginLeft: "20px",
                              justifyContent: "center",
                              textTransform: "capitalize",
                              fontSize: "16px",

                            }}
                          >
                            {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : ''}`} {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ''}`}<br /><span style={{ fontSize: "x-small", color: "#207170" }}><b>{item.pv_enquiry_number}</b></span><br/>
                          </h6>
                        </div>
                        {/* <div className="col-2"><i class="fa fa-file" aria-hidden="true" style={{marginTop:'30px',cursor:"pointer"}}
     //onClick={()=>handleViewDetails(item.pv_enquiry_number)}
     >

     </i>
              </div> */}
                        <div className="col-3" >
                          {/* <i class="fa fa-file" aria-hidden="true"></i> */}
                          <div
                            style={{
                              width: "123px",
                              height: "40px",
                              border: "1px solid grey",

                              borderRadius: "5px",
                              marginTop: "20px",
                              marginLeft: "20px",
                            }}
                          >

                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "5px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="28"
                                  fill="#5ECB5B"
                                  class="bi bi-envelope-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>{" "}
                              </div>
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnvelopClick(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>

                          </div>
                        </div>


                        <div className="col-3" style={{}}>
                          <div
                            style={{
                              flexDirection: "column",
                              width: "123px",
                              height: "40px",
                              border: "1px solid #CDCDCD",
                              borderRadius: "5px",
                              marginTop: "23px",
                              marginLeft: "20px",
                              textAlign: "center",
                              display: "flex",

                              alignItems: "center",
                            }}
                          >

                            <div style={{ display: "flex" }}>
                              <div style={{ marginTop: "4%" }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="25"
                                  fill="#5ECB5B"
                                  class="bi bi-envelope-check-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                  <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>
                              </div>{" "}
                              <div
                                style={{
                                  width: "1px",
                                  backgroundColor: "grey",
                                  marginLeft: "25px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                              </div>{" "}
                              <div
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEnquiryClick(item.pv_enquiry_number)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="28"
                                  viewBox="0 0 32 32"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="4"
                                    fill="#245693"
                                  />
                                  <path
                                    fill="#245693"
                                    d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                  />
                                </svg>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="col-2">
                          {item.chairman_status === "pending" || item.chairman_status === null ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",

                              }}
                            >
                              Waiting for trust decision
                            </h6>
                          ) : item.admission_status === "pending" ? (
                            <h6
                              style={{
                                fontSize: "12px",
                                marginTop: "20px",
                                fontFamily: "inter",
                                fontStyle: "italic",
                              }}
                              onClick={() =>navigate("/admission_form", { state: { itemId: item.pv_enquiry_number } })}
                            >
                              <a
                                style={{ pointerEvents: "none" }}
                                href=""
                              //  href={`/admission_form?itemId=${item.pv_enquiry_number}`}
                              >Click here</a> to complete Admission process
                            </h6>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>


        {qualityView ? (
          <QualityPopUp
            show={qualityView}
            onHide={() => setQualityView(false)}
          />
        ) : null}

        {weaknessView ? (
          <WeaknessPopUp
            show={weaknessView}
            onHide={() => setWeaknessView(false)}
          />
        ) : null}

        {relativeView ? (
          <RelativePopUp
            show={relativeView}
            onHide={() => setRelativeView(false)}
          />
        ) : null}

        {refererView1 ? (
          <RefererPopUp1
            show={refererView1}
            onHide={() => setRefererView1(false)}
          />
        ) : null}


        {recommenderView ? (
          <RecommenderPopUp
            show={recommenderView}
            onHide={() => setRecommenderView(false)}
          />
        ) : null}

        {consentView ? (
          <ConsentPopUp
            show={consentView}
            onHide={() => setConsentView(false)}
          />
        ) : null}

        {peView ? (
          <PePopUp
            show={peView}
            onHide={() => {
              setPeview(false);
            }}
          />
        ) : null}
        {view1 ? (
          <EnquiryFormPopUp
            show={view1}
            onHide={() => {
              setView1(false);
            }}
          />
        ) : null}
      </div>
    </>
  );
}
export default AdmissionPatient;

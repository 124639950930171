import React, { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import moment from 'moment';
import peace from "../../assets/images/icons/peacevillage.png";
import AddMedicinesPat from './AddMedicinesPat';
import AddTest from './TestScan/AddTest';
import AddScan from './TestScan/AddScan';
import AddProcedure from './Procedure/AddProcedure';
import DatePicker from 'react-date-picker';
import './discharge.css';
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
const DischargeShow = ({ close, patient_id, patient_name, doctor, doctor_id, setDischargeStatus, data }) => {
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
    const [postData, setPostData] = useState({})
    const [prescriptionShow, setPrescriptionShow] = useState(false)
    const [scanShow, setScanShow] = useState(false)
    const [testShow, setTestShow] = useState(false)
    const [procedureShow, setProcedureShow] = useState(false)
    const [prescriptionList, setPrescriptionList] = useState([])
    const [testList, setTestList] = useState([])
    const [scanList, setScanList] = useState([])
    const [procedureList, setProcedureList] = useState([])
    const [doctorList, setDoctorList] = useState([])
    const [displayDoctor, setDisplayDoctor] = useState(false)
    const [update, setUpdate] = useState(false)
    const dropdownRef = useRef(null)
    const prescriptionRef = useRef();
    const scanRef = useRef();
    const testRef = useRef();
    const procedureRef = useRef();
    const alert = useAlert();
    const allied = JSON.parse(sessionStorage.getItem("logindatas"))
        ? JSON.parse(sessionStorage.getItem("logindatas")).name
        : null;

    useEffect(() => {
        if (update) {
            onConfirm();
        }
    }, [prescriptionList, testList, scanList, procedureList, update]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDisplayDoctor(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const trimspace = (val) => {
        return val.startsWith(' ') ? val.trimLeft() : val;
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = trimspace(e.target.value);
        setPostData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setPostData((prevData) => ({
            ...prevData,
            followupDate: date,
        }));
    };

    const handleSearchDoctor = (e) => {
        const { name, value } = e.target;

        setPostData((prevData) => {
            if (!value) {
                const { followup, followup_name, followupDate, ...rest } = prevData;
                return rest;
            }
            return {
                ...prevData,
                followup_name: value,
            };
        });

        if (value === '') {
            setDisplayDoctor(false)
        } else {
            const tokenString = sessionStorage.getItem("usertoken");
            const str = tokenString.replace(/["]+/g, "");

            axios
                .get(`${APIURL}/api/v1/service-provider/procedure-assignee/?q=${value}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        setDoctorList(res.data.details);
                        setDisplayDoctor(true);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching doctor list:", error);
                    setDoctorList([]);
                });
        }
    };

    const callChildFunction = async () => {
        console.log("-->2");

        let isValid = true;

        if (prescriptionRef.current) {
            console.log("-->3");
            const result = await prescriptionRef.current.handleMedicine();
            if (!result) isValid = false;
            console.log(result)
        }
        if (testRef.current && isValid) {
            console.log("-->4");
            const result = await testRef.current.handleSubmitTest();
            if (!result) isValid = false;
            console.log(result)
        }
        if (scanRef.current && isValid) {
            console.log("-->5");
            const result = await scanRef.current.handleSubmitScan();
            if (!result) isValid = false;
            console.log(result)
        }
        if (procedureRef.current && isValid) {
            console.log("-->6");
            const result = await procedureRef.current.handleSubmitProcedure();
            if (!result) isValid = false;
            console.log(result)
        }

        if (isValid) {
            console.log("-->78")
            setShowConfirmation(true);
        }
    };


    const handleConfirm = () => {
        console.log('hi')
        setUpdate(true)
    }
    const handlePrescriptionsUpdate = (med) => {
        let medList = [];
        for (let i = 0; i < med.length; i++) {
            let durationVal = 0;
            let durationunit = "";
            let frequencyValue = 0;
            let intakeValue = 0;
            let intakeTimeValue = 0;
            let intakeUnitValue = 0;

            if (
                med[i].duration.value &&
                med[i].durationvalue
            ) {
                durationVal = med[i].durationvalue;
                durationunit = med[i].duration.value;
            }
            if (
                typeof med[i].frequency === "object" &&
                med[i].frequency !== null
            ) {
                frequencyValue = med[i].frequency.value;
            } else {
                frequencyValue = med[i].frequency;
            }

            if (typeof med[i].intake === "object" && med[i].intake !== null) {
                intakeValue = med[i].intake.label;
            } else {
                intakeValue = med[i].intake;
            }
            if (
                typeof med[i].intake_time === "object" &&
                med[i].intake_time !== null
            ) {
                intakeTimeValue = med[i].intake_time.value;
            } else {
                intakeTimeValue = med[i].intake_time;
            }
            if (
                typeof med[i].intake_unit === "object" &&
                med[i].intake_unit !== null
            ) {
                intakeUnitValue = med[i].intake_unit.value;
            }

            else {
                intakeUnitValue = med[i].intake_unit;
            }
            medList.push({
                med_code: med[i].med_code,
                drug: med[i].medicine_name,
                medicine: med[i].id,
                med_type: med[i].med_type,
                frequency: frequencyValue,
                intake: intakeValue,
                intake_time: intakeTimeValue,
                intake_unit: intakeUnitValue,
                strength: med[i].strength === " " ? med[i].strength1 : med[i].strength,
                form: med[i].dosage_form === " " ? med[i].dosage_form1 : med[i].dosage_form,
                route: med[i].route === "" ? med[i].route1 : med[i].route,
                duration: med[i].durationvalue,
                duration_unit: med[i].duration,
                strength_unit: "mg",
                intake_count: med[i].dosage_count,
                remarks: med[i].remarks,
            });
        }
        setPrescriptionList(medList)
    }

    const handleTestUpdate = (testData) => {
        let values = [];
        const tests = testData;
        for (let i = 0; i < tests.length; i++) {
            values.push({
                patient: patient_id,
                doctor: doctor_id,
                test_name: tests[i].test_name,
                lab_test_details: tests[i].test_details,
                date: tests[i].date,
                test: tests[i].id,
            });
        }
        setTestList(values)
    }

    const handleScanUpdate = (scanData) => {
        console.log('>9')
        const scans1 = scanData;
        let values1 = [];

        for (let i = 0; i < scans1.length; i++) {
            values1.push({
                patient: patient_id,
                doctor: doctor_id,
                scan_name: scans1[i].scan_name,
                scan_details: scans1[i].scan_details,
                date: scans1[i].date,
                scan: scans1[i].id,
            });
        }
        setScanList(values1)
    }

    const handleProcedureUpdate = (procedureData) => {
        let proceduresList = [];

        procedureData.forEach((procedure) => {
            let procedureData = {
                procedure_name: procedure.procedure_details.procedure_name,
                procedure: procedure.procedure_details.procedure_id,
                date: procedure.date.split("T").join(" ").slice(0, 10),
                area: procedure.area,
                session: procedure.session,
                quantity: procedure.quantity,
                details: procedure.details,
                procedure_reason: procedure.procedure_reason ? procedure.procedure_reason : "",
                patient: patient_id,
            };
            if (procedure.proc_image !== "") {
                procedureData.proc_image = procedure.proc_image;
            }
            proceduresList.push(procedureData);
        });

        console.log(proceduresList);
        setProcedureList(proceduresList)

    }

    function processData(data) {
        const { careplans, prescriptions, ...restData } = data;

        if (restData.diagnosis) {
            restData.diagnosis = restData.diagnosis.map((item) => {
                const diagnosisList = item.diagnosis.split(',').map(d => d.trim());
                const diagnosisStr = diagnosisList.join(' and ');
                return `${diagnosisStr} were diagnosed by ${item.doctor} on ${item.created_at}`;
            }).join('. ');
        }

        if (restData.symptoms) {
            restData.symptoms = restData.symptoms.map((item) => {
                return `${item.diagnosis} observed by ${item.doctor} on ${item.created_at}`;
            }).join('. ');
        }

        if (restData.observations) {
            restData.observations = restData.observations.map((item) => {
                return `${item.observation} on ${item.created_at}`;
            }).join(', ');
        }

        if (restData.vitals && restData.vitals.length > 0) {
            const vitalsByDate = restData.vitals.reduce((acc, item) => {
                const { created_at, test, result } = item;
                if (!acc[created_at]) {
                    acc[created_at] = [];
                }
                acc[created_at].push(`${test === 'glucose' ? 'Glucose' : test === 'glucose_random' ? 'Glucose Random' : test === 'glucose_fasting' ? 'Glucose Fasting' : test === 'pulse' ? 'Pulse' : test === 'blood_pressure' ? 'Blood Pressure' : test === 'temperature' ? 'Temperature' : test === 'spo2' ? 'SPO2' : test}: ${result}`);
                return acc;
            }, {});

            restData.vitals = Object.entries(vitalsByDate).map(([date, vitalsList]) => {
                const vitalsStr = vitalsList.join(', ');
                return `${vitalsStr} were recorded on ${date}`;
            }).join('. ');
        }


        if (restData.medical_history) {
            restData.medical_history = `Medical history: ${restData.medical_history}`;
        }
        if (restData.family_history) {
            restData.family_history = `Family history: ${restData.family_history}`;
        }

        return {
            ...restData,
            careplans,
            prescriptions
        };
    }

    const onConfirm = () => {

        if (!postData.admissionremarks) {
            alert.error('Add admission remarks')
        } else if (postData.followup && !postData.followupDate) {
            alert.error('Add Followup Date')
        } else {
            console.log("-->9")
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            const stringData = processData(data);
            const payload = {
                patient_id: patient_id,
                patient_name: patient_name,
                diagnosis: data.diagnosis,
                symptoms: data.symptoms,
                observations: data.observations,
                post_discharge_careplans: {
                    scans: scanList,
                    tests: testList,
                    procedures: procedureList
                },
                fulfilled_careplans: stringData.careplans,
                post_discharge_prescriptions: {
                    patient: patient_id,
                    medicines: prescriptionList
                },
                vitals: stringData.vitals,
                fulfilled_prescriptions: stringData.prescriptions,
                medical_history: stringData.medical_history,
                family_histoty: stringData.family_history,
                diet: postData.diet,
                instructions: postData.instructions,
                next_visit_doctor: postData.followup,
                next_visit_date: postData.followupDate && new Date(postData.followupDate).toISOString().split('T')[0],
                discharging_doctor_name: doctor || allied,
                admission_remarks: postData.admissionremarks,
                discharge_remarks: postData.dischargeremarks,
                report_prepared_by: postData.preparedby
            };
            axios
                .post(`${APIURL}/api/v1/doctor/discharge-patient/`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert.success(res.data.message);
                        setDischargeStatus('pending')
                        close()
                    } else {
                        alert.error(res.data.message);
                    }
                });
        }
        setUpdate(false)
    };

    const InfoRow = ({ label }) => {
        return (
            <div className="flex-dis">
                <div className="col-2 zero-padding">
                    <label>{label}</label>
                </div>
                <div className="col-10 zero-padding">
                    <hr className="lines" />
                </div>
            </div>
        );
    };

    return (
        <div>
            <Modal show={true} centered size="lg">
                <Modal.Body style={{ textAlign: "left", display: 'flex', flexDirection: 'column' }} >
                    <div className="flex-dis">
                        <label className='discharge-head2 col-9'>Discharge summary</label>
                        <img src={peace} className="peace-image col-3" alt="Peace Village" />
                    </div>
                    <br />
                    <div className="flex-dis label-margin">
                        <div className='col-6' style={{ display: "flex", flexDirection: "column" }}>
                            <div className="flex-dis"><label className='strong-label'>Patient Id</label>&nbsp;<label> : <b>{patient_id}</b></label></div>
                            <div className="flex-dis"><label className='strong-label'>Name</label>&nbsp;<label style={{ textTransform: "capitalize" }}> : <b>{patient_name}</b></label></div>
                            {/* <div className="flex-dis"><label className='strong-label'>Age/Sex</label><label>:</label></div>
                            <div style={{ display: "flex" }}><label className='strong-label'>Admission Date</label><label>:</label></div> */}
                        </div>
                        <div className='col-6' style={{ display: "flex", flexDirection: "column" }}>
                            {/* <div className="flex-dis"><label className='strong-label'>UHID</label><label>:</label></div>
                            <div className="flex-dis"><label className='strong-label'>IP Number</label><label>:</label></div>
                            <div className="flex-dis"><label className='strong-label'>Discharge Date</label><label>:</label></div> */}
                        </div>
                    </div>
                    <div className="flex-dis">
                        <div className="col-12 zero-padding">
                            <hr className="lines" />
                        </div>
                    </div>
                    {data?.diagnosis && data.diagnosis.length > 0 && (
                        <>
                            <InfoRow label="Diagnosis" />
                            {data.diagnosis.map((item, index) => (
                                <div className="data-dis" key={index}>
                                    <p style={{ textTransform: 'capitalize' }}>Doctor: {item.doctor}</p>
                                    <p>Date: {item.created_at}</p>
                                    <p className='cap-sentence'> {item.diagnosis}</p>
                                    <br />
                                </div>
                            ))}
                        </>
                    )}
                    {data.medical_history &&
                        <>
                            <InfoRow label="Medical History" />
                            <p className='cap-sentence'>{data.medical_history}</p>
                        </>
                    }
                    {data.family_history &&
                        <>
                            <InfoRow label="Family History" />
                            <p className='cap-sentence'>{data.family_history}</p>
                        </>
                    }
                    {data.observations?.length > 0 &&
                        <>
                            <InfoRow label="Observations" />
                            <table className="prescription-table">
                                <thead>
                                    <tr>
                                        <th>Observations</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.observations.map((observation, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='cap-sentence'>{observation.observation}</td>
                                            <td>{observation.created_at}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    }
                    {data.symptoms?.length > 0 &&
                        <>
                            <InfoRow label="Symptoms" />
                            <table className="prescription-table">
                                <thead>
                                    <tr>
                                        <th>Symptoms</th>
                                        <th>Doctor</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.symptoms.map((symptom, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td className='cap-sentence'>{symptom.diagnosis}</td>
                                            <td style={{ textTransform: 'capitalize' }}>{symptom.doctor}</td>
                                            <td>{symptom.created_at}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    }
                    {data.vitals && data.vitals?.length > 0 && (
                        <>
                            <InfoRow label="Vitals" />
                            <table className="prescription-table">
                                <thead>
                                    <tr>
                                        <th>Test</th>
                                        <th>Result</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.vitals.map((vital, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                            <td>{vital.test === 'glucose' ? 'Glucose' : vital.test === 'glucose_random' ? 'Glucose Random' : vital.test === 'glucose_fasting' ? 'Glucose Fasting' : vital.test === 'pulse' ? 'Pulse' : vital.test === 'blood_pressure' ? 'Blood Pressure' : vital.test === 'temperature' ? 'Temperature' : vital.test === 'spo2' ? 'SPO2' : vital.test}</td>
                                            <td>{vital.result}</td>
                                            <td>{vital.created_at}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    {data.careplans && (
                        <>
                            <InfoRow label="Care plan" />
                            <div style={{ display: "flex", marginLeft: "auto", width: "fit-content" }}>
                                <button onClick={() => { setTestShow(!testShow) }} style={{ margin: "0 2px", color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Test</button>
                                <button onClick={() => { setScanShow(!scanShow) }} style={{ margin: "0 2px", color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Scan</button>
                                <button onClick={() => { setProcedureShow(!procedureShow) }} style={{ margin: "0 2px", color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Procedure</button>
                            </div>
                            {data.careplans?.labtest?.length > 0 && (
                                <>
                                    <table className="prescription-table">
                                        <thead>
                                            <tr>
                                                <th>Test Name</th>
                                                <th>Details</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.careplans.labtest.map((test, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                    <td>{test.test_name}</td>
                                                    <td className='cap-sentence'>{test.test_details || 'N/A'}</td>
                                                    <td>{test.created_at}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            {/* {data.careplans?.labtest?.length > 0 && <div className="flex-dis" style={{ justifyContent: "center" }}>
                                <button onClick={() => { setTestShow(!testShow) }} style={{ color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Test</button>
                            </div>} */}
                            {testShow && <AddTest onTestUpdate={handleTestUpdate} ref={testRef} from="discharge" id={patient_id} />}
                            {data.careplans?.scan?.length > 0 && (
                                <>
                                    <table className="prescription-table">
                                        <thead>
                                            <tr>
                                                <th>Scan Name</th>
                                                <th>Details</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.careplans.scan.map((scan, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                    <td>{scan.scan_name}</td>
                                                    <td className='cap-sentence'>{scan.scan_details || 'N/A'}</td>
                                                    <td>{scan.created_at}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            {/* {data.careplans?.labtest?.length > 0 && <div className="flex-dis" style={{ justifyContent: "center" }}>
                                <button onClick={() => { setScanShow(!scanShow) }} style={{ color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Scan</button>
                            </div>} */}
                            {scanShow && <AddScan onScanUpdate={handleScanUpdate} ref={scanRef} from="discharge" id={patient_id} />}
                            {data.careplans?.procedure?.length > 0 && (
                                <>
                                    <table className="prescription-table">
                                        <thead>
                                            <tr>
                                                <th>Procedure Name</th>
                                                <th>Details</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.careplans.procedure.map((procedure, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                                    <td>{procedure.procedure_name}</td>
                                                    <td className='cap-sentence'>{procedure.procedure_details || 'N/A'}</td>
                                                    <td>{procedure.created_at}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                            {/* {data.careplans?.labtest?.length > 0 && <div className="flex-dis" style={{ justifyContent: "center" }}>
                                <button onClick={() => { setProcedureShow(!procedureShow) }} style={{ color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Procedure</button>
                            </div>} */}
                            {procedureShow && <AddProcedure onProcedureUpdate={handleProcedureUpdate} ref={procedureRef} id={patient_id} />}
                        </>
                    )}

                    <InfoRow label="Prescription" />
                    <div className="flex-dis" style={{ marginLeft: "auto", width: "fit-content" }}>
                        <button onClick={() => { setPrescriptionShow(!prescriptionShow) }} style={{ margin: "0, 2px", color: "#fff", borderRadius: "3px", backgroundColor: '#2b69a3' }}>Add Prescription</button>
                    </div>

                    {data.prescriptions?.length > 0 && (
                        <table className="prescription-table">
                            <thead>
                                <tr>
                                    {Object.keys(data.prescriptions[0]).map((key, index) => (
                                        <th key={index}>
                                            {key === "tilldate"
                                                ? "Till Date"
                                                : key.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
                                            }
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.prescriptions.map((prescription, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                                        {Object.values(prescription).map((value, i) => (
                                            <td key={i}>{value}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {prescriptionShow && <AddMedicinesPat onPrescriptionsUpdate={handlePrescriptionsUpdate} from="discharge" ref={prescriptionRef} patient={patient_id} />}
                    <br />
                    <div className='input-container col-12' style={{ padding: 0 }}>
                        <label>Clinical history</label>
                        <input
                            className='input1'
                            type="text"
                            value={postData.clinicalhistory}
                            maxLength="500"
                            name="clinicalhistory"
                            onChange={handleChange}
                        />
                    </div>
                    <div className='flex-dis'>
                        <div className='input-container col-6' style={{ padding: "0 5px 0 0" }}>
                            <label>Admission Remarks <span style={{ color: 'red' }}>*</span></label>
                            <input
                                className='input1'
                                type="text"
                                value={postData.admissionremarks}
                                maxLength="500"
                                name="admissionremarks"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container col-6' style={{ padding: "0 0 0 5px" }}>
                            <label>Discharge Remarks</label>
                            <input
                                className='input1'
                                type="text"
                                value={postData.dischargeremarks}
                                maxLength="500"
                                name="dischargeremarks"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='flex-dis'>
                        <div className='input-container col-6' style={{ padding: "0 5px 0 0" }}>
                            <label>Diet</label>
                            <input
                                className='input1'
                                type="text"
                                value={postData.diet}
                                maxLength="200"
                                name="diet"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container col-6' style={{ padding: "0 0 0 5px" }}>
                            <label>Instructions</label>
                            <input
                                className='input1'
                                type="text"
                                value={postData.instructions}
                                maxLength="500"
                                name="instructions"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='flex-dis'>
                        <div className='input-container col-6' style={{ padding: "0 5px 0 0", position: "relative" }}>
                            <label>Follow Up Consultant</label>
                            <input
                                style={{ textTransform: 'capitalize' }}
                                className='input1'
                                type="text"
                                value={postData.followup_name}
                                maxLength="50"
                                name="followup"
                                onChange={handleSearchDoctor}
                            />
                            {displayDoctor && (
                                <div
                                    ref={dropdownRef}
                                    className='doctorlist-discharge'
                                >
                                    {doctorList.length > 0 ? doctorList.map((item, index) => (
                                        <p
                                            key={index}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setPostData((prevData) => ({
                                                    ...prevData,
                                                    followup: item.id,
                                                    followup_name: item.full_name,
                                                }));
                                                setDisplayDoctor(false);
                                            }}
                                            className='suggest-item text-secondary doctorlist-item'
                                        >
                                            <label style={{ textTransform: 'capitalize', margin: 0 }}>{item.full_name}</label>
                                        </p>
                                    )) : (
                                        <h6 style={{ textAlign: 'center' }}>No Doctors found !!</h6>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className='input-container discharge col-6' style={{ padding: "0 0 0 5px" }}>
                            <label>Follow Up Date</label>
                            <DatePicker
                                onChange={date => { handleDateChange(date) }}
                                minDate={new Date()}
                                value={postData.followupDate}
                                disabled={!postData.followup_name}
                            />
                        </div>
                    </div>
                    <div className='flex-dis'>
                        <div className='input-container col-6' style={{ padding: "0 5px 0 0" }}>
                            <label>Discharging doctor</label>
                            <input
                                style={{ textTransform: 'capitalize' }}
                                className='input1'
                                type="text"
                                value={doctor || allied}
                                maxLength="50"
                                name="dischargedby"
                                disabled
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container col-6' style={{ padding: "0 0 0 5px" }}>
                            <label>Report Prepared by</label>
                            <input
                                className='input1'
                                type="text"
                                value={postData.preparedby}
                                maxLength="100"
                                name="preparedby"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', margin: 'auto' }}>
                        <button className='swirl-butns' style={{ margin: '10px 3px 0px 0px', backgroundColor: '#dc3545' }} onClick={() => close()}> Cancel</button>
                        <button className='swirl-butns' style={{ margin: '10px 0px 0px 3px', backgroundColor: '#2b69a3' }} onClick={() => callChildFunction()}> Confirm</button>
                    </div>
                </Modal.Body>
            </Modal>
            {
                showConfirmation && (
                    <SubmitConfirmationPopup
                        message="Are you sure you want to submit the form?"
                        onConfirm={handleConfirm}
                        onCancel={() => { setShowConfirmation(false) }}
                    />
                )
            }
        </div >
    );
};

export default DischargeShow;

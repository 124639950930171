import React, { useState } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import './hospprofile.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Modal } from 'react-bootstrap';
import "./popup.css";
import { useAlert } from 'react-alert';

function EmergencyUpdate(props) {

  let number = props.emergency1;
  if (number && !number.startsWith('91')) {
    number = '91' + number;
  }
  const [phone, setPhone] = useState({
    dialcode: "",
    rawphone: "",
    emergency_number: number,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [rawPhone, setRawPhone] = useState("");
  const alert = useAlert()

  const [error, setError] = useState({
    phone: ""
  });


  const handlePhoneInput = (value, data) => {

    const rawPhone = value.slice(data.dialCode.length);
    setRawPhone(rawPhone);
    const dialcode = value.slice(0, data.dialCode.length);
    let number = value;
    if (number && !number.startsWith('91')) {
      number = '91' + number;
    }
    setPhone(currentstate => ({
      ...currentstate,
      dialcode: dialcode,
      rawphone: rawPhone,
      emergency_number: number

    }));
  }


  let flag = true

  const handleCheckExistingPhone = async () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const rawPhone = phone.rawphone;

    const dialcode = phone.dialcode;
    setError(currentstate => ({
      ...currentstate,
      phone: ''
    }));


    const data = {
      code: dialcode,
      emergency_number: rawPhone
    }

    await axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
      headers: {
        "Authorization": 'Token ' + str,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          if (res.data.is_existing_user === true) {
            flag = false

            setError(currentstate => ({
              ...currentstate,
              phone: "This phone number is already registered!"
            }));

          }
          else {
            flag = true

          }



        }
        else {
          flag = true

        }

      })
      .catch(err => {


      })

  }

  const validate = async () => {
    //let input = email;
    let errors = {};
    let isValid = true;
    await handleCheckExistingPhone()

    if (flag === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (!phone.dialcode || phone.dialcode === "" || !phone.rawphone || phone.rawphone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }
    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Enter valid phone number  ";
    }
    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }


  const handleContinue = async (e) => {
    e.preventDefault();

    if (await validate()) {

      submitPhone()
      //handleSendOTP();
    }
  }


  const submitPhone = () => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      key: "update",
      code: phone.dialcode,
      emergency_number: phone.dialcode + phone.rawphone
    }

    axios.post(`${APIURL}/api/v1/patient/emergency-contact/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {

          alert.success('Updated successfully')
          props.handle()
        } else {
          setErrorMsg(res.data.message);
          setErrorSubmit(true);


        }

      })
      .catch(err => {
        setErrorMsg("error");

        setErrorSubmit(true);


      })

  }

  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        //  backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 class="updated-message" style={{ color: "red" }}>{errorMsg ? errorMsg
            : 'Error in Data Submission. Please try again!'} </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }


  return (<>
    <div className='phone-edit flex-phone'>
      <div className="form-control phone-input item item-left-row">
        <PhoneInput
          inputProps={{

          }}
          country={"in"}
          onlyCountries={['in']}
          countryCodeEditable={false}
          value={phone.emergency_number}
          onChange={handlePhoneInput}
          onBlur={handleCheckExistingPhone}

        />
        {error.phone ? <div className="error-validation-msg"> {error.phone}</div> : null}

      </div>
      <div className="form-button">
        <button style={{ marginLeft: '10px' }} onClick={handleContinue} className="btn btn-primary btn-col" >Update Emergency Number</button>
      </div>

      {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
          }}

        /> : ''
      }


    </div>
  </>
  )
}

export default EmergencyUpdate
